import React, {
  createContext,
  useReducer,
  Dispatch,
  useCallback,
  useMemo
} from 'react';
import { appNotification } from '../core';
import { leadsService, quickRepliesService } from '../services';
import { Lead } from '../types/Lead';
import { QuickReply } from '../types/Shortcodes';

export interface ChatMessageBoxStateActions {
  type: string;
  value?: any;
}

export interface ChatMessageBoxState {
  emojiOpen: boolean;
  showAllTools: boolean;
  internalOnly: boolean;
  isEmail: boolean;
  showMentionsPopup: boolean;
  showQuickReplies: boolean;
  selectedMentionIndex: number;
  notificationGroups: any[];
  allNotificationGroups: any[];
  selectedGif?: any;
  selectedFile?: any;
  selectedFilePreview?: any;
  shortcode?: string;
}

export interface ChatMessageBoxContextProps {
  state: ChatMessageBoxState;
  dispatch: Dispatch<ChatMessageBoxStateActions>;
  isEmptyMessage: () => boolean;
  appendMessage: (value: string) => void;
  onFileSelected: (file: any) => Promise<any>;
}

export const ChatMessageBoxContext = createContext(
  {} as ChatMessageBoxContextProps
);

export function getMessageInput(): HTMLIonInputElement {
  return document.getElementById(
    'chat-message-box-input'
  ) as HTMLIonInputElement;
}

export function getMessageText(): string {
  const element = getMessageInput();
  return (element?.value ?? '').toString();
}

export function setMessageText(text: string) {
  const element = getMessageInput();

  if (element) {
    element.value = text;
  }
}

export function expandQuickReply(reply: QuickReply, lead?: Lead) {
  const msg = getMessageText();
  setMessageText(
    `${msg.substring(
      0,
      msg.lastIndexOf(':')
    )}${quickRepliesService.renderedMessageText(reply, lead)}`
  );
}

export function appendMessageText(text: string) {
  const element = getMessageInput();

  if (element) {
    element.value += text;
  }
}

export function replaceMessageText(
  getIndex: (str: string) => number,
  text: string
) {
  const element = getMessageInput();
  if (element) {
    const msg = (element.value ?? '').toString();
    const index = getIndex(msg);
    element.value = `${index > -1 ? msg.substring(0, index) : msg}${text} `;
  }
}

export function clearMessage() {
  setMessageText('');
}

export function setMessageFocus() {
  const element = getMessageInput();
  element?.setFocus?.();
}

let reducer = (state: ChatMessageBoxState, action: any) => {
  const { type, value } = action;

  switch (type) {
    case 'setMention': {
      const user =
        value || state.notificationGroups[state.selectedMentionIndex ?? 0];
      if (!user) return state;
      const mention = `@${user.username}`;

      replaceMessageText((msg: string) => msg.lastIndexOf('@'), mention);

      return {
        ...state,
        showMentionsPopup: false,
        internalOnly: true,
        isEmail: false,
        notificationGroups: [],
        selectedMentionIndex: 0
      };
    }

    case 'set': {
      return { ...state, ...value };
    }
  }
  return state;
};

export const ChatMessageBoxContextProvider = (props: any) => {
  const initialState: ChatMessageBoxState = {
    emojiOpen: false,
    showMentionsPopup: false,
    showQuickReplies: false,
    selectedMentionIndex: 0,
    notificationGroups: [],
    allNotificationGroups: [],
    showAllTools: false,
    internalOnly: false,
    isEmail: false
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const isEmptyMessage = useCallback(() => {
    const msg = getMessageText();
    return !msg && !state.selectedGif && !state.selectedFile;
  }, [state.selectedFile, state.selectedGif]);

  const onFileSelected = useCallback(
    async (file: any) => {
      return new Promise(resolve => {
        if (!leadsService.isSupportedFileType(file.type)) {
          appNotification.toast(
            'Only images and videos are supported.',
            'Error'
          );
          return resolve();
        }
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          dispatch({
            type: 'set',
            value: {
              selectedFilePreview: reader.result,
              selectedFile: file
            }
          });
          resolve();
        });

        reader.readAsDataURL(file);
      });
    },
    [dispatch]
  );

  const value = useMemo(
    () =>
      ({
        state,
        dispatch,
        isEmptyMessage,
        appendMessage: appendMessageText,
        onFileSelected
      } as ChatMessageBoxContextProps),
    [state, dispatch, isEmptyMessage, onFileSelected]
  );

  return (
    <ChatMessageBoxContext.Provider value={value}>
      {props.children}
    </ChatMessageBoxContext.Provider>
  );
};

export const ChatMessageBoxContextConsumer = ChatMessageBoxContext.Consumer;
