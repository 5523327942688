import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import Picker from 'emoji-picker-react';
import '../styles/components/EmojiPicker.scss';

export interface EmojiPickerProps {
  isOpen: boolean;
  onSelected?: (emoji: string) => void;
}

const EmojiPicker: React.FC<EmojiPickerProps> = (
  { onSelected, isOpen },
  ref
) => {
  const inputRef = useRef<any>();
  useImperativeHandle(ref, () => ({
    contains: (e: any) => inputRef.current?.contains?.(e)
  }));

  return (
    <div
      ref={inputRef}
      className="emoji-picker-container"
      style={{
        display: isOpen ? 'block' : 'none'
      }}
    >
      <Picker
        onEmojiClick={(e: any, emojiObject: any) => {
          const { emoji } = emojiObject;
          if (onSelected) {
            onSelected(emoji);
          }
        }}
      />
    </div>
  );
};

export default forwardRef(EmojiPicker);
