import {
  speedometer,
  chatbubbles,
  document,
  call,
  time,
  calendar,
  calculator,
  footsteps,
  gift,
  location,
  megaphone,
  personCircle,
  pieChart,
  notifications,
  peopleCircle,
  medal,
  apps,
  code,
  codeWorking,
  business,
  cog,
  person,
  people,
  analytics,
  download,
  receipt,
  body,
  barbell,
  eye,
  clipboard,
  logoFacebook,
  cash,
  caretForwardCircle,
  carSport,
  wallet,
  mail,
  colorWandOutline,
  refreshCircleOutline,
  alarm
} from 'ionicons/icons';
import { AppPage } from '../pages';
import util from './util';

const appPages: AppPage[] = [
  {
    title: 'Dashboard',
    url: '/',
    icon: speedometer,
    allowNinjas: false
  },
  {
    title: 'Queue',
    url: '/',
    icon: download,
    allowNinjas: true,
    showBadge: true,
    canAccess(user) {
      return user.is_text_ninja;
    },
    badgeCount(state): number {
      return state.inboundCount ?? 0;
    }
  },
  {
    title: 'Notifications',
    url: '/notifications/',
    icon: notifications,
    showBadge: true,
    allowNinjas: true,
    badgeCount(state): number {
      return state.unread;
    }
  },
  {
    title: 'Text Messages',
    url: '/text-messages/',
    allowNinjas: false,
    icon: chatbubbles,
    showBadge: true,
    badgeCount(state): number {
      return state.clientInboundCount;
    },
    badgeCount2(state): number {
      return state.clientNeedsCallCount;
    }
  },
  {
    title: 'Queue',
    url: '/inbound-queue/',
    allowNinjas: false,
    icon: download,
    showBadge: true,
    canAccess(user) {
      return user.is_admin === true;
    },
    badgeCount(state) {
      return state.inboundCount ?? 0;
    }
  },
  {
    title: 'Blasts',
    url: '/blasts/leads/',
    allowNinjas: false,
    icon: megaphone,
    canAccess(user) {
      return !user.is_admin && !user.is_client_admin;
    }
  },
  {
    title: 'Blasts',
    icon: megaphone,
    allowNinjas: false,
    canAccess(user) {
      return user.is_admin || user.is_client_admin;
    },
    subpages: [
      {
        title: 'Lead Blasts',
        url: '/blasts/leads/',
        allowNinjas: false,
        icon: megaphone
      },

      {
        title: 'Lead Blast Report',
        url: '/reports/lead-blasts/',
        allowNinjas: false,
        icon: pieChart,
        showBadge: true,
        badgeCount(): string {
          return 'New';
        },
        canAccess(user, selectedClient, state) {
          return (user.is_admin || user.is_client_admin);
        }
      },
      {
        title: 'Customer Blasts',
        url: '/blasts/customers/',
        allowNinjas: false,
        icon: personCircle
      },

      {
        title: 'Customer Blast Report',
        url: '/reports/customer-blasts/',
        icon: pieChart,
        allowNinjas: false,
        showBadge: true,
        badgeCount(): string {
          return 'New';
        },
        canAccess(user, selectedClient, state) {
          return (user.is_admin || user.is_client_admin);
        }
      },
      {
        title: 'Upcoming Blasts',
        url: '/upcoming-blasts/leads/',
        allowNinjas: false,
        icon: time,
        canAccess(user) {
          return user.is_admin;
        }
      },
    ]
  },
  {
    title: 'Phone Calls',
    url: '/phone-calls/',
    allowNinjas: false,
    icon: call
  },
  {
    title: 'Appointments',
    url: '/appointments/',
    allowNinjas: false,
    icon: calendar
  },
  {
    title: 'Credit',
    allowNinjas: false,
    icon: document,
    subpages: [
      {
        title: 'Credit Applications',
        url: '/credit-applications/',
        icon: document,
        allowNinjas: false
      },
      {
        title: 'Soft Pulls',
        url: '/quick-qualifications/',
        icon: document,
        allowNinjas: false
      }
    ]
  },
  {
    title: 'Surveys',
    icon: clipboard,
    allowNinjas: true,
    url: '/surveys/'
  },
  {
    title: 'Direct Mail',
    url: '/maildrops/',
    icon: mail,
    allowNinjas: false,
    canAccess(user) {
      return user.is_admin || user.is_client_admin;
    }
  },
  {
    title: 'Reports',
    icon: analytics,
    allowNinjas: true,
    subpages: [
      {
        title: 'KPI Dashboard',
        url: '/reports/kpis/',
        icon: speedometer,
        allowNinjas: false
      },
      {
        title: 'My Activity',
        url: '/reports/my-activity/',
        icon: person,
        allowNinjas: false
      },
      {
        title: 'Team Activity',
        url: '/reports/team-activity/',
        icon: people,
        allowNinjas: false
      },
      {
        title: 'Lead Sources',
        url: '/reports/lead-sources/',
        icon: pieChart,
        allowNinjas: false
      },
      {
        title: 'Ninja Teams',
        url: '/reports/teams/',
        icon: medal,
        allowNinjas: true,
        canAccess(user) {
          return util.userIsStaff(user);
        }
      },
      {
        title: 'Needs Call',
        url: '/reports/needs-call/',
        icon: call,
        allowNinjas: true,
        canAccess(user) {
          return util.userIsStaff(user);
        }
      },
      {
        title: 'Ninja Report',
        url: '/reports/ninja/',
        icon: body,
        allowNinjas: true,
        canAccess(user) {
          return util.userIsStaff(user);
        }
      },
      {
        title: 'My Commissions',
        url: '/reports/my-commissions/',
        icon: cash,
        allowNinjas: true,
        canAccess(user) {
          return util.userIsStaff(user);
        }
      },
      {
        title: 'Commissions',
        url: '/reports/commissions/',
        icon: wallet,
        allowNinjas: false,
        canAccess(user) {
          return user.is_admin;
        }
      },
      {
        title: 'Ninja Team Commissions',
        url: '/reports/ninja-team-commissions/',
        icon: wallet,
        allowNinjas: false,
        canAccess(user) {
          return user.is_admin && (user.email.includes('alana@tecobi.com') || user.email.includes('ryanflores79'));
        }
      },
      {
        title: 'Facebook Ads',
        url: '/reports/facebook-ads/',
        icon: logoFacebook,
        allowNinjas: false,
        canAccess(user) {
          return user.is_admin || user.is_client_admin;
        }
      },
      {
        title: 'SMS Delivery',
        url: '/reports/sms-delivery-status/',
        icon: chatbubbles,
        allowNinjas: false,
        canAccess(user) {
          return user.is_staff && !user.is_text_ninja;
        }
      },
      /*
      {
        title: 'Lead Blasts',
        url: '/reports/lead-blasts/',
        icon: megaphone,
        allowNinjas: false,
        showBadge: true,
        badgeCount(): string {
          return 'New';
        },
        canAccess(user, selectedClient, state) {
          return (user.is_admin || user.is_client_admin);
        }
      },
      */
      /*
      {
        title: 'Per Lead Billing',
        url: '/reports/per-lead-billing/',
        icon: receipt,
        allowNinjas: false,
        showBadge: false,
        canAccess(user, selectedClient, state) {
          return (user.is_staff && !!selectedClient?.cost_per_new_lead);
        }
      },
      */
      {
        title: 'Sold Appointments',
        url: '/reports/sold-appointments/',
        icon: receipt,
        allowNinjas: false,
        showBadge: true,
        badgeCount(): string {
          return 'New';
        },
        canAccess(user) {
          return user.is_staff;
        }
      },
      {
        title: 'Customer Blast Accounting',
        url: '/reports/customer-blast-accounting/',
        icon: calculator,
        allowNinjas: false,
        canAccess(user) {
          return user.is_staff && !user.is_text_ninja;
        }
      }
    ]
  },
  {
    title: 'Account',
    icon: business,
    allowNinjas: false,
    canAccess(user) {
      return user.is_admin === true || user.is_settings_admin === true;
    },
    subpages: [
      {
        title: 'Settings',
        url: '/client/settings/',
        icon: cog,
        allowNinjas: false
      },
      {
        title: 'Users',
        url: '/client/users/',
        icon: peopleCircle,
        allowNinjas: false
      },
      {
        title: 'Phone Numbers',
        url: '/client/numbers/',
        icon: apps,
        allowNinjas: false
      },
      {
        title: 'Website Widget',
        url: '/client/widget/',
        icon: code,
        allowNinjas: false
      },
      {
        title: 'Embed Credit App',
        url: '/client/embed-credit-app/',
        icon: codeWorking,
        allowNinjas: false
      }
    ]
  },

  {
    title: 'Automation',
    icon: caretForwardCircle,
    allowNinjas: false,
    canAccess(user) {
      return user.is_admin || user.is_client_admin;
    },
    subpages: [
      {
        title: 'New Lead Followup',
        url: '/followup-settings/',
        allowNinjas: false,
        icon: colorWandOutline
      },
      {
        title: 'Re-engaged Lead Followup',
        url: '/reengaged-lead-followup/',
        allowNinjas: false,
        icon: refreshCircleOutline
      },
      {
        title: 'Vehicle Acquisition Followup',
        url: '/vehicle-acquisition-lead-followup/',
        allowNinjas: false,
        icon: carSport
      },
      {
        title: 'Appointment Reminders',
        url: '/appointment-reminders/',
        allowNinjas: false,
        icon: alarm
      },
      {
        title: 'Post Appointment Followup',
        url: '/post-appointment-followup/',
        allowNinjas: false,
        icon: calendar
      },
      {
        title: 'Appointment Checkin',
        url: '/post-appointment-checkin/',
        allowNinjas: false,
        icon: location
      },
      {
        title: 'Appt Showed/Lost Followup',
        url: '/appointment-lost-followup/',
        allowNinjas: false,
        icon: footsteps
      },
      {
        title: 'Birthday Followup',
        url: '/birthday-followup/',
        allowNinjas: false,
        icon: gift
      },
    ]
  },
  {
    title: 'Monitor',
    icon: eye,
    allowNinjas: false,
    canAccess(user) {
      return user.is_staff || user.is_client_admin;
    },
    url: '/monitor/'
  },
  {
    title: 'Training',
    icon: barbell,
    allowNinjas: true,
    url: '/training/'
  }
];

export default appPages;
