import React, { useContext, useCallback } from 'react';
import HapticButton from './HapticButton';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle
} from '@ionic/react';
import { TrainingContext } from '../context/TrainingContext';
import { AppContext } from '../context/AppContext';
import { trainingService } from '../services';
import { useRouter } from '../hooks/';
import { loadingIndicator, appNotification } from '../core';

const TrainingVideo: React.FC = () => {
  const { state, dispatch } = useContext(TrainingContext);
  const { state: appState } = useContext(AppContext);

  const { quiz } = state;
  const router = useRouter();
  const startQuiz = useCallback(async () => {
    try {
      await loadingIndicator.create();
      const res = await trainingService.createSession(quiz.id);

      dispatch({
        session: res,
        question: quiz.quiz_questions?.[res.answer_count]
      });
    } catch (e) {
      console.error(e);
      appNotification.toast('Failed to start quiz.', 'Error');
    } finally {
      await loadingIndicator.dismiss();
    }
  }, [quiz, dispatch]);

  if (!quiz) {
    return null;
  }

  return (
    <IonCard className="training-video">
      <IonCardHeader>
        <IonCardTitle>Video</IonCardTitle>
        <IonCardSubtitle>
          Watch the video below{appState.authorized && (<span> and then start the quiz</span>)}.
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <video src={quiz.video} controls />
        {appState.authorized && (
          <HapticButton color="secondary" fill="solid" onClick={startQuiz}>
            Start Quiz
          </HapticButton>
        )}
        {!appState.authorized && (
          <HapticButton color="secondary" fill="solid" onClick={() => router.history.goBack()}>
            Go Back
          </HapticButton>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default TrainingVideo;
