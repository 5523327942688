import util from './util';
import { Plugins } from '@capacitor/core';
import native from './native';
const { Storage } = Plugins;

class Authorization {
  private authKey = 'authorizationToken';
  private clientKey = 'clientId';
  async setToken(token: string) {
    await Storage.set({ key: this.authKey, value: token });
  }

  async getToken() {
    const { value } = await Storage.get({ key: this.authKey });
    return value;
  }

  async getSelectedClientId() {
    const sessionClientId = window.sessionStorage.getItem(this.clientKey);
    const { value } = await Storage.get({ key: this.clientKey });

    if (!native.isNative && sessionClientId) {
      return parseInt(sessionClientId);
    }

    if (!native.isNative && value) {
      window.sessionStorage.setItem(this.clientKey, value);
    }
    return value ? parseInt(value) : null;
  }

  async setSelectedClientId(id: number) {
    if (!native.isNative) {
      window.sessionStorage.setItem(this.clientKey, id.toString());
    }

    await Storage.set({ key: this.clientKey, value: id.toString() });
    util.defer(() =>
      window.dispatchEvent(new CustomEvent('client:changed', { detail: id }))
    );
  }

  async hasSelectedClientId() {
    return !!(await this.getSelectedClientId());
  }

  async clearToken() {
    return await Storage.remove({ key: this.authKey });
  }

  async isAuthorized() {
    return !!(await this.getToken());
  }

  async clear() {
    localStorage.clear();
    return await Storage.clear();
  }

  async load() {
    const authorizationToken = await this.getToken();

    return {
      authorized: !!authorizationToken,
      authorizationToken
    };
  }
}

export default new Authorization();
