import React, { useContext, useState } from 'react';
import '../../styles/components/ReportFilters.scss';
import TModal from './TModal';
import { IonList, IonButton, IonBadge } from '@ionic/react';
import TSelectItem from '../TSelectItem';
import ButtonGrid from '../ButtonGrid';
import { AppContext } from '../../context/AppContext';
import { leadsService } from '../../services';
import { loadingIndicator, http, util } from '../../core';
import TItem from '../TItem';

export interface ReassignLeadsModalProps {
  user: any;
  leadCount: number;
  onConfirm?: () => any;
  onDidDismiss: () => any;
}

const ReassignLeadsModal: React.FC<ReassignLeadsModalProps> = ({
  user,
  onConfirm,
  leadCount,
  onDidDismiss
}) => {
  const { state } = useContext(AppContext);
  const { clientUsers, selectedClientId } = state;
  const [selectedUser, setSelectedUser] = useState<number>(-1);

  const reassign = async () => {
    try {
      if (selectedUser > -1) {
        await loadingIndicator.create();
        await leadsService.reassignLeads(
          selectedClientId,
          user.id,
          selectedUser
        );
      }
      onConfirm?.();
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <TModal
      cssClass="reassign-leads-modal"
      isOpen={true}
      title="Reassign Leads"
      onDidDismiss={onDidDismiss}
      footer={
        <ButtonGrid>
          <IonButton color="secondary" expand="block" onClick={reassign}>
            Reassign
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList>
        <TItem label={`Leads Assigned to ${user.full_name}`} wrapLabel>
          <IonBadge color="secondary" mode="ios" slot="end">
            {util.formatNumber(leadCount)}
          </IonBadge>
        </TItem>
        <TSelectItem
          label="Reassign To"
          value={selectedUser}
          onChange={(e: any) => setSelectedUser(e.detail.value)}
          options={[
            { value: -1, text: 'Nobody' },
            ...clientUsers
              .filter((it: any) => it.id !== user.id)
              .map((it: any) => ({ value: it.id, text: it.full_name }))
          ]}
        />
      </IonList>
    </TModal>
  );
};

export default ReassignLeadsModal;
