import { Plugins } from '@capacitor/core';
import { network, http } from '../core';
import { isEqual } from 'lodash';
const { Storage } = Plugins;

export enum CacheStrategy {
  LIVE,
  CACHED,
  TIMEOUT
}

export enum CacheTTL {
  DEFAULT = 30 * 1000,
  ONEMINUTE = 60 * 1000,
  FIVEMINUTES = 5 * 60 * 1000,
  FIFTEENMINUTES = 15 * 60 * 1000,
  HALFHOUR = 30 * 60 * 1000,
  HOUR = 60 * 60 * 1000,
  DAY = 24 * 60 * 60 * 1000
}

export default abstract class CacheService {
  private cacheKey: string;
  private defaultValue: string;
  private stateKey: string;
  private cacheStrategy: CacheStrategy;
  private timeout: number;
  constructor(
    name: string,
    stateKey: string,
    defaultValue: any,
    strategy: CacheStrategy = CacheStrategy.LIVE,
    timeout: number = CacheTTL.DEFAULT
  ) {
    this.cacheKey = name;
    this.stateKey = stateKey;
    this.defaultValue = defaultValue;
    this.cacheStrategy = strategy;
    this.timeout = timeout;
    window.addEventListener('cache:evict', () => this.evict());
  }
  async cache(data: any, props?: any) {
    await Storage.set({
      key: this.cacheKey,
      value: JSON.stringify({ data, props, ts: Date.now() })
    });
  }

  async evict() {
    await Storage.remove({
      key: this.cacheKey
    });
  }

  async load(props?: any) {
    try {
      const loadCached =
        !network.hasNetworkConnection() ||
        this.cacheStrategy !== CacheStrategy.LIVE;

      let data: any;
      if (loadCached) {
        const cached = await this.getCached();
        if (
          //cached item exists
          cached &&
          //Make sure it's in correct format
          cached.data &&
          cached.ts &&
          //props are equal
          isEqual(cached.props, props) &&
          //strategy is not timeout or under timeout
          (this.cacheStrategy !== CacheStrategy.TIMEOUT ||
            Date.now() - cached.ts < this.timeout)
        ) {
          data = cached.data;
        }
      }

      if (!data) {
        if (network.hasNetworkConnection()) {
          data = await this.request(props);
          await this.cache(data, props);
        } else {
          data = this.defaultValue;
        }
      }

      return {
        [this.stateKey]: data
      };
    } catch (e) {
      http.onHttpError(e);
      return {
        [this.stateKey]: this.defaultValue
      };
    }
  }

  abstract request(props?: any): any;

  async getCached() {
    const item = await Storage.get({ key: this.cacheKey });
    return item?.value && JSON.parse(item.value);
  }
}
