import { Plugins, KeyboardStyle, StatusBarStyle } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
const { Keyboard, StatusBar } = Plugins;

class Theme {
  private systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  private preference = 'system';
  constructor() {
    this.systemPrefersDark.addListener(mediaQuery => this.onChange());
    this.onChange();
  }

  private onChange() {
    const dark = this.isDark();
    if (isPlatform('capacitor')) {
      Keyboard.setStyle({
        style: dark ? KeyboardStyle.Dark : KeyboardStyle.Light
      });

      StatusBar.setStyle({
        style: dark ? StatusBarStyle.Dark : StatusBarStyle.Light
      });
    }

    if (dark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }

    window.dispatchEvent(
      new CustomEvent('theme:changed', { detail: { dark } })
    );
  }

  setPreference(pref: string) {
    this.preference = pref;
    this.onChange();
  }

  isDark(): boolean {
    return (
      this.preference === 'dark' ||
      (this.preference === 'system' && this.systemPrefersDark.matches)
    );
  }

  reset() {
    this.setPreference('system');
  }
}

export default new Theme();
