import { http, authorization, initialization } from '../core';
class LoginService {
  async onLogin(token: string) {
    await authorization.setToken(token);
    const initial = await initialization.onAuthorized();
    return Object.assign(
      {
        selectedClientId: await authorization.getSelectedClientId(),
        authorizationToken: token,
        authorized: true
      },
      initial
    );
  }

  async login(data: any) {
    const res = await http.request({
      method: 'POST',
      url: '/auth/login/',
      data,
      headers: http.FORM_HEADER
    });

    return await this.onLogin(res.data.key);
  }

  async sendPasswordResetRequest({ url, data }: any) {
    return await http.request({
      method: 'POST',
      url,
      data
    });
  }
}

export default new LoginService();
