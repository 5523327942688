import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';

class LanguagesService extends CachedService {
  constructor() {
    super('languages', 'languages', [], CacheStrategy.TIMEOUT, CacheTTL.DAY);
  }

  async request() {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: '/languages/',
      type: 'language',
      exclusive: true
    });

    return res.data;
  }
}

export default new LanguagesService();
