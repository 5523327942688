import { http } from '../core';
import CachedService, { CacheStrategy } from './cached-service';
import qs from 'querystring';

class ClientUsersService extends CachedService {
  constructor() {
    super('client-users', 'clientUsers', [], CacheStrategy.TIMEOUT);
  }

  async request(clientId: any) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/users/?page_size=500`
    });

    return res.data.results;
  }

  async get(clientId: any, id: any) {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/users/${id}/`
    });

    return data;
  }

  getById(id: number, users: any[]) {
    return users.find((it: any) => it.id === id);
  }

  getUserName(user: any) {
    return user ? `${user.first_name} ${user.last_name}` : '';
  }

  getNameById(id: number, users: any[]) {
    const user = this.getById(id, users);

    return this.getUserName(user);
  }

  async update(clientId: any, userId: any, changes: any) {
    const { data } = await http.authorizedRequest({
      method: 'PATCH',
      url: `/clients/${clientId}/users/${userId}/`,
      data: changes
    });

    this.evict();

    return data;
  }

  async create(clientId: any, user: any) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/clients/${clientId}/users/`,
      data: user
    });

    this.evict();

    return data;
  }

  async delete(clientId: any, user: any) {
    const res = await http.authorizedRequest({
      method: 'POST',
      url: `/clients/${clientId}/users/remove/`,
      data: user
    });

    this.evict();

    return res;
  }

  async add(clientId: any, user: any) {
    const res = await http.authorizedRequest({
      method: 'POST',
      url: `/clients/${clientId}/users/add/`,
      data: user
    });

    this.evict();

    return res;
  }

  async checkIntent(clientId: any, email: string, intent: string) {
    const params = { email__iexact: email, intent };
    const query = qs.stringify(params);
    return await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/users/?${query}`
    });
  }
}

export default new ClientUsersService();
