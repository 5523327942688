import React from 'react';
import { IonIcon } from '@ionic/react';
import { star } from 'ionicons/icons';

class UIUtil {
  renderRating(value: number, max = 5) {
    return Array.from(Array(max).keys()).map((it: number) => (
      <IonIcon
        key={it}
        color={it < Math.round(value) ? 'warning' : ''}
        icon={star}
      />
    ));
  }
}

export default new UIUtil();
