import { Calendar } from '@ionic-native/calendar';
import { createEvent } from 'ics-browser';
import moment from 'moment';
import util from './util';

export type ICSDuration = {
  hours: number;
  minutes: number;
};

export type ICSGeo = {
  lat: number;
  lon: number;
};

export type ICS = {
  title: string;
  duration: ICSDuration;
  start: Date;
  description?: string;
  location?: string;
  geo?: ICSGeo;
  url?: string;
  status?: string;
};

class CalendarUtility {
  createEventInteractively(
    title: string,
    start: Date,
    end: Date,
    location?: string,
    notes?: string
  ) {
    Calendar.createEventInteractively(title, location, notes, start, end);
  }

  async generateICS(data: ICS) {
    return await new Promise((resolve, reject) => {
      const arr = moment(data.start)
        .format('YYYY M D HH mm')
        .split(' ')
        .map((it: string) => parseInt(it));

      createEvent(
        Object.assign(data, { start: arr, productId: 'TECOBI' }),
        (error: any, value: any) => {
          if (error) {
            return reject(error);
          }

          resolve(value);
        }
      );
    });
  }

  async downloadICS(data: ICS, filename = 'appointment.ics') {
    const ics = await this.generateICS(data);
    const uri = util.stringToDataURI(ics as string, 'text/calendar');
    util.downloadDataURI(uri, filename);
  }
}

export default new CalendarUtility();
