import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonToast,
  IonLoading
} from '@ionic/react';
import http from '../core/http';
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { AppContext } from '../context/AppContext';
import { TInput, Logo } from '../components';
import { PasswordResetModal } from '../components/modals';
import { util } from '../core';
import { loginService } from '../services';
import { useLocation, useHistory } from 'react-router';

const LoginPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const form = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(
    !!location.pathname.match(/\/forgot-password|reset-password\//i)
  );

  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [hint, setHint] = useState('');
  const [height, setHeight] = useState(window.innerHeight);
  const { dispatch } = useContext(AppContext);
  util.setTitle('Login');

  const login = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (loading || !form.current) {
        return;
      }

      setMessageType('Error');
      setMessage('');
      const data = new FormData(form.current);
      const email = data.get('email');
      const password = data.get('password');
      if (!email || !password) {
        return setMessage('Please fill out the form completely.');
      }

      try {
        setLoading(true);
        dispatch({
          type: 'set',
          value: await loginService.login(data)
        });
      } catch (e) {
        console.error(e);
        setLoading(false);
        setMessage(http.getErrorMessage(e));
      }
    },
    [dispatch, loading]
  );

  useEffect(() => {
    const handler = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  });

  return (
    <IonPage className="t-page">
      <IonContent className="login">
        <form method="POST" ref={form} onSubmit={login}>
          <IonGrid
            style={{
              height
            }}
          >
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol style={{ maxWidth: 600, margin: 'auto' }}>
                <div className="ion-text-center ion-padding">
                  <Logo />
                </div>
                <div className="ion-padding">
                  <IonItem
                    className="input-background border-radius-top"
                    lines="full"
                  >
                    <TInput
                      type="email"
                      placeholder="Email"
                      onEnterPressed={login}
                      id="login-email"
                      name="email"
                    />
                  </IonItem>
                  <IonItem
                    className="input-background border-radius-bottom"
                    lines="none"
                  >
                    <TInput
                      type="password"
                      placeholder="Password"
                      id="login-password"
                      name="password"
                      onEnterPressed={login}
                    />
                  </IonItem>
                </div>
                <div className="ion-padding">
                  <IonButton
                    type="submit"
                    color="success"
                    className="no-margin"
                    disabled={loading}
                    onClick={login}
                    expand="block"
                  >
                    Login
                  </IonButton>
                  <div
                    className="forgot-password-link link-button"
                    onClick={() => {
                      setShowForgotPasswordModal(true);
                      setMessage('');
                      setHint(
                        new FormData(form.current).get('email') as string
                      );
                    }}
                  >
                    Forgot your password?
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <IonToast
          isOpen={!!message}
          position="top"
          header={messageType}
          message={message}
          buttons={[{ text: 'Dismiss', role: 'cancel' }]}
        />
        <PasswordResetModal
          isOpen={showForgotPasswordModal}
          onWillDismiss={({ message, email }) => {
            if (email && message) {
              setMessageType('Success');
              setMessage(message);
              const element = document.getElementById(
                'login-email'
              ) as HTMLIonInputElement;
              if (element) {
                element.value = email;
              }
            }
          }}
          onDidDismiss={() => {
            setShowForgotPasswordModal(false);
            history.replace('/');
          }}
          hint={hint}
        />
      </IonContent>
      <IonLoading isOpen={loading} />
    </IonPage>
  );
};

export default LoginPage;
