import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  IonItem,
  IonLabel,
  IonText,
  IonIcon,
  IonInput,
  IonPopover,
  IonList
} from '@ionic/react';
import { useDarkTheme } from '../hooks';
import '../styles/components/LocalInfo.scss';
import { browser } from '../core';
import moment from 'moment';
import 'moment-timezone';
import { time, reader, call as callIcon } from 'ionicons/icons';
import css from 'classnames';
import { clientHoursService, leadsService } from '../services';
import { LeadContext } from '../context/LeadContext';
import { AppContext } from '../context/AppContext';
import phoneNinja from '../icons/phoneNinja.svg';
import TimeAgo from 'react-timeago'

const LeadLocalInfo: React.FC = () => {
  const { state } = useContext(LeadContext);
  const { state: appState } = useContext(AppContext);
  const { user } = appState;
  const { lead, clientHours } = state;
  const { current_weather, client_name, client_is_full_service, client_important_note, client_phone_ninja } = lead ?? {};
  const [showHours, setShowHours] = useState(false);
  const tz = leadsService.getTimezone(lead);
  const clientName = client_name;
  const clientPhoneNinja = client_phone_ninja && user.is_staff;
  const clientIsFullService = client_is_full_service && user.is_staff;
  const clientImportantNote = client_important_note && user.is_staff;
  const [localTime, setLocalTime] = useState(
    clientHoursService.getLocalTimeString(tz)
  );
  const [currentStatus, setCurrentStatus] = useState<any>();
  const { name, weather, coord } = current_weather ?? {};
  const { temp } = current_weather?.main ?? {};
  const { main, icon_url, description } = weather?.[0] ?? {};
  const { lon, lat } = coord ?? {};
  const isDark = useDarkTheme();
  const fullServiceImg = isDark ? '/assets/full-service-dk.png' : '/assets/full-service-lt.png';

  const loadHours = useCallback(async () => {
    setCurrentStatus(clientHoursService.getCurrentStatus(clientHours, tz));
  }, [tz, clientHours]);

  useEffect(() => {
    loadHours();
  }, [loadHours]);

  useEffect(() => {
    const timers = [
      setInterval(
        () => setLocalTime(clientHoursService.getLocalTimeString(tz)),
        1000
      ),
      setInterval(
        () =>
          setCurrentStatus(
            clientHoursService.getCurrentStatus(clientHours, tz)
          ),
        10000
      )
    ];
    return () => timers.forEach(clearInterval);
  });

  const openWeather = () => {
    browser.open(`https://darksky.net/forecast/${lat},${lon}`);
  };

  if (!lead) {
    return null;
  }

  return (
    <>
      <IonItem className="pointer" onClick={() => setShowHours(true)}>
        <IonIcon
          slot="start"
          className="no-margin client-hours-icon"
          icon={time}
        />
        <IonLabel className="client-hours ion-multiline">
          {clientName}
          <div
            className={css('detail ion-text-wrap', {
              open: currentStatus?.isOpen
            })}
          >
            {currentStatus?.text}
          </div>
        </IonLabel>
        <IonText slot="end">
          <span className="localtime detail">{localTime}</span>
        </IonText>
      </IonItem>
      {clientIsFullService && (
      <IonItem>
        <img src={fullServiceImg} alt="full service" />
      </IonItem>
      )}
      {clientPhoneNinja && (
      <IonItem color="tertiary">
        <IonIcon slot="start" src={phoneNinja} />
        <IonLabel>Phone Ninja</IonLabel>
      </IonItem>
      )}

      {clientImportantNote && (
        <IonItem color="danger">
          <IonIcon slot="start" icon={reader} />
          <IonLabel  className="ion-text-wrap">{client_important_note}</IonLabel>
        </IonItem>
      )}

      {lead?.needs_call_since && (
        <IonItem color="orange">
          <IonIcon slot="start" icon={callIcon} />
          <IonLabel position="stacked">Needs call since</IonLabel>
          <IonInput readonly>
            <TimeAgo date={lead?.needs_call_since} live={true} />
          </IonInput>
        </IonItem>
      )}

      {temp && (
        <IonItem className="weather pointer" onClick={openWeather}>
          <img
            slot="start"
            className="weather-icon"
            src={icon_url}
            alt={main}
          />
          <h1 slot="start" className="weather-temp">
            {Math.round(temp)}º
          </h1>

          <IonLabel>
            <IonText className="ion-multiline">
              <span className="weather-city">{name}</span>
              <span className="weather-conditions detail ion-text-capitalize">
                {description}
              </span>
            </IonText>
          </IonLabel>
        </IonItem>
      )}
      <IonPopover
        mode="md"
        cssClass="client-hours-popover"
        isOpen={showHours}
        onDidDismiss={() => setShowHours(false)}
      >
        <IonList lines="full">
          <IonItem>
            <IonIcon
              slot="start"
              className="no-margin client-hours-icon"
              icon={time}
            />
            <IonLabel>Hours for {clientName}</IonLabel>
          </IonItem>
          {clientHours?.map(it => (
            <IonItem key={it.weekday}>
              <IonLabel position="stacked" className="light-text-color">
                <strong>{it.weekday}</strong>
              </IonLabel>
              <IonText>
                <p>
                  {moment(it.from_hour, 'HH:mm:ss').format('h:mma')} -{' '}
                  {moment(it.to_hour, 'HH:mm:ss').format('h:mma')}
                </p>
              </IonText>
            </IonItem>
          ))}
        </IonList>
      </IonPopover>
    </>
  );
};

export default LeadLocalInfo;
