import { actionSheetController, ActionSheetButton } from '@ionic/core';
import util from './util';
import moment from 'moment';
class ActionSheet {
  async show(
    buttons: ActionSheetButton[],
    header?: string,
    subHeader?: string
  ) {
    const element = await actionSheetController.create({
      header,
      subHeader,
      buttons
    });
    element.present();
  }

  async showEmail(email: string, title?: string) {
    return this.show(
      [
        {
          text: 'Compose Email',
          handler: () => util.composeEmail(email)
        },
        {
          text: 'Copy',
          handler: () => util.copyToClipboard(email)
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ],
      title,
      email
    );
  }

  async showPhone(phone: any, title?: string) {
    return this.show(
      [
        {
          text: 'Call',
          handler: () => console.log('call')
        },
        {
          text: 'Text',
          handler: () => console.log('text')
        },
        {
          text: 'Copy',
          handler: () => util.copyToClipboard(phone)
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ],
      title,
      phone
    );
  }

  async showCalendar(
    date: any,
    title?: string,
    format: string = 'MMM D, YYYY h:mma'
  ) {
    const displayDate = moment(date).format(format);

    return this.show(
      [
        {
          text: 'Show in Calendar',
          handler: () => {}
        },
        {
          text: 'Create Event',
          handler: () => {}
        },
        {
          text: 'Copy',
          handler: () => util.copyToClipboard(displayDate)
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ],
      title,
      displayDate
    );
  }
}

export default new ActionSheet();
