import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { useRouter } from '../hooks';
import { AppContext } from '../context/AppContext';
import { TReportsPage, TSearchBar } from '../components';
import { BlastDetailModal } from '../components/modals';
import { IonToolbar } from '@ionic/react';
import { reportsService } from '../services';
import { blastsService } from '../services';
import '../styles/Reports.scss';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { util } from '../core';


const BlastReport: React.FC = () => {
  util.createTecobiDarkDataTableTheme();
  const { state } = useContext(AppContext);
  const { clients } = state;
  const showSelectedClientOnlyToggle = clients?.length > 1;
  // Used for is_full_service hide avg response time chart
  const { selectedClientId, user } = state;
  const [reports, setReports] = useState<any>();
  const [blast, setBlast] = useState();
  const router = useRouter();
  const blastType = useMemo(() => router.query.type, [router]);
  const tableTitle = blastType === 'lead' ? 'Lead Blasts' : 'Customer Blasts';
  const hideClassification = blastType === 'customer';
  const [filteredReports, setFilteredReports] = useState<any>();
  const doSearch = (val: string) => {
    const searchResults = reports?.filter((it:any) => {
      return it.client_name.toLowerCase().includes(val.toLowerCase());
    })
    setFilteredReports(searchResults);
  };

  const columns = [
    {
      name: 'Name',
      selector: 'client_name',
      sortable: true,
      //maxWidth: '200px'
    },
    {
      name: 'Send at',
      selector: 'send_at',
      sortable: true,
      right: true,
      format: (row: any)=> moment(row.send_at).format('lll'),
      maxWidth: '170px'
    },
    {
      name: 'Sender',
      selector: 'sender',
      sortable: true,
      right: true,
      maxWidth: '140px'
    },
    {
      name: 'Sent',
      selector: 'sent_count',
      sortable: true,
      right: true,
      maxWidth: '30px'
    },
    {
      name: 'Opt-out',
      selector: 'optout_count',
      sortable: true,
      right: true,
      maxWidth: '30px'
    },
    {
      name: 'Delivered',
      selector: 'delivered_status_count',
      sortable: true,
      right: true,
      maxWidth: '30px'
    },
    {
      name: 'Undelivered',
      selector: 'undelivered_status_count',
      sortable: true,
      right: true,
      maxWidth: '30px'
    },
    {
      name: 'Response',
      selector: 'response_rate',
      sortable: true,
      right: true,
      format: (row: any)=> `${row.response_rate}%`,
      maxWidth: '30px'
    },
    {
      name: 'Appts Set',
      selector: 'appointments_set_count',
      sortable: true,
      right: true,
      maxWidth: '30px'
    },
    {
      name: 'Sold',
      selector: 'appointments_sold_count',
      sortable: true,
      right: true,
      maxWidth: '30px'
    },
  ];

  useEffect(() => {
    setFilteredReports(reports);
  }, [reports]);

  const loadData = useCallback(
    async ({ since, until, clientId, filters }: any) => {
      const reportData = await reportsService.retrieveBlastReports(
        since,
        until,
        blastType,
        filters,
        selectedClientId,
      )
      setReports(reportData);
      return {
        reportData,
      };
    },
    [blastType, selectedClientId]
  );

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ showSelectedClientOnlyToggle, hideClassification }}
    >
      {({ state }) => {
        return (
          <>
            <IonToolbar>
              <TSearchBar onSearch={doSearch} />
            </IonToolbar>
            <DataTable
              title={tableTitle}
              columns={columns}
              data={filteredReports}
              theme={user.mobile_theme === 'dark' ? 'tecobi-dark' : 'default'}
              pagination={filteredReports?.length > 10}
              onRowClicked={async (row: any) => {
                const b = await blastsService.get(row.client_id, row.id, blastType+'s');
                setBlast(b);
              }}
              paginationServer={false}
              highlightOnHover={true}
            />
            {blast && (
              <BlastDetailModal
                item={blast}
                blastType={blastType + 's'}
                onDidDismiss={() => setBlast(undefined)}
              />
            )}
          </>
        );
      }}
    </TReportsPage>
  );
};

export default BlastReport;
