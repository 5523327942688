import { http, authorization, native, loadingIndicator } from '../core';
import qs from 'qs';
import PagedService from './paged-service';
import fs from '../core/fs';
import { FilesystemDirectory } from '@capacitor/core';
import { FileOpener } from '@ionic-native/file-opener';
import appNotifications from '../core/app-notifications';

class CreditApplicationsService extends PagedService {
  async list({ clientId, search }: any) {
    try {
      const params = {} as any;

      if (search) {
        params.search = search;
      }

      const query = qs.stringify(params);

      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/credit-applications/?${query}`
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async openApplicationOnNative(id: string, url: string) {
    await loadingIndicator.create();
    try {
      const data = new FormData();
      const token = (await authorization.getToken()) ?? '';
      data.append('Token', token);

      const res = await http.request({
        method: 'POST',
        url,
        data,
        responseType: 'arraybuffer'
      });

      const uri = await fs.write(
        `${id}.pdf`,
        res.data,
        FilesystemDirectory.Cache
      );

      await FileOpener.open(uri, res.headers['content-type']);
    } catch (e) {
      console.error(e);
      appNotifications.toast('Failed to open credit application.', 'Error');
    } finally {
      loadingIndicator.dismiss();
    }
  }

  openApplicationPDF(id: string) {
    const url = `https://tecobi.com/credit-application/pdf/${id}/`;

    if (native.isNative) {
      this.openApplicationOnNative(id, url);
    } else {
      window.open(`/form-post/${encodeURIComponent(url)}/`);
    }
  }

  async sendLinkAndCode(token: any) {
    try {
      await http.authorizedRequest({
        method: 'POST',
        url: `/lead/${token}/send-credit-app/`
      });
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new CreditApplicationsService();
