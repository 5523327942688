import firebase from 'firebase/app';
import 'firebase/database';

class LiveDB {
  useReference(path: string) {
    return firebase.database().ref(path);
  }
}

export default new LiveDB();
