import React from 'react';
import ChatToolButton from './ChatToolButton';
import { trash } from 'ionicons/icons';
import { IonItem } from '@ionic/react';
import TMedia, { TMediaProps } from './TMedia';

export interface ChatFilePreviewProps extends TMediaProps {
  onRemoveClick: () => void;
}

const ChatFilePreview: React.FC<ChatFilePreviewProps> = ({
  onRemoveClick,
  style,
  ...rest
}) => {
  return (
    <IonItem lines="full" className="ion-justify-center">
      <TMedia
        style={Object.assign(
          { margin: '15px auto', width: 'auto' },
          style || {}
        )}
        {...rest}
      />
      <ChatToolButton
        icon={trash}
        title="Remove"
        color="danger"
        action={onRemoveClick}
      />
    </IonItem>
  );
};

export default ChatFilePreview;
