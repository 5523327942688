import React, { useLayoutEffect, useCallback, useRef } from 'react';
import Chart, { ChartDataSets } from 'chart.js';
import 'chartjs-gauge';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle
} from '@ionic/react';
import css from 'classnames';
import '../styles/components/TChart.scss';
import { useDarkTheme } from '../hooks';
import randomColor from 'randomcolor';

export enum TChartColors {
  BLUE = '56, 128, 255',
  GREEN = '142, 194, 82',
  PURPLE = '112, 68, 255',
  RED = '245, 61, 61',
  YELLOW = '255, 206, 0',
  DARK = '56, 128, 255'
}

export interface TChartProps {
  type: string;
  data?: Array<any>;
  datasets?: ChartDataSets[];
  value?: number;
  labels?: string[];
  title?: string;
  description?: string;
  borderWidth?: number;
  options?: any;
  children?: any;
  legend?: boolean;
  uom?: string;
}

type DatasetConfig = {
  value?: number;
} & ChartDataSets[];

export function generateColors(dataLength: number, isGauge?: boolean) {
  const colors = (isGauge
    ? [TChartColors.RED, TChartColors.YELLOW, TChartColors.GREEN]
    : [
        TChartColors.BLUE,
        TChartColors.GREEN,
        TChartColors.PURPLE,
        TChartColors.RED,
        TChartColors.YELLOW
      ]) as any[];

  if (dataLength > colors.length) {
    while (colors?.length < dataLength) {
      colors.push(randomColor({ format: 'rgbArray', hue: 'random' }));
    }
  }

  return colors;
}

export function getBackgroundColor(color: string) {
  return `rgba(${color}, 0.2)`;
}

export function getBorderColor(color: string) {
  return `rgb(${color})`;
}

export function colorForIndex(colors: any[], index: number) {
  const color: string = colors[index] ?? '0,0,0';
  return {
    backgroundColor: getBackgroundColor(color),
    borderColor: getBorderColor(color),
    borderWidth: 1
  };
}

export function colorArrayForLength(dataLength: number) {
  const colors = generateColors(dataLength);
  return {
    borderColor: colors.map(getBorderColor),
    backgroundColor: colors.map(getBackgroundColor)
  };
}

const TChart: React.FC<TChartProps> = ({
  type,
  data = [],
  datasets,
  borderWidth = 1,
  title,
  description,
  labels,
  options,
  value,
  children,
  legend,
  uom = '%'
}) => {
  const isDark = useDarkTheme();
  const isGauge = type === 'gauge';
  if (isGauge && !data.length) {
    data = [33, 66, 100];
  }

  const canvasRef = useRef<any>();
  const chart = useRef<Chart | null>();
  const dataLength = datasets ? datasets.length : data?.length ?? 0;

  const colors = generateColors(dataLength, isGauge);

  const render = useCallback(() => {
    if (canvasRef.current) {
      if (chart.current) {
        chart.current.destroy();
      }

      const getDefaults = (index: number) => ({
        backgroundColor: colors
          .slice(index, dataLength)
          .map(getBackgroundColor),
        borderColor: colors.slice(index, dataLength).map(getBorderColor),
        borderWidth
      });

      chart.current = new Chart(canvasRef.current, {
        type,
        data: {
          labels,
          datasets: datasets
            ? (datasets.map((d: ChartDataSets, index: number) => ({
                ...getDefaults(index),
                ...d
              })) as ChartDataSets[])
            : ([
                {
                  value,
                  data,
                  ...getDefaults(0)
                }
              ] as DatasetConfig)
        },
        options: Object.assign(
          {
            animation: false,
            needle: {
              color: isDark ? 'rgba(255,255,255,.9)' : 'rgb(0,0,0)'
            },
            legend: {
              display: legend,
              position: 'bottom'
            },
            valueLabel: {
              display: false
            }
          },
          options || {}
        )
      });
    }
  }, [
    value,
    data,
    canvasRef,
    borderWidth,
    labels,
    options,
    type,
    legend,
    colors,
    isDark,
    datasets,
    dataLength
  ]);

  useLayoutEffect(render, [data]);

  return (
    <IonCard className={css('t-chart', { gauge: isGauge })}>
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
        {description && <IonCardSubtitle>{description}</IonCardSubtitle>}
      </IonCardHeader>
      <IonCardContent>
        <canvas ref={canvasRef} />
        {isGauge && (
          <div className="gauge-label">
            {value}
            {uom}
          </div>
        )}
      </IonCardContent>
      {children}
    </IonCard>
  );
};

export default TChart;
