import React, { useContext } from 'react';
import { loadingIndicator, actionSheet } from '../core';
import { LeadContext } from '../context/LeadContext';
import { leadsService, appointmentsService } from '../services';
import { IonItem, IonIcon, IonLabel, IonButton } from '@ionic/react';
import moment from 'moment';
import TExpandableItemGroup from './TExpandableItemGroup';
import { calendar, ellipsisVertical, closeCircleOutline, link } from 'ionicons/icons';
import TButtonItem from './TButtonItem';
import { SetAppointmentModal, SelfSchedulingLinkModal } from './modals';
import {
  appendMessageText
} from '../context/ChatMessageBoxContext';
const LeadAppointment: React.FC = () => {
  const { state, dispatch } = useContext(LeadContext);

  const { lead } = state;

  const { appointments = [] } = lead || {};

  const appt = appointmentsService.findActive(appointments);

  const cancelAppointment = async (appointment: any) => {
    try {
      if (!lead) {
        return;
      }

      const { client, id } = lead;

      await loadingIndicator.create();

      if (appointment) {
        await leadsService.cancelAppointment(
          client,
          id,
          appointment
        );
      }
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <TExpandableItemGroup title="Appointment" badge={appt ? 1 : 0}>
      {appt && (
        <IonItem>
          <IonIcon
            icon={appt.missed ? closeCircleOutline : calendar}
            slot="start"
          />
          <IonLabel className="ion-text-wrap ion-multiline">
            {moment(appt.appointment_time)
              .tz(leadsService.getTimezone(lead))
              .format('dddd, MMMM D, YYYY h:mma')}
            <span className="detail ion-text-capitalize">
              Status: {appt.status}
            </span>
          </IonLabel>
          <IonButton
            slot="end"
            fill="clear"
            onClick={() =>
              actionSheet.show([
                {
                  text: 'Cancel Appointment',
                  role: 'destructive',
                  handler: () => {
                    cancelAppointment(appt);
                  }
                },
                {
                  text: 'Mark as Missed',
                  handler: () => {
                    (async () => {
                      if (!lead) {
                        return;
                      }

                      try {
                        await loadingIndicator.create();
                        const res = await leadsService.appointmentMissed(
                          lead.client,
                          lead.id,
                          appt
                        );
                        dispatch({
                          type: 'set',
                          value: { lead: res.lead }
                        });
                      } finally {
                        loadingIndicator.dismiss();
                      }
                    })();
                  }
                },
                {
                  text: 'Mark as Showed',
                  handler: () => {
                    (async () => {
                      if (!lead) {
                        return;
                      }

                      try {
                        await loadingIndicator.create();
                        const res = await leadsService.appointmentShowed(
                          lead.client,
                          lead.id,
                          appt
                        );
                        dispatch({
                          type: 'set',
                          value: { lead: res.lead }
                        });
                      } finally {
                        loadingIndicator.dismiss();
                      }
                    })();
                  }
                },
                {
                  text: 'Mark as Showed/Lost',
                  handler: () => {
                    (async () => {
                      if (!lead) {
                        return;
                      }

                      try {
                        await loadingIndicator.create();
                        const res = await leadsService.appointmentShowedLost(
                          lead.client,
                          lead.id,
                          appt
                        );
                        dispatch({
                          type: 'set',
                          value: { lead: res.lead }
                        });
                      } finally {
                        loadingIndicator.dismiss();
                      }
                    })();
                  }
                },
                {
                  text: 'Dismiss',
                  role: 'cancel'
                }
              ])
            }
          >
            <IonIcon icon={ellipsisVertical} />
          </IonButton>
        </IonItem>
      )}
      <TButtonItem
        color="secondary"
        onClick={() =>
          dispatch({
            type: 'set',
            value: { showSetAppointmentModal: true, appointments }
          })
        }
      >
        <IonIcon icon={calendar} slot="start" />
        {appt ? 'Change' : 'Set'} Appointment
      </TButtonItem>

      <TButtonItem
        color="secondary"
        onClick={() =>
          dispatch({
            type: 'set',
            value: { showSelfSchedulingLinkModal: true }//, appointments }
          })
        }
      >
        <IonIcon icon={link} slot="start" />
        Self-Scheduling Link
      </TButtonItem>

      {state.showSetAppointmentModal && (
        <SetAppointmentModal
          isOpen={true}
          onDidDismiss={() =>
            dispatch({
              type: 'set',
              value: { showSetAppointmentModal: false }
            })
          }
        />
      )}

      {state.showSelfSchedulingLinkModal && (
        <SelfSchedulingLinkModal
          isOpen={true}
          onSend={appendMessageText}
          onDidDismiss={() =>
            dispatch({
              type: 'set',
              value: { showSelfSchedulingLinkModal: false }
            })
          }
        />
      )}

    </TExpandableItemGroup>
  );
};

export default LeadAppointment;
