import { http } from '../core';

class MagicLinkService {
  async fetch(clientId: string|number, leadId: string|number) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: '/magic-link/',
        data: {
          client: clientId,
          lead: leadId
        }
      });

      return data;
    } catch (e) {
      console.error(e);
      return {short_url: null, long_url: null};
    }
  }
}

export default new MagicLinkService();
