import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';
import moment from 'moment';
import 'moment-timezone';

class ClientHoursService extends CachedService {
  constructor() {
    super(
      'client-hours',
      'clientHours',
      [],
      CacheStrategy.TIMEOUT,
      CacheTTL.HOUR
    );
  }

  async request(clientId: any) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/hours/`
    });

    return res.data;
  }

  private hoursAsMap(hours: any[]) {
    return hours?.reduce((o: any, it) => {
      o[moment(it.weekday, 'dddd').weekday()] = it;
      return o;
    }, {});
  }

  private getNextOpen(now: any, hours: any[]) {
    let weekday = now.weekday();
    let nextOpen;

    const hoursMap = this.hoursAsMap(hours);
    for (let i = 0; i < 7; i++) {
      weekday++;
      if (weekday > 6) {
        weekday = 0;
      }
      nextOpen = hoursMap[weekday];
      if (nextOpen) {
        break;
      }
    }

    return nextOpen;
  }

  private getTodaysHours(now: any, hours: any[]) {
    const dayName = now.format('dddd');
    return hours?.find(it => it.weekday === dayName);
  }

  getLocalTimeString(tz: string) {
    return moment()
      .tz(tz)
      .format('h:mma');
  }

  private getDateAt(date: any, time: string, tz: string) {
    const parseFormat = 'MM/DD/YY HH:mm:ss';
    return moment.tz(
      `${moment(date)
        .tz(tz)
        .format('MM/DD/YY')} ${time}`,
      parseFormat,
      tz
    );
  }

  private getTodayAt(time: string, tz: string) {
    return this.getDateAt(moment(), time, tz);
  }

  isDuringBusinessHours(date: any, hours: any, tz: string) {
    const localTime = moment(date).tz(tz);
    const todaysHours = this.getTodaysHours(localTime, hours);
    if (!todaysHours) {
      return {
        closed: true,
        during: false
      };
    }

    const open = this.getDateAt(date, todaysHours?.from_hour, tz);
    const close = this.getDateAt(date, todaysHours?.to_hour, tz);
    return {
      closed: false,
      during: localTime.isBetween(open, close, undefined, '[)')
    };
  }

  getCurrentStatus(hours: any[], tz: string) {
    const displayFormat = 'h:mma';
    const localTime = moment().tz(tz);
    const todaysHours = this.getTodaysHours(localTime, hours);
    const nextOpen = this.getNextOpen(localTime, hours);
    const isTomorrow =
      nextOpen &&
      localTime
        .clone()
        .add(1, 'day')
        .format('dddd') === nextOpen.weekday;
    const isClosedForDay = !todaysHours;
    let text;
    let isOpen = false;

    if (todaysHours) {
      const open = this.getTodayAt(todaysHours?.from_hour, tz);
      const close = this.getTodayAt(todaysHours?.to_hour, tz);
      isOpen = localTime.isBetween(open, close);
      const willOpen = localTime.isBefore(open);
      text = isOpen
        ? `Open, Closes at ${close.format(displayFormat)}`
        : willOpen
        ? `Closed, Opens at ${open.format(displayFormat)}`
        : `Closed, Opens ${
            isTomorrow ? 'Tomorrow' : nextOpen?.weekday
          } at ${this.getTodayAt(nextOpen?.from_hour, tz).format(
            displayFormat
          )}`;
    }

    if (isClosedForDay && nextOpen) {
      text = `Closed Today, Opens ${
        isTomorrow ? 'Tomorrow' : nextOpen.weekday
      } at ${this.getTodayAt(nextOpen.from_hour, tz).format(displayFormat)}`;
    }

    return {
      todaysHours,
      isOpen,
      text,
      isClosedForDay,
      nextOpen
    };
  }
}

export default new ClientHoursService();
