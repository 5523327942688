import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';

class ClientMentionUsersService extends CachedService {
  constructor() {
    super(
      'client-mention-users',
      'clientMentionUsers',
      [],
      CacheStrategy.TIMEOUT,
      CacheTTL.ONEMINUTE
    );
  }

  async request(clientId: any) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/mention-users/`
    });

    return res.data;
  }
}

export default new ClientMentionUsersService();
