import React, { useState, useCallback, useEffect } from 'react';
import TModal from './TModal';
import { modalController } from '@ionic/core';
import { IonButton, IonList, IonItem, IonIcon, IonThumbnail, IonImg } from '@ionic/react';
import { TInputItem } from '../';
import '../../styles/components/DocumentModal.scss';
import { leadsService, leadDocumentService } from '../../services';
import { loadingIndicator, appNotification } from '../../core';
import { checkmarkCircle, checkmarkCircleOutline } from 'ionicons/icons';

export interface DocumentModalProps {
  lead: any;
  messageId: number;
  isOpen?: boolean;
  onDidDismiss?: () => void;
}

const DocumentModal: React.FC<DocumentModalProps> = ({
  onDidDismiss,
  lead,
  messageId,
  isOpen = false
}) => {
  const [message, setMessage] = useState<any>();
  const [mediaIds, setMediaIds] = useState<any>();

  const saveToDocumentCenter = useCallback(async () => {
    if (!message) {
      return null;
    }
    await loadingIndicator.create();
    try {
      const reqs: any = message?.smsmedia_set?.filter((it: any) => mediaIds.includes(it.id))?.map((it: any) => {
        // trim off the signed auth url
        const url = it.media?.split('?')[0]
        const doc = {
          document_type: it.content_type,
          name: it.fileName ?? url?.split('/')?.pop(),
          url
        };
        leadDocumentService.create(lead?.client, lead?.id, doc);
      });
      await Promise.all(reqs);
      await appNotification.toast('Items have been added to document center.', 'Success');
      modalController.dismiss();
    } finally {
      loadingIndicator.dismiss();
    }
  }, [lead, message, mediaIds]);

  const addOrRemove = (mediaId: number) =>
    mediaIds.includes(mediaId) ? setMediaIds(mediaIds.filter((i: number) => i !== mediaId)) : setMediaIds([ ...mediaIds, mediaId ]);

  const documentLead = useCallback(() => {
    saveToDocumentCenter();
  }, [saveToDocumentCenter]);

  const fetchMessage = useCallback(async () => {
    const data: any = await leadsService.getMessage(lead.client, lead.id, messageId);
    setMediaIds(data?.smsmedia_set?.map((it: any) => it.id));
    return setMessage(data);
  }, [lead, messageId]);

  useEffect(() => {
    fetchMessage();
  }, [fetchMessage]);


  return (
    <TModal
      isOpen={isOpen}
      title="Save to Document Center"
      onDidDismiss={onDidDismiss}
      cssClass="document-modal"
      footer={
        <>
          <IonItem lines="none">
            <IonButton
              slot="end"
              color="success"
              fill="clear"
              size="default"
              disabled={!mediaIds?.length}
              onClick={documentLead}
            >
              Save to Document Center
            </IonButton>
          </IonItem>
        </>
      }
    >
      <div className="document-container">
        <IonList>
        {message?.smsmedia_set?.map((it: any, index: number) => (
          <TInputItem
            key={it.id}
            label="Name"
            labelPosition="stacked"
            value={it?.media?.split('/')?.pop()?.split('?')[0]}
            required
            onChange={(e: any) => {
              const messageCopy = Object.assign({}, message);
              messageCopy.smsmedia_set = messageCopy.smsmedia_set.map((md: any) => {
                if (md.id === it.id) {
                  md.fileName = e.detail.value;
                }
                return md;
              });
              setMessage(messageCopy);
            }}
          >
            <IonThumbnail slot="start">
              <IonImg src={it.media} />
            </IonThumbnail>
            <IonButton onClick={(e: any) => addOrRemove(it.id)} size="large" fill="clear" slot="end" color="primary" >
              <IonIcon icon={mediaIds?.includes(it.id) ? checkmarkCircle : checkmarkCircleOutline} />
            </IonButton>
          </TInputItem>
        ))}
        </IonList>
      </div>
    </TModal>
  );
};

export default DocumentModal;
