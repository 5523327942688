import React, { useContext } from 'react';
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonSpinner,
  IonImg,
  IonButton,
} from '@ionic/react';

import bars from '../icons/bars.svg';
import {
  checkmarkCircle,
  mail,
  helpBuoy,
  refresh,
  arrowRedo,
  document as documentIcon,
} from 'ionicons/icons';
import TImage from './TImage';
import TAudio from './TAudio';
import ReactMarkdown from 'react-markdown';
import '../styles/components/ChatBubble.scss';
import TMedia from './TMedia';
import PhoneCallChips from './PhoneCallChips';
import css from 'classnames';
import AudioNotAvailable from './AudioNotAvailable';
import { LeadContext } from '../context/LeadContext';
import { util } from '../core';
import { useRouter } from '../hooks';
import TimeAgo from 'react-timeago';

export interface ChatBubbleProps {
  id: number;
  adPreview?: any;
  typing?: boolean;
  message?: string;
  sender?: string;
  dateSent?: string;
  mediaSet?: any[];
  read?: boolean;
  ninja?: boolean;
  senderIs1099?: boolean;
  internal?: boolean;
  outgoing?: boolean;
  isEmail?: boolean;
  gif?: string;
  video?: string;
  videoThumb?: string;
  twilioStatus?: string;
  outboundEmailStatus?: string;
  yellow?: boolean;
  orange?: boolean;
  red?: boolean;
  call?: any;
  resetPause?: any;
  lines?: HTMLIonItemElement['lines'];
  translation?: string;
  videoMeetingStatus?: string;
  language?: any;
  hideAudio?: boolean;
  timeSince?: string;
  linkToConversation?: string;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
  id,
  adPreview,
  typing,
  read,
  sender,
  dateSent,
  message,
  outgoing,
  internal,
  ninja,
  senderIs1099,
  gif,
  mediaSet,
  videoThumb,
  twilioStatus,
  outboundEmailStatus,
  yellow,
  call,
  resetPause,
  videoMeetingStatus,
  language,
  translation,
  timeSince,
  linkToConversation,
  orange = false,
  red = false,
  hideAudio = false,
  isEmail = false,
  lines = 'none',
}) => {
  const router = useRouter();
  const { resetPauseInLead, dispatch } = useContext(LeadContext);
  if (videoMeetingStatus && message && !videoThumb) {
    message = videoMeetingStatus === 'completed' ? ' ' : message;
  }
  if (call?.summary) {
    message = `${message}:\n\n ${call.summary.replace(/\[(\d+)\]/g, "Score: $1")}`;
  }
  if (call?.user) {
    sender = `${call?.user?.first_name} ${call?.user?.last_name}`;
  }
  let ninjaLabel = ninja ? '(Ninja)' : '';
  if (senderIs1099) {
    ninjaLabel = '(1099)';
  }
  return (
    <IonItem
      className={css('chat-bubble', {
        call,
        incoming: !outgoing,
        outgoing,
        yellow,
        orange,
        red,
        'ad-preview': adPreview,
      })}
      lines={lines}
    >
      <IonLabel className='chat-label ion-text-wrap'>
        <div className={css('bubble', { typing })}>
          {mediaSet &&
            mediaSet.map((it) => (
              <TMedia
                key={it.id}
                src={it.media}
                alt={it.media}
                unsafe={it.unsafe}
                poster={videoThumb}
                onClick={(e: any) =>
                  dispatch({
                    type: 'set',
                    value: {
                      mediaPreview: {
                        height: e.target.naturalHeight,
                        width: e.target.naturalWidth,
                        src: it.media,
                        contentType: it.content_type,
                      },
                    },
                  })
                }
                contentType={it.content_type}
              />
            ))}
          {gif && (
            <TImage
              onClick={(e: any) =>
                dispatch({
                  type: 'set',
                  value: {
                    mediaPreview: {
                      height: e.target.naturalHeight,
                      width: e.target.naturalWidth,
                      src: gif,
                      contentType: 'image/gif',
                    },
                  },
                })
              }
              src={gif}
            />
          )}
          {adPreview && <div dangerouslySetInnerHTML={{ __html: adPreview }} />}
          {message && (
            <div
              onMouseUp={(e: any) => {
                if (
                  message &&
                  ['A', 'IMG', 'VIDEO'].indexOf(e.target.tagName) === -1
                ) {
                  util.copyToClipboard(message);
                }
              }}
            >
              {videoMeetingStatus && !videoThumb && (
                <IonImg
                  style={{ maxWidth: 270 }}
                  src={
                    videoMeetingStatus === 'completed'
                      ? `/assets/video-meeting-recording-processing.png`
                      : `/assets/video-meeting-in-progress.png`
                  }
                  title={`Video Meeting ${videoMeetingStatus}`}
                  alt={`Video Meeting ${videoMeetingStatus}`}
                />
              )}
              <ReactMarkdown
                linkTarget='_blank'
                className='message-text'
                skipHtml={true}
                source={message}
              />
            </div>
          )}
          {call && call.call_status === 'in-progress' && (
            <div style={{ textAlign: 'center', fontSize: 60 }}>
              <IonIcon src={bars} />
            </div>
          )}
          {translation && (
            <div className='translation'>
              <div className='translation-language'>
                Auto translated from {language?.name} to English
              </div>
              <div>{translation}</div>
            </div>
          )}
          {call && (
            <div className='message-text' style={{ fontSize: 10 }}>
              {call.from_number}, {call.from_city ? call.from_city + ', ' : ''}
              {call.from_state}
            </div>
          )}
          {call &&
            !hideAudio &&
            (call.audio_file ? (
              <TAudio
                src={call.audio_file}
                shareSrc={call.twilio_url}
                noSpeed
                noTime
                showLength
                background
                allowShare
              />
            ) : (
              <AudioNotAvailable background />
            ))}
          {resetPause && (
            <IonButton
              className='reset-pause'
              fill='clear'
              onClick={() => resetPauseInLead(resetPause, id)}
            >
              <IonIcon slot='start' icon={refresh} />
              Reset Pause
            </IonButton>
          )}
          {!outgoing && !!mediaSet?.length && (
            <IonButton
              className='send-to-document-center'
              fill='clear'
              onClick={(e: any) =>
                dispatch({
                  type: 'set',
                  value: {
                    showDocumentModal: id,
                  },
                })
              }
            >
              <IonIcon slot='start' icon={documentIcon} />
              Doc Center
            </IonButton>
          )}
          {isEmail && (
            <div className='chat-internal-only'>
              <IonIcon icon={mail} />
              E-mail Message
            </div>
          )}
          {internal && (
            <div className='chat-internal-only'>
              <IonIcon icon={helpBuoy} />
              Internal Message
            </div>
          )}
        </div>
        {!!call?.category && (
          <PhoneCallChips call={call} />
        )}
        {sender && (
          <div className='chat-details'>
            {typing && <IonSpinner name='dots' />}
            {read && <IonIcon icon={checkmarkCircle} />}
            {sender} {ninjaLabel}
            {twilioStatus && <strong>&nbsp;{twilioStatus}&nbsp;</strong>}
            {outboundEmailStatus && (
              <strong>&nbsp;{outboundEmailStatus}&nbsp;</strong>
            )}
            {dateSent ? ' at ' : ''} {dateSent}
            &nbsp;
            {!!linkToConversation && (
              <IonIcon
                onClick={() => router.push(linkToConversation, {})}
                className='pointer'
                icon={arrowRedo}
              />
            )}
            {timeSince && (
              <>
                &nbsp;
                <TimeAgo date={timeSince} live={true} />
              </>
            )}
          </div>
        )}
      </IonLabel>
    </IonItem>
  );
};

export default ChatBubble;
