import React, { useContext, useEffect } from 'react';
import { IonCard, IonList, IonItem, IonIcon, IonLabel } from '@ionic/react';
import { checkmarkCircle, ellipse } from 'ionicons/icons';
import TButtonItem from './TButtonItem';
import {
  PersonalizedLeadContext,
  PersonalizedLeadState
} from '../context/PersonalizedLeadContext';
import { util, actionSheet, loadingIndicator, appNotification } from '../core';
import { appointmentsService } from '../services';
import creditApplications from '../services/credit-applications';
import { useParams } from 'react-router';

export type ChecklistItem = {
  step: number;
  text: string;
  action?: (lead: any, state: PersonalizedLeadState, dispatch: any) => any;
  isComplete: (lead: any, state: PersonalizedLeadState) => boolean;
};

const hasActiveAppointment = (lead: any) =>
  !!appointmentsService.findActive(lead.appointments);

export const checkListItems: ChecklistItem[] = [
  {
    step: 1,
    text: 'Find Your Vehicle',
    action: () => {
      const el = document.getElementById(
        'inventory-search-input'
      ) as HTMLIonSearchbarElement;
      el?.setFocus?.();
    },
    isComplete(lead: any) {
      return lead?.inventory_likes?.length > 0;
    }
  },
  {
    step: 2,
    text: 'Trades',
    action: (lead: any, state: PersonalizedLeadState, dispatch: any) => {
      actionSheet.show([
        {
          text: 'Get Estimate',
          handler() {
            dispatch({
              showAddTradeModal: true
            });
          }
        },
        {
          text: 'No Trade',
          handler() {
            window.localStorage.setItem('skippedTrade', 'true');
            dispatch({
              skippedTrade: true
            });
          }
        },
        {
          text: 'Cancel',
          role: 'Cancel'
        }
      ]);
    },
    isComplete(lead: any, state: PersonalizedLeadState) {
      return lead?.tradeins?.length > 0 || state.skippedTrade;
    }
  },
  {
    step: 3,
    text: 'Credit',
    action: (lead: any, state: PersonalizedLeadState, dispatch: any) => {
      actionSheet.show([
        {
          text: 'Credit Application',
          handler() {
            (async () => {
              try {
                await loadingIndicator.create();
                await creditApplications.sendLinkAndCode(state.token);
                appNotification.alert({
                  message: `We've sent a link and a pin code to your phone.`,
                  header: 'Application Sent',
                  buttons: [
                    {
                      text: 'Dismiss',
                      role: 'cancel'
                    }
                  ]
                });
              } catch (e) {
                appNotification.alert({
                  message: `There was a problem generating a credit application for you.`,
                  header: 'Error',
                  buttons: [
                    {
                      text: 'Dismiss',
                      role: 'cancel'
                    },
                    {
                      text: 'Contact Us',
                      handler(lead: any) {
                        util.sms(
                          lead.twilio_number,
                          `There was an error getting my credit application.`
                        );
                      }
                    }
                  ]
                });
              } finally {
                loadingIndicator.dismiss();
              }
            })();
          }
        },
        {
          text: 'No Financing',
          handler() {
            window.localStorage.setItem('skippedFinancing', 'true');
            dispatch({
              skippedFinancing: true
            });
          }
        },
        {
          text: 'Cancel',
          role: 'Cancel'
        }
      ]);
    },
    isComplete(lead: any, state: PersonalizedLeadState) {
      return lead?.credit_app_complete || state.skippedFinancing;
    }
  },
  {
    step: 4,
    text: 'Appointment',
    action: (lead: any) =>
      util.sms(lead?.twilio_number, `I'd like to setup an appointment.`),
    isComplete(lead: any) {
      return hasActiveAppointment(lead);
    }
  },
  {
    step: 5,
    text: 'Your New Vehicle',
    isComplete(lead: any) {
      return !!lead?.status?.match(/^Sold-/);
    }
  }
];

const PersonalizedLeadChecklist: React.FC = () => {
  const { showTrade } = useParams() as any;
  const { state, dispatch } = useContext(PersonalizedLeadContext);
  const { lead } = state;
  const nextStep = checkListItems.find(it => !it.isComplete(lead, state));
  // Show the trade modal if the URL has the param in it
  useEffect(() => {
    if (!!showTrade) {
      dispatch({
        showAddTradeModal: true
      });
    }
  }, [showTrade, dispatch]);

  return (
    <IonCard>
      <IonList lines="none">
        {checkListItems.map(it => {
          const isComplete = it.isComplete(lead, state);
          return (
            <IonItem key={it.step}>
              <IonIcon
                icon={isComplete ? checkmarkCircle : ellipse}
                color={isComplete ? 'success' : undefined}
                slot="start"
              />
              <IonLabel>{it.text}</IonLabel>
            </IonItem>
          );
        })}
      </IonList>
      {nextStep?.action && (
        <TButtonItem
          lines="none"
          color="secondary"
          onClick={() => nextStep?.action?.(lead, state, dispatch)}
        >
          Next Step: {nextStep.text}
        </TButtonItem>
      )}
    </IonCard>
  );
};

export default PersonalizedLeadChecklist;
