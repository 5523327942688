import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';

class ClientGroupsService extends CachedService {
  constructor() {
    super(
      'client-groups',
      'clientGroups',
      [],
      CacheStrategy.TIMEOUT,
      CacheTTL.FIVEMINUTES
    );
  }

  async request(clientId: any) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/notification-groups/`
    });

    return res.data;
  }
}

export default new ClientGroupsService();
