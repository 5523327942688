import React, { useContext, useState, useEffect, useCallback } from 'react';
import { LeadContext } from '../context/LeadContext';
import { IonItem, IonText, IonIcon, IonLabel, IonAlert } from '@ionic/react';
import { leadDocumentService } from '../services';
import { DocumentModal } from './modals';
import TExpandableItemGroup from './TExpandableItemGroup';
import moment from 'moment';
import {
  document as documentIcon,
  closeCircle
} from 'ionicons/icons';

const LeadContactDetails: React.FC = () => {
  const { state, dispatch } = useContext(LeadContext);
  const { lead, loading } = state;
  const [leadDocuments, setLeadDocuments] = useState<any>();
  const [showAlert, setShowAlert] = useState<any>();
  const fetchLeadDocuments = useCallback(async () => {
    if (!!lead) {
      const data: any = await leadDocumentService.list(lead.client, lead.id);
      return setLeadDocuments(data);
    }
  }, [lead]);

  useEffect(() => {
    fetchLeadDocuments();
  }, [fetchLeadDocuments]);

  if (loading || !lead) {
    return null;
  }

  const badgeValue = leadDocuments?.length ? leadDocuments.length : undefined;

  return (
    <TExpandableItemGroup title="Document Center" badge={badgeValue}>
      {!leadDocuments?.length && (
        <IonItem><span className="detail">No Documents</span></IonItem>
      )}
      {leadDocuments?.map((it: any) => (
        <IonItem
          key={it.id}
          className="pointer"
          href={it.signed_url}
          target="_blank"
        >
          <IonIcon icon={documentIcon} slot="start"  />
          <IonLabel className="ion-multiline">
            <IonText>
              {it?.name}
              <span className="detail">
                {it?.added_by}
              </span>
              <span className="detail">{it?.document_type} {moment(it.created_at).fromNow()}</span>
            </IonText>
          </IonLabel>
          <IonIcon
            icon={closeCircle}
            onClick={(e: any) => {
              e.preventDefault();
              setShowAlert(it.id);
            }}
            slot="end"
          />
        </IonItem>
      ))}
      {!!state.showDocumentModal && (
        <DocumentModal
          isOpen={true}
          messageId={state.showDocumentModal}
          lead={lead}
          onDidDismiss={() => {
            dispatch({
              type: 'set',
              value: { showDocumentModal: 0 }
            });
            fetchLeadDocuments();
          }}
        />
      )}
      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Confirm!'}
        message={`Are you sure you want to remove this item from the document center?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: 'Remove',
            handler: async () => {
              await leadDocumentService.delete(lead.client, lead.id, showAlert);
              fetchLeadDocuments();
            }
          }
        ]}
      />
    </TExpandableItemGroup>
  );
};

export default LeadContactDetails;
