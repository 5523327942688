import { http } from '../core';

class URLShortenerService {
  async shorten(url: string) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: '/link-shortener/',
        data: {
          url
        }
      });

      return data;
    } catch (e) {
      console.error(e);
      return url;
    }
  }
}

export default new URLShortenerService();
