import React from 'react';
import { TItemProps, default as TItem } from './TItem';
import { IonDatetime, IonIcon } from '@ionic/react';
import css from 'classnames';
import '../styles/components/TDateTimeItem.scss';
import { time } from 'ionicons/icons';

export interface TDateTimeItemProps extends TItemProps {
  value?: HTMLIonDatetimeElement['value'];
  displayFormat?: HTMLIonDatetimeElement['displayFormat'];
  disabled?: boolean;
  fromDisabled?: boolean;
  toDisabled?: boolean;
  pickerOptions?: HTMLIonDatetimeElement['pickerOptions'];
  placeholder?: string;
  min?: HTMLIonDatetimeElement['min'];
  max?: HTMLIonDatetimeElement['max'];
  fromMin?: HTMLIonDatetimeElement['min'];
  fromMax?: HTMLIonDatetimeElement['max'];
  toMin?: HTMLIonDatetimeElement['min'];
  toMax?: HTMLIonDatetimeElement['max'];
  range?: boolean;
  fromPickerOptions?: HTMLIonDatetimeElement['pickerOptions'];
  toPickerOptions?: HTMLIonDatetimeElement['pickerOptions'];
  onIonChange?: (e: CustomEvent) => any;
  onFromIonChange?: (e: CustomEvent) => any;
  onToIonChange?: (e: CustomEvent) => any;
  fromValue?: HTMLIonDatetimeElement['value'];
  toValue?: HTMLIonDatetimeElement['value'];
  noIcon?: boolean;
}

const TDateTimeItem: React.FC<TDateTimeItemProps> = ({
  value,
  children,
  displayFormat = 'MMM DD, YYYY',
  pickerOptions,
  disabled,
  min,
  max,
  placeholder = '',
  range,
  onIonChange,
  fromPickerOptions,
  toPickerOptions,
  onFromIonChange,
  onToIonChange,
  fromValue,
  toValue,
  toMin,
  toMax,
  toDisabled,
  fromMin,
  fromMax,
  fromDisabled,
  className,
  ...rest
}) => {
  if (range) {
    const from = {
      displayFormat,
      min: fromMin,
      max: fromMax,
      disabled: fromDisabled,
      pickerOptions: fromPickerOptions,
      value: fromValue,
      onIonChange: onFromIonChange
    };

    const to = {
      displayFormat,
      min: toMin,
      max: toMax,
      disabled: toDisabled,
      pickerOptions: toPickerOptions,
      value: toValue,
      onIonChange: onToIonChange
    };

    return (
      <TItem className={css('t-datetime-item', { range }, className)} {...rest}>
        {!rest.icon && (
          <IonIcon className="ion-hide-md-down" slot="start" icon={time} />
        )}
        <IonDatetime {...from} placeholder="Since" slot="end" />
        <span slot="end" className="ion-hide-md-down">
          -
        </span>
        <IonDatetime {...to} placeholder="Until" slot="end" />
      </TItem>
    );
  }

  const dt = {
    displayFormat,
    min,
    max,
    disabled,
    pickerOptions,
    value,
    onIonChange,
    placeholder
  };

  return (
    <TItem {...rest}>
      <IonDatetime className="t-datetime" {...dt} slot="end" />
    </TItem>
  );
};

export default TDateTimeItem;
