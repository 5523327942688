import authorization from './authorization';
import network from './network';
import data from './data';
import appConfig from '../config.json';
import {
  pushNotifications,
  inboundLeadsService,
  userService
} from '../services';
import native from './native';
import analytics from './analytics';
import updates from './updates';
import sentry from './sentry';
import firebase from 'firebase/app';
import 'firebase/auth';
import environment from './environment';

class Initialization {
  private async firebaseLogin(user: any, retried?: boolean) {
    try {
      await firebase.auth().signInWithCustomToken(user.firebase_token);
    } catch (e) {
      sentry.addBreadcrumb({
        message: `Has Firebase Token: ${!!user.firebase_token}`
      });
      if (!retried) {
        sentry.addBreadcrumb({
          message: 'Firebase Login Retry'
        });
        setTimeout(async () => {
          const updated = await userService.request();
          this.firebaseLogin(updated, true);
        }, 5000);
      } else {
        sentry.capture(e);
        console.error(e);
      }
    }
  }

  private channelForUser(user: any) {
    if (appConfig.tecobi.developers.indexOf(user.id) > -1) {
      return 'Development';
    } else if (user.is_staff) {
      return 'Master';
    }

    return 'Production';
  }

  async onClientChanged(clientId: any) {
    return await data.loadClientInfo(clientId);
  }

  async onAuthorized() {
    pushNotifications.register();
    pushNotifications.removeAllDeliveredNotifications();

    const coreData = await data.load();

    let d = Object.assign(coreData, {
      isOnline: network.hasNetworkConnection()
    });

    await sentry.configure(d.user, await authorization.getSelectedClientId());
    await this.firebaseLogin(d.user);
    updates.initialize(d.user);

    if (userService.isStaff(d.user)) {
      await inboundLeadsService.initialize(d.user);
    }

    await updates.updateChannel(this.channelForUser(d.user));
    await analytics.initialize(d.user);

    return d;
  }

  async onAppLaunched() {
    const auth = await authorization.load();
    await network.monitor();

    if (auth.authorized) {
      Object.assign(auth, await this.onAuthorized());
    } else {
      updates.unregisterServiceWorker(false);
      await updates.updateChannel('Production');
    }

    native.initialize();
    if (environment.isProduction) {
      window.onerror = () => window.location.reload(true);
    }

    document.body.classList.add('t-init');
    pushNotifications.checkPendingAction();

    return auth;
  }
}

export default new Initialization();
