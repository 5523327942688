import React, { useEffect, useCallback, useContext } from 'react';
import {
  TPage,
  TrainingQuestion,
  TrainingVideo,
  TrainingResults
} from '../components';
import { trainingService } from '../services';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  useIonViewDidLeave
} from '@ionic/react';
import { useParams } from 'react-router';
import '../styles/pages/Training.scss';
import { ellipseOutline, checkmarkCircle } from 'ionicons/icons';
import { TrainingContext } from '../context/TrainingContext';
import { AppContext } from '../context/AppContext';

const TrainingQuizPage: React.FC = () => {
  const { slug, quizSlug } = useParams() as any;
  const { state, dispatch } = useContext(TrainingContext);
  const { state: appState } = useContext(AppContext);

  const loadData = useCallback(async () => {
    try {
      dispatch({
        loading: true,
        quiz: null,
        module: slug,
        answers: {},
        session: null
      });
      const [q, c] = await Promise.all([
        trainingService.getQuiz(slug, quizSlug),
        trainingService.getQuizzes(slug)
      ]);

      dispatch({
        category: c,
        quiz: q,
        loading: false
      });
    } catch (e) {}
  }, [slug, dispatch, quizSlug]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useIonViewDidLeave(() =>
    dispatch({
      loading: true,
      quiz: null,
      session: null,
      category: {},
      module: null
    })
  );

  let step: any;

  if (state.session?.is_complete) {
    step = <TrainingResults />;
  } else if (state.question) {
    step = <TrainingQuestion />;
  } else {
    step = <TrainingVideo />;
  }

  return (
    <TPage loading={state.loading}>
      <IonGrid className="training-quiz">
        <IonRow>
          <IonCol size="12" className="lesson-name">
            <strong className="lesson-category">{state.category?.name}</strong>
            <div className="lesson">
              <strong>Lesson {state.quiz?.order}: </strong>
              {state.quiz?.name}
            </div>
          </IonCol>
        </IonRow>
        {appState.authorized && (
        <IonRow>
          <IonCol className="training-progress">
            <div className="training-progress-item">
              <IonIcon
                icon={
                  state.question || state.session?.is_complete
                    ? checkmarkCircle
                    : ellipseOutline
                }
                slot="start"
              />
              <span>Video</span>
            </div>
            {state.quiz?.quiz_questions?.map((q: any, i: number) => (
              <div key={i} className="training-progress-item">
                <IonIcon
                  icon={
                    i < state.session?.answer_count
                      ? checkmarkCircle
                      : ellipseOutline
                  }
                  slot="start"
                />
              </div>
            ))}
          </IonCol>
        </IonRow>
        )}
        <IonRow>
          <IonCol>{step}</IonCol>
        </IonRow>
      </IonGrid>
    </TPage>
  );
};

export default TrainingQuizPage;
