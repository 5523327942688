import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';

class JobTitlesService extends CachedService {
  constructor() {
    super('jobTitles', 'jobTitles', [], CacheStrategy.TIMEOUT, CacheTTL.FIFTEENMINUTES);
  }

  async request() {
    let { data } = await http.authorizedRequest({
      method: 'GET',
      url: '/job-titles/',
      type: 'job-titles',
      exclusive: true
    });

    return data;
  }
}

export default new JobTitlesService();
