import { Plugins, HapticsImpactStyle } from '@capacitor/core';
import { isPlatform } from '@ionic/core';

const { Haptics } = Plugins;

class HapticsUtil {
  impact(style: HapticsImpactStyle) {
    if (isPlatform('capacitor')) {
      Haptics.impact({
        style
      });
    }
  }
  lightImpact() {
    this.impact(HapticsImpactStyle.Light);
  }

  mediumImpact() {
    this.impact(HapticsImpactStyle.Medium);
  }

  heavyImpact() {
    this.impact(HapticsImpactStyle.Heavy);
  }
}

export default new HapticsUtil();
