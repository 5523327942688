import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { IonItemGroup, IonItemDivider, IonIcon, IonBadge, IonSpinner } from '@ionic/react';
import css from 'classnames';
import '../styles/components/TExpandableItemGroup.scss';
import { arrowUp, arrowDown } from 'ionicons/icons';
import { haptics } from '../core';

export interface TExpandableItemGroup {
  sticky?: boolean;
  title?: string | JSX.Element;
  children?: JSX.Element | JSX.Element[] | any[] | string;
  defaultExpanded?: boolean;
  badge?: number | string;
  badgeColor?: string;
  renderWhenExpanded?: boolean;
  loading?: boolean;
}

const TExpandableItemGroup: React.FC<TExpandableItemGroup> = (
  {
    sticky = true,
    title,
    children,
    defaultExpanded,
    badge,
    badgeColor,
    renderWhenExpanded,
    loading = false
  },
  ref
) => {
  const containerRef = useRef<any>();
  const [expanded, setExpanded] = useState(defaultExpanded);
  useEffect(() => setExpanded(defaultExpanded), [defaultExpanded]);
  useImperativeHandle(ref, () => ({
    isExpanded() {
      return expanded;
    }
  }));
  return (
    <IonItemGroup className={css('t-expandable-item-group', { expanded })}>
      <IonItemDivider
        className="expandable-title"
        onClick={() => {
          if (loading) {
            return false;
          }
          haptics.lightImpact();
          setExpanded(!expanded);
        }}
        sticky={sticky}
        mode="md"
      >
        {title}
        {!!badge && (
          <IonBadge mode="ios" color={badgeColor || 'success'} slot="end">
            {badge}
          </IonBadge>
        )}
        {loading && (
          <IonSpinner name="dots" slot="end" />
        )}
        <IonIcon slot="end" icon={expanded ? arrowUp : arrowDown} />
      </IonItemDivider>
      <div ref={containerRef} className="expandable-group-items">
        {(!renderWhenExpanded || expanded) && children}
      </div>
    </IonItemGroup>
  );
};

export default forwardRef(TExpandableItemGroup);
