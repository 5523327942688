import React, { useContext, useCallback } from 'react';
import { ItemReorderEventDetail } from '@ionic/core';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonReorder,
  IonReorderGroup,
  IonList,
  IonItemDivider,
  IonCardSubtitle,
  IonItem,
  IonLabel,
  IonCol,
  IonText,
  IonSkeletonText
} from '@ionic/react';
import moment from 'moment';
import { appointmentsService, leadsService } from '../services';
import { AppContext } from '../context/AppContext';
import { util, loadingIndicator, actionSheet } from '../core';
import { useDataLoader } from '../hooks';

const getPreviousSibling = (elem: any, selector: string) => {
  if (!elem) {
    return false;
  }
	// Get the next sibling element
	let sibling = elem.previousElementSibling;

	// If there's no selector, return the first sibling
	if (!selector) return sibling;

	// If the sibling matches our selector, use it
	// If not, jump to the next sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.previousElementSibling;
	}
  return sibling;
};

function getDomElement(elementName: string, index: number) {
  return document.querySelectorAll(elementName)[index];
}

const UpcomingAppointmentsCard: React.FC = () => {
  const { state } = useContext(AppContext);

  const markSet = async (appointment: any) => {
    try {
      await loadingIndicator.create();
      const { appt } = await leadsService.appointmentSet(
        appointment.client,
        appointment.get_lead_info.id,
        appointment
      );
      onAppointmentChanged?.(appt);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const markShowed = async (appointment: any) => {
    try {
      await loadingIndicator.create();
      const { appt } = await leadsService.appointmentShowed(
        appointment.client,
        appointment.get_lead_info.id,
        appointment
      );
      onAppointmentChanged?.(appt);
    } finally {
      loadingIndicator.dismiss();

    }
  };

  const markMissed = async (appointment: any) => {
    try {
      await loadingIndicator.create();
      const { appt } = await leadsService.appointmentMissed(
        appointment.client,
        appointment.get_lead_info.id,
        appointment
      );
      onAppointmentChanged?.(appt);
    } finally {
      loadingIndicator.dismiss();

    }
  };

  const onAppointmentChanged = (appt: any) => refresher();

  const markShowedLost = async (appointment: any) => {
    try {
      await loadingIndicator.create();
      const { appt } = await leadsService.appointmentShowedLost(
        appointment.client,
        appointment.get_lead_info.id,
        appointment
      );
      onAppointmentChanged?.(appt);
    } finally {
      loadingIndicator.dismiss();

    }
  };

  const markCancelled = async (appointment: any) => {
    try {
      await loadingIndicator.create();
      const { appt } = await leadsService.cancelAppointment(
        appointment.client,
        appointment.get_lead_info.id,
        appointment
      );
      onAppointmentChanged?.(appt);
    } finally {
      loadingIndicator.dismiss();

    }
  };

  const markSold = (appointment: any) => {
    const doMarkSold = (status: number, str: string) => () => {
      (async () => {
        try {
          await loadingIndicator.create();
          const { appt } = await leadsService.appointmentSold(
            appointment.client,
            appointment.get_lead_info.id,
            appointment,
            status
          );
          onAppointmentChanged?.(appt);
        } finally {
          loadingIndicator.dismiss();

        }
      })();
      return true;
    };

    actionSheet.show([
      {
        text: 'Sold New',
        handler: doMarkSold(13, 'New')
      },
      {
        text: 'Sold Used',
        handler: doMarkSold(14, 'Used')
      },
      {
        text: 'Sold Unknown',
        handler: doMarkSold(15, 'Unknown')
      },
      {
        text: 'Dismiss',
        role: 'cancel'
      }
    ]);
  };

  function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
    const statuses: any = ['set', 'missed', 'cancelled', 'showed', 'sold', 'showed_lost'];
    const droppedEl = getDomElement('ion-reorder-group > *', event.detail.to) as HTMLElement;
    const divider = getPreviousSibling(droppedEl, 'ion-item-divider');
    if (divider === false) {
      refresher();
      return;
    }

    const classArray = !!divider ? Array.from(divider.classList) : [];
    const status = classArray.find((cl: any) => statuses.includes(cl));
    const appointment = !!droppedEl?.dataset?.json ? JSON.parse(droppedEl.dataset.json) : null;

    switch(status) {
      case 'set':
        markSet(appointment);
        break;
      case 'sold':
        markSold(appointment);
        break;
      case 'showed':
        markShowed(appointment);
        break;
      case 'showed_lost':
        markShowedLost(appointment);
        break;
      case 'missed':
        markMissed(appointment);
        break;
      case 'cancelled':
        markCancelled(appointment);
        break;
      case undefined:
        refresher();
        break;
      }
  }
  const loadData = useCallback(async () => {
    return await appointmentsService.list({
      clientId: state.selectedClientId,
      from: moment().startOf('day'),
      to: moment().endOf('day')
    });
  }, [state.selectedClientId]);

  const { count, loading, refresher, data = [] } = useDataLoader(loadData, true);
  const renderApptItem = (it: any) => (
    <IonItem
      key={it.id}
      data-json={JSON.stringify(it)}
      routerLink={`/text-messages/conversation/${it.client}/${it.get_lead_info.id}/`}
    >
      <IonText slot="start" className="detail">
        {moment(it.appointment_time).format('hh:mma')}
      </IonText>
      <IonLabel class="ion-text-wrap">
        <h2>{it.get_lead_info.name}</h2>
        {it?.get_lead_info?.assigned_to && <span className="detail">{it.get_lead_info.assigned_to}</span>}
      </IonLabel>
      <IonText slot="end" className="detail ion-text-capitalize">
        {it.status.replace('_', '/')}
      </IonText>
      <IonReorder slot="end" />
    </IonItem>
  );
  return (
    <>
      <IonCol sizeSm="12" sizeXs="12" sizeMd="12" sizeLg="12" sizeXl="12">
      <IonCard>
        <IonCardHeader>
          {loading ? (
            <>
              <IonSkeletonText animated style={{ height: 25, width: '60%' }} />
              <IonSkeletonText animated style={{ width: '40%' }} />
            </>
          ) : (
            <>
              <IonCardTitle>
                Today's{' '} {count} {' '}
                {util.getPluralizedString('Appointment', count)}
              </IonCardTitle>
              <IonCardSubtitle>
                {moment().format('dddd, MMMM DD, YYYY')}
              </IonCardSubtitle>
            </>
          )}
        </IonCardHeader>
        <IonList>
          <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
            {loading && (
              Array.from(Array(3).keys()).map((it: any, i: number) => (
                  <IonItem key={i}>
                    <IonLabel>
                      <IonSkeletonText key={i} animated />
                    </IonLabel>
                  </IonItem>
                ))
            )}
            {!loading && (
              <IonItemDivider className="set" color="medium">Set</IonItemDivider>
            )}

            {!loading && util.sortByProp(data, 'appointment_time')?.filter((it: any) => it.status === 'set').map?.((it: any) => renderApptItem(it))}

            {!loading && (
              <IonItemDivider className="showed" color="secondary">In Store</IonItemDivider>
            )}

            {!loading && util.sortByProp(data, 'appointment_time')?.filter((it: any) => it.status === 'showed').map?.((it: any) => renderApptItem(it))}


            {!loading && (
              <IonItemDivider className="showed_lost" color="tertiary">Showed/Lost</IonItemDivider>
            )}

            {!loading && util.sortByProp(data, 'appointment_time')?.filter((it: any) => it.status === 'showed_lost').map?.((it: any) => renderApptItem(it))}

            {!loading && (
              <IonItemDivider className="sold" color="success">Sold</IonItemDivider>
            )}

            {!loading && util.sortByProp(data, 'appointment_time')?.filter((it: any) => it.status === 'sold').map?.((it: any) => renderApptItem(it))}

            {!loading && (
              <IonItemDivider className="missed" color="warning">Missed</IonItemDivider>
            )}

            {!loading && util.sortByProp(data, 'appointment_time')?.filter((it: any) => it.status === 'missed').map?.((it: any) => renderApptItem(it))}

            {!loading && (
              <IonItemDivider className="cancelled" color="danger">Canceled</IonItemDivider>
            )}

            {!loading && util.sortByProp(data, 'appointment_time')?.filter((it: any) => it.status === 'cancelled').map?.((it: any) => renderApptItem(it))}

          </IonReorderGroup>
          <IonItem
            detail={true}
            routerLink="/appointments/"
            routerDirection="root"
            lines="none"
          >
            <IonLabel>View All Appointments</IonLabel>
          </IonItem>
        </IonList>
      </IonCard>
    </IonCol>
  </>
  );
};

export default UpcomingAppointmentsCard;
