import React, { useContext } from 'react';
import { IonTitle, IonChip, IonLabel, IonText } from '@ionic/react';
import { util, haptics } from '../core';
import { LeadContext } from '../context/LeadContext';
import TAvatar from './TAvatar';

const ChatHeader: React.FC = () => {
  const { state, markHeaderTooltipSeen } = useContext(LeadContext);

  const showMenu = () => {
    haptics.lightImpact();
    util.showLeadSidebar();
  };

  return (
    <IonTitle className="ion-text-center" onClick={markHeaderTooltipSeen}>
      <IonChip
        id="chat-header-avatar"
        onClick={showMenu}
        style={{ '--background': 'transparent', opacity: state.lead ? 1 : 0 }}
      >
        <TAvatar
          style={{ fontSize: 12 }}
          email={state.lead?.email_address}
          alt={state.lead?.best_name}
        />
        <IonLabel>
          <IonText>{state.lead?.best_name}</IonText>
        </IonLabel>
      </IonChip>
    </IonTitle>
  );
};

export default ChatHeader;
