import React, { useContext, useEffect, useCallback, useReducer } from 'react';
import { TPage, TSegmentItem } from '../components';
import { AppContext } from '../context/AppContext';
import moment from 'moment';
import '../styles/Reports.scss';
import {
  funnel,
  briefcase,
  business,
  closeCircle,
  time,
  download as downloadIcon,
  paperPlane,
  people,
  calendar,
} from 'ionicons/icons';
import HapticButton from './HapticButton';
import ReportFilters, { ReportFiltersProps } from './modals/ReportFiltersModal';
import { IonChip, IonIcon, IonLabel, IonItem } from '@ionic/react';
import { util } from '../core';

export interface TReportsPageLoadProps {
  since: Date;
  until: Date;
  clientId: number;
  userId?: number;
  filters: any;
}

export interface TReportsPageProps {
  children?: (props: any) => JSX.Element;
  fab?: JSX.Element;
  filterProps?: Partial<ReportFiltersProps>;
  noFilters?: boolean;
  downloadData?: (data: any) => any[];
  updateDateRange?: (e: any) => any;
  defaultDateRange?: string;
  defaultSince?: string;
  defaultUntil?: string;
  onLoadData: (opts: TReportsPageLoadProps) => Promise<any>;
  segments?: Array<string>;
}

export interface ReportState {
  data: any;
  dateRange: string;
  since: string;
  until: string;
  classification?: string;
  showSelectedClientOnly?: boolean;
  leadProvider?: number;
  excludeSaleAppointments?: boolean;
  newLeadsOnly?: boolean;
  clientIds?: Array<number>;
  pipelineStatusIds?: Array<number>;
  loading: boolean;
  filtersVisible: boolean;
  user?: string;
  admins?: any;
  staff?: any;
  topNinja?: any;
  contractors?: any;
  isPhoneNinja?: any;
  ninjaTeams?: Array<any>;
}

const reducer = (state: ReportState, value: any) => {
  const s = { ...state };
  return Object.assign(s, value || {});
};

const TReportsPage: React.FC<TReportsPageProps> = ({
  onLoadData,
  filterProps,
  updateDateRange,
  defaultDateRange,
  defaultSince,
  defaultUntil,
  downloadData,
  noFilters,
  segments,
  fab,
  children
}) => {
  const { state: appState } = useContext(AppContext);

  const initialState = {
    data: {},
    loading: true,
    filtersVisible: false,
    dateRange: defaultDateRange || 'Month',
    classification: 'All',
    showSelectedClientOnly: !!filterProps?.showSelectedClientOnlyToggle,
    clientIds: appState.clients.slice(0, 500).filter((cli:any) => {
      return cli.id !== 278;
    }).map((it: any)=> it.id),
    pipelineStatusIds: [1, 2, 23, 21, 11, 12, 35],
    admins: false,
    staff: undefined,
    topNinja: true,
    contractors: undefined,
    isPhoneNinja: undefined,
    ninjaTeams: undefined,
    since: defaultSince || moment()
      .startOf('month')
      .toISOString(),
    until: defaultUntil || moment()
      .endOf('day')
      .toISOString()
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedClientId, user, clientProviders } = appState;
  const { classification, showSelectedClientOnly, dateRange, admins, staff, topNinja, contractors, isPhoneNinja, ninjaTeams, leadProvider, excludeSaleAppointments, newLeadsOnly, clientIds, pipelineStatusIds } = state;

  const download = useCallback(() => {
    util.downloadCSV(
      downloadData?.(state.data),
      `report-${moment().format('YYYY-MM-DD-HH-mm')}.csv`
    );
  }, [downloadData, state.data]);

  const loadData = useCallback(async () => {
    try {
      dispatch({ loading: true });
      const since = moment(state.since)
        .startOf('day')
        .toDate();
      const until = moment(state.until)
        .endOf('day')
        .toDate();

      const data = await onLoadData({
        since,
        until,
        clientId: selectedClientId ?? 0,
        userId: user?.id,
        filters: { classification, showSelectedClientOnly, admins, staff, topNinja, contractors, isPhoneNinja, ninjaTeams, leadProvider, excludeSaleAppointments, newLeadsOnly, clientIds, pipelineStatusIds }
      });
      dispatch({
        data,
        loading: false,
        error: false
      });
    } catch (e) {
      if (!e.cancelled) {
        dispatch({
          loading: false,
          error: true
        });
      }
    }
  }, [
    dispatch,
    onLoadData,
    selectedClientId,
    classification,
    showSelectedClientOnly,
    leadProvider,
    excludeSaleAppointments,
    newLeadsOnly,
    clientIds,
    pipelineStatusIds,
    user,
    state.since,
    state.until,
    admins,
    staff,
    topNinja,
    contractors,
    isPhoneNinja,
    ninjaTeams
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const updateRange = !!updateDateRange ? updateDateRange : (e: any) => {
    const val = e.detail.value === 'Today' ? 'day' : e.detail.value;
    let untilMoment = moment();
    if (e.detail.value) {
      let sinceMoment = moment().startOf(val);
      if (val === 'Yesterday') {
        sinceMoment = sinceMoment.add(-1, 'days');
      }
      if (val === 'Last Month') {
        sinceMoment = moment().add(-1, 'month').startOf('month');
        untilMoment = moment().add(-1, 'month').endOf('month');
      }
      return {
        dateRange: e.detail.value,
        since: sinceMoment.toISOString(),
        until: untilMoment.toISOString()
      };
    }
  };

  const dispatchDateRange = (e: any) => dispatch(updateRange(e));

  const showFilters = () => dispatch({ filtersVisible: true });

  const filters = [] as any;

  if (classification !== 'All') {
    filters.push({
      icon: briefcase,
      value: classification,
      remove() {
        dispatch({ classification: 'All' });
      }
    });
  }

  if (showSelectedClientOnly && appState?.clients?.length > 1) {
    filters.push({
      icon: business,
      value: 'Selected Client Only',
      remove() {
        dispatch({ showSelectedClientOnly: false });
      }
    });
  }

  if (!dateRange) {
    filters.push(
      {
        icon: time,
        value: `Since: ${moment(state.since).format('MM/DD/YY')}`
      },
      {
        icon: time,
        value: `Until: ${moment(state.until).format('MM/DD/YY')}`
      }
    );
  }
  if (newLeadsOnly) {
    filters.push({
      icon: people,
      value:
        'New Leads',
      remove() {
        dispatch({ newLeadsOnly: undefined });
      }
    });
  }
  if (leadProvider) {
    filters.push({
      icon: paperPlane,
      value:
        clientProviders?.find(it => it.id === leadProvider)?.name ??
        leadProvider,
      remove() {
        dispatch({ leadProvider: undefined });
      }
    });
  }
  if (excludeSaleAppointments) {
    filters.push({
      icon: calendar,
      value:
        'Exclude Sold-only Appointments',
      remove() {
        dispatch({ excludeSaleAppointments: undefined });
      }
    });
  }

  return (
    <TPage
      loading={state.loading}
      loadingText="Generating Report..."
      headerTool={
        <>
          {!state.loading && downloadData && (
            <HapticButton
              icon={downloadIcon}
              onClick={download}
              className="btn-sm-padding"
              slot="end"
            />
          )}
          {!noFilters && (
            <HapticButton
              icon={funnel}
              onClick={showFilters}
              className="btn-sm-padding"
              slot="end"
            />
          )}
        </>
      }
      preContent={
        !noFilters ? (
          <>
            <TSegmentItem
              value={dateRange}
              onIonChange={dispatchDateRange}
              segments={segments ?? ['Today', 'Week', 'Month', 'Last Month', 'Year']}
            />
            {filters.length ? (
              <IonItem lines="full">
                {filters.map((it: any, i: number) => (
                  <IonChip key={i} color="primary">
                    <IonIcon icon={it.icon} />
                    <IonLabel>{it.value}</IonLabel>
                    {it.remove && (
                      <IonIcon icon={closeCircle} onClick={it.remove} />
                    )}
                  </IonChip>
                ))}
              </IonItem>
            ) : null}
          </>
        ) : (
          undefined
        )
      }
    >
      {!state.loading && state.error && (
        <div className="error-message">
          <h2>There was an error generating the report</h2>
          <HapticButton color="secondary" fill="solid" onClick={loadData}>
            Retry
          </HapticButton>
        </div>
      )}
      {!state.loading &&
        !state.error &&
        children &&
        children({ state, dispatch })}
      {state.filtersVisible && (
        <ReportFilters
          state={state}
          dispatch={dispatch}
          onDidDismiss={() => dispatch({ filtersVisible: false })}
          {...filterProps}
        />
      )}
      {fab}
    </TPage>
  );
};

export default TReportsPage;
