import React, { useContext, useState, useCallback, useEffect } from 'react';
import TExpandableItemGroup from './TExpandableItemGroup';
import TSelectItem from './TSelectItem';
import { util } from '../core';
import { LeadContext } from '../context/LeadContext';
import { AppContext } from '../context/AppContext';
import { leadsService, userWatchersService } from '../services';
import TToggleItem from './TToggleItem';
import DoRoundRobinButton from './DoRoundRobinButton';
import OptinButton from './OptinButton';

const LeadActions: React.FC = () => {
  const appContext = useContext(AppContext);
  const { state, updateAndSaveLead, dispatch } = useContext(LeadContext);
  const { lead, clientUsers } = state;
  const [changes, setChanges] = useState(lead);
  const [watcher, setWatcher] = useState<any>();
  const { user } = appContext.state;
  const classificationOptions = [
    { value: 'Sales', text: 'Sales' },
    { value: 'Service', text: 'Service' },
    { value: 'Employment', text: 'Employment' }
  ];
  if (user?.is_staff) {
    classificationOptions.splice( 2, 0, { value: 'Service Canceled', text: 'Service Canceled' } );
  }
  useEffect(() => setChanges(lead), [lead]);
  useEffect(() => setWatcher(lead?.watchers?.find((it: any) => it.user_id === user.id)), [lead, user]);

  const canSeeAdvanceAutomationFeatures = lead?.client_advanced_automation_admins_only ? (user.is_client_admin || user.is_staff) : true;
  const canSeeRoundRobinButton = lead?.client_delivery_type === 'round_robin' && (user.is_client_admin || user.is_staff);
  const canSeeOptinButton = !!(lead && lead.sms_opt_out && lead.sms_opt_out > 8 && (user.is_client_admin || user.is_staff));
  const canSeeWatcherToggle = !user.is_text_ninja && (user.is_client_admin || user.is_admin);

  const updateWatcher = useCallback(async (action: string, notifications: boolean) => {
    if (action === 'delete') {
      userWatchersService.delete(watcher.id);
      setWatcher(undefined);
    } else if(!!lead && action === 'create') {
      const { data } = await userWatchersService.create(lead.id, notifications);
      setWatcher(data);
    }
  }, [watcher, lead]);


  const updateLead = (key: string) => async (e: CustomEvent) => {
    // Toggle component uses checked not value
    if ('undefined' === typeof e.detail.value && 'undefined' !== typeof e.detail.checked) {
      e.detail.value = e.detail.checked;
    }

    if (lead?.id !== changes?.id) {
      return;
    }

    const val = e.detail.value;
    if (
      key === 'assigned_to' &&
      val &&
      !clientUsers?.find((it: any) => it.id === val)
    ) {
      return;
    }

    await updateAndSaveLead(key, e, user);

    switch (key) {
      case 'status':
        const status = e.detail.value as number;
        switch (status) {
          case 20:
          case 7:
            await leadsService.archive(lead?.client, lead?.id, {
              noToast: true
            });
            break;
          case 32:
            await leadsService.archive(lead?.client, lead?.id, {
              noToast: true
            });
            break;
          case 33:
            await leadsService.archive(lead?.client, lead?.id, {
              noToast: true
            });
            break;
        }

        const appt = state.lead?.appointments?.[0];
        if (appt) {
          const changeAppointment = async (key: string) => {
            await leadsService.setAppointmentStatus(
              lead?.client,
              lead?.id,
              key,
              appt
            );

            dispatch({
              type: 'set',
              value: { lead }
            });
          };

          switch (status) {
            case 11:
              await changeAppointment('missed');
              break;
            case 12:
              await changeAppointment('cancelled');
              break;
            case 10:
              await changeAppointment('showed');
              break;
            case 40:
              await changeAppointment('showed_lost');
              break;
            case 13:
            case 14:
            case 15:
            case 44:
              await changeAppointment('sold');
              break;
          }
        }
    }
  };

  const updateInternalMessageFilter = () => async (e: CustomEvent) => {
    // Coerce empty string to undefined for API
    if (e.detail.value === '') {
      e.detail.value = undefined;
    }
    const val = e.detail.value;
    dispatch({
      type: 'internalMessageFilter',
      value: val
    });
  }

  if (state.loading) {
    return null;
  }

  return (
    <TExpandableItemGroup title="Actions" defaultExpanded={true}>
      {!!lead && canSeeOptinButton && (
        <OptinButton lead={lead} />
      )}
      {canSeeWatcherToggle && (
        <>
          <TToggleItem
            label="Watch Lead"
            checked={!!watcher}
            onIonChange={(e: any) => updateWatcher(!!watcher ? 'delete' : 'create', true)}
            wrapLabel
          />
          <TToggleItem
            label="Watch Notifications"
            disabled={!watcher}
            checked={!!watcher?.notifications}
            onIonChange={(e: any) => updateWatcher('create', !watcher?.notifications)}
            wrapLabel
          />
        </>
      )}
      <TToggleItem
        color="orange"
        label="Needs Call"
        checked={!!changes?.needs_call}
        onIonChange={updateLead('needs_call')}
        wrapLabel
      />
      <TSelectItem
        label="Classification"
        labelPosition="stacked"
        onChange={updateLead('classification')}
        value={changes?.classification}
        options={classificationOptions}
      />
      {canSeeAdvanceAutomationFeatures && lead?.client_text_ninja && (
        <TToggleItem
          label="No Ninja"
          checked={!!changes?.no_ninja}
          onIonChange={updateLead('no_ninja')}
          wrapLabel
        />
      )}
      {canSeeAdvanceAutomationFeatures && (
        <>
          <TToggleItem
            label="No Automation"
            checked={!!changes?.no_automation}
            onIonChange={updateLead('no_automation')}
            wrapLabel
          />

          <TToggleItem
            label="No Blast"
            checked={!!changes?.no_blast}
            onIonChange={updateLead('no_blast')}
            wrapLabel
          />
        </>
      )}
      <TSelectItem
        label="Status"
        labelPosition="stacked"
        onChange={updateLead('status')}
        value={changes?.status}
        options={util
          .sortByProp(appContext.state.statuses, 'option_name')
          .map(it => ({
            value: it.id,
            text: it.option_name
          }))}
      />
      <TSelectItem
        label="Message Filter"
        labelPosition="stacked"
        onChange={updateInternalMessageFilter()}
        value={state.internalMessageFilter ?? ''}
        options={[
          { value: '', text: 'All' },
          { value: 'internalOnly', text: 'Internal Only' },
          { value: 'notesOnly', text: 'Notes Only' },
          { value: 'excludeInternal', text: 'Exclude Internal' },
          { value: 'callsOnly', text: 'Calls Only' },
          { value: 'emailsOnly', text: 'Emails Only' },
        ]}
      />
      <TSelectItem
        label="Language"
        labelPosition="stacked"
        onChange={updateLead('language')}
        value={changes?.language}
        options={appContext.state.languages.map(it => ({
          value: it.id,
          text: it.name
        }))}
      />

      <TSelectItem
        label="Assigned To"
        labelPosition="stacked"
        onChange={updateLead('assigned_to')}
        value={changes?.assigned_to}
        options={[
          { value: null, text: 'Unassigned' },
          ...util.sortByProp(clientUsers, 'full_name').map((it: any) => ({
            value: it.id,
            text: it.full_name
          }))
        ]}
      />
      {!!lead && canSeeRoundRobinButton && (
        <DoRoundRobinButton lead={lead} />
      )}
    </TExpandableItemGroup>
  );
};

export default LeadActions;
