import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';

class StatusService extends CachedService {
  constructor() {
    super(
      'statuses',
      'statuses',
      [],
      CacheStrategy.TIMEOUT,
      CacheTTL.FIFTEENMINUTES
    );
  }

  async request() {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: '/status/',
      type: 'status',
      exclusive: true
    });

    return res.data;
  }

  get ineligibleBlastStatuses() {
    return [7, 18, 20, 25, 26, 55];
  }
}

export default new StatusService();
