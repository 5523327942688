import React from 'react';
import TImage from './TImage';
import TVideo from './TVideo';

export interface TMediaProps {
  src: string;
  alt?: string;
  style?: any;
  poster?: string;
  unsafe?: boolean;
  contentType: string;
  onClick?: (e?: any) => any;
}

const TMedia: React.FC<TMediaProps> = (props) => {
  const { contentType, poster, onClick, ...rest } = props;

  if (contentType.match(/^image\//i)) {
    return <TImage onClick={onClick} {...rest} />;
  }

  if (contentType.match(/^video\//i)) {
    return <TVideo poster={poster} {...rest} />;
  }

  return null;
};

export default TMedia;
