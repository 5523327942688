import { http } from '../core';
import qs from 'qs';

class AllAppointmentsService {
  async list(params: any) {

    try {
      const query = qs.stringify(params);
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/appointments/?${query}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new AllAppointmentsService();
