import React, { useContext, useRef } from 'react';
import ChatToolButton, { ChatToolButtonProps } from './ChatToolButton';
import {
  media,
  appNotification,
  loadingIndicator,
  browser,
  native
} from '../core';
import {
  images,
  videocam,
  logoFacebook,
  archive,
  wallet,
  send,
  flash,
  informationCircleOutline
} from 'ionicons/icons';
import css from 'classnames';
import { LeadContext } from '../context/LeadContext';
import { leadsService, userService } from '../services';
import '../styles/components/ChatTools.scss';
import { IonGrid, IonCol, IonRow, IonContent } from '@ionic/react';
import { AppContext } from '../context/AppContext';
import { MediaType } from '@ionic-native/camera';
import { ChatMessageBoxContext } from '../context/ChatMessageBoxContext';
import { useOnClickOutside } from '../hooks';

export interface ChatTool extends ChatToolButtonProps {
  visible: boolean;
}

const ChatTools: React.FC = () => {
  const ref = useRef<any>();
  const appContext = useContext(AppContext);
  const chatboxContext = useContext(ChatMessageBoxContext);
  const { user } = appContext.state;
  const { state, dispatch } = useContext(LeadContext);
  const { lead, leadClient } = state;
  const archived = lead?.convo_archived === true;

  useOnClickOutside(ref, () =>
    chatboxContext.dispatch({ type: 'set', value: { showAllTools: false } })
  );

  const captureNative = async (type: MediaType) => {
    try {
      const { file, url } = await media.selectNativeMedia(type);
      chatboxContext.dispatch({
        type: 'set',
        value: {
          selectedFilePreview: url,
          selectedFile: file
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const toggleArchive = async () => {
    try {
      await loadingIndicator.create();
      const isArchived = lead?.convo_archived;
      const fn = isArchived
        ? leadsService.unarchive.bind(leadsService)
        : leadsService.archive.bind(leadsService);

      const updates = await fn(lead?.client, lead?.id, {
        successMessage: `Lead has been ${
          isArchived ? 'unarchived' : 'archived'
        }.`
      });

      dispatch({
        type: 'set',
        value: {
          lead: updates
        }
      });
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const tools = [
    {
      icon: flash,
      title: 'Quick Reply',
      visible: true,
      action: async () =>
        chatboxContext.dispatch({
          type: 'set',
          value: { showQuickReplies: true }
        })
    },
    {
      icon: videocam,
      title: 'Video',
      visible: true,
      action: async () => {
        if (native.isAndroid) {
          captureNative(MediaType.VIDEO);
        } else {
          const res = await media.selectVideo();
          chatboxContext.onFileSelected(res);
        }
      }
    },
    {
      icon: informationCircleOutline,
      title: 'Notes',
      visible: userService.isStaff(user),
      action: async () => {
        dispatch({
          type: 'set',
          value: {
            showLeadNotesModal: true
          }
        });
      }
    },
    {
      icon: images,
      mode: 'md',
      title: 'Photo',
      visible: true,
      action: async () => {
        if (native.isAndroid) {
          captureNative(MediaType.PICTURE);
        } else {
          const res = await media.selectPhoto();
          chatboxContext.onFileSelected(res);
        }
      }
    },
    {
      icon: logoFacebook,
      title: 'Preview',
      visible: true,
      action: () => dispatch({ type: 'set', value: { showFacebookAd: true } })
    },
    {
      icon: send,
      title: 'Send to CRM',
      visible: leadClient?.crm_email,
      action: async () => {
        try {
          if (!lead) {
            return;
          }
          await loadingIndicator.create();
          await leadsService.sendToCRM(lead?.client, lead?.id);
          lead.adf_sent_at = new Date().toISOString();
          dispatch({ type: 'set', value: { lead } });
          appNotification.toast('Lead has been sent to CRM.', 'Success');
        } finally {
          loadingIndicator.dismiss();
        }
      }
    },
    {
      icon: archive,
      className: css({ archived }),
      color: archived ? 'danger' : 'primary',
      title: archived ? 'Unarchive' : 'Archive',
      visible: true,
      action: toggleArchive
    },
    {
      icon: wallet,
      title: 'Agreement',
      visible: user?.is_admin,
      action: () =>
        browser.open(
          `https://agreements.tecobi.com/agreements/create/${lead?.id}`
        )
    }
  ] as ChatTool[];

  return (
    <IonContent
      ref={ref}
      id="chat-tools"
      className={css('chat-tools', {
        visible: chatboxContext.state.showAllTools
      })}
      onClick={() =>
        chatboxContext.dispatch({ type: 'set', value: { showAllTools: false } })
      }
    >
      <IonGrid>
        <IonRow justify-content-start align-items-start>
          {tools
            .filter(it => it.visible)
            .map(({ visible, ...rest }, index) => (
              <IonCol
                key={index}
                sizeXs="3"
                sizeSm="3"
                sizeMd="2"
                sizeLg="2"
                sizeXl="2"
              >
                <ChatToolButton {...rest} />
              </IonCol>
            ))}
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default ChatTools;
