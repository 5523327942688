import React, { useContext, useEffect, useState } from 'react';
import TModal from './TModal';
import moment from 'moment';
import { AppContext } from '../../context/AppContext';
import DataTable from 'react-data-table-component';
import { http, util } from '../../core';
import { useHistory } from "react-router-dom";
import TLoading from '../TLoading';
export interface PaginatedLeadListModalProps {
  isOpen: boolean;
  userId: number;
  since: Date;
  until: Date;
  title: string;
  endpoint: string;
  dateTimeField: string;
  tableTitle?: string;
  onDidDismiss: () => any;
}
const customStyles: any = {
  rows: {
    style: {
      cursor: 'pointer',
    }
  },
};
const columns = [
  {
    name: 'Name',
    selector: 'full_name',
  },
  {
    name: 'Touched',
    selector: 'created_at',
    format: (row: any)=> moment(row.created_at).format('lll'),
  }
];

const PaginatedLeadListModal: React.FC<PaginatedLeadListModalProps> = ({
  isOpen,
  userId,
  since,
  until,
  title,
  endpoint,
  dateTimeField,
  tableTitle,
  onDidDismiss
}) => {
  const { state } = useContext(AppContext);
  const history = useHistory();
  // Used for is_full_service hide avg response time chart
  const { user } = state;
  const [leads, setLeads] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const countPerPage = 7;
  util.createTecobiDarkDataTableTheme();
  useEffect(() => {
    const getLeadList = async () => {
      const url = `/${endpoint}/${userId}/?page=${page}&page_size=${countPerPage}&${dateTimeField}__gte=${since}&${dateTimeField}__lte=${until}`
      const res = await http.authorizedRequest({
        method: 'GET',
        url,
        type: endpoint,
        exclusive: true
      });
      setLeads(res.data);
      setLoading(false);
    }
    getLeadList();
  }, [page, userId, dateTimeField, endpoint, since, until]);

  return (
    <TModal
      isOpen={isOpen}
      title={title}
      onDidDismiss={onDidDismiss}
    >
        <TLoading visible={loading} text="Loading" />
        <DataTable
          title={tableTitle}
          columns={columns}
          customStyles={customStyles}
          data={leads?.results}
          theme={user.mobile_theme === 'dark' ? 'tecobi-dark' : 'default'}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={leads?.count}
          paginationPerPage={countPerPage}
          paginationComponentOptions={{
            noRowsPerPage: true
          }}
          onRowClicked={(row:any, e: any) => {
            history.push(`/text-messages/conversation/${row.client_id}/${row.id}/`);
            onDidDismiss();
          }}
          onChangePage={page => setPage(page)}
        />
    </TModal>
  );
};

export default PaginatedLeadListModal;
