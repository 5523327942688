class Emotion {
  getEmoji(emotion: string) {
    switch (emotion) {
      case 'accepting':
        return '🤝';
      case 'accommodating':
        return '🛏️';
      case 'aggressive':
        return '💢';
      case 'agreeable':
        return '🤝';
      case 'agreement':
        return '🤝';
      case 'amused':
        return '😆';
      case 'anger':
        return '💢';
      case 'angered':
        return '💢';
      case 'angry':
        return '💢';
      case 'annoyance':
        return '😒';
      case 'anxiety':
        return '😰';
      case 'anxious':
        return '😰';
      case 'apologetic':
        return '🙇‍♂️';
      case 'apology':
        return '🙇‍♂️';
      case 'appreciative':
        return '🙏';
      case 'apprehensive':
        return '😬';
      case 'assertive':
        return '💪';
      case 'blessing':
        return '🙏';
      case 'calm':
        return '😌';
      case 'casual':
        return '😎';
      case 'comfortable':
        return '😌';
      case 'compassionate':
        return '🤗';
      case 'compliant':
        return '🙌';
      case 'concern':
        return '😟';
      case 'concerned':
        return '😟';
      case 'confident':
        return '😎';
      case 'confused':
        return '😕';
      case 'confusion':
        return '😕';
      case 'congratulatory':
        return '🎉';
      case 'content':
        return '😊';
      case 'conversational':
        return '💬';
      case 'cooperative':
        return '🤝';
      case 'cordial':
        return '🤝';
      case 'courteous':
        return '🤝';
      case 'curiosity':
        return '🤔';
      case 'curious':
        return '🤔';
      case 'curt':
        return '🤐';
      case 'defensive':
        return '🛡️';
      case 'desperate':
        return '😫';
      case 'disappointed':
        return '😞';
      case 'disappointment':
        return '😞';
      case 'disbelief':
        return '😮';
      case 'discomfort':
        return '😣';
      case 'discouraged':
        return '😔';
      case 'disgust':
        return '🤢';
      case 'disgusted':
        return '🤢';
      case 'disinterest':
        return '😐';
      case 'disjointed':
        return '🤨';
      case 'displeased':
        return '😒';
      case 'dissatisfaction':
        return '😒';
      case 'dissatisfied':
        return '😒';
      case 'distress':
        return '😫';
      case 'distressed':
        return '😫';
      case 'distrust':
        return '😒';
      case 'distrustful':
        return '😒';
      case 'doubt':
        return '🤔';
      case 'driven':
        return '🏎️';
      case 'eager':
        return '😃';
      case 'embarrassed':
        return '😳';
      case 'emotional':
        return '😭';
      case 'empathetic':
        return '🤝';
      case 'empathy':
        return '🤝';
      case 'enthusiasm':
        return '🤩';
      case 'enthusiastic':
        return '🤩';
      case 'excited':
        return '😃';
      case 'excitement':
        return '😃';
      case 'exhaustion':
        return '😫';
      case 'fear':
        return '😨';
      case 'friendly':
        return '😊';
      case 'frustrated, apology':
        return '🙇‍♂️';
      case 'frustration':
        return '😠';
      case 'funny':
        return '😂';
      case 'good':
        return '👍';
      case 'grateful':
        return '🙏';
      case 'gratitude':
        return '🙏';
      case 'happiness':
        return '😊';
      case 'happy':
        return '😊';
      case 'helpful':
        return '🤝';
      case 'hesitant':
        return '🤔';
      case 'hesitation':
        return '🤔';
      case 'hopeful':
        return '🤞';
      case 'humor':
        return '😂';
      case 'humorous':
        return '😂';
      case 'impatience':
        return '😒';
      case 'impatient':
        return '😒';
      case 'inappropriate':
        return '🙅‍♀️';
      case 'indifference':
        return '😐';
      case 'indifferent':
        return '😐';
      case 'informative':
        return '🧐';
      case 'inquiring':
        return '🤔';
      case 'inquisitive':
        return '🤔';
      case 'interest':
        return '😀';
      case 'interested':
        return '😀';
      case 'irritated':
        return '😒';
      case 'jokey':
        return '😜';
      case 'joking':
        return '😜';
      case 'knowledgeable':
        return '🤓';
      case 'laughing':
        return '😂';
      case 'lonely':
        return '😔';
      case 'love':
        return '❤️';
      case 'mad':
        return '🤬';
      case 'motivated':
        return '💪';
      case 'negative':
        return '👎';
      case 'nervous':
        return '😰';
      case 'neutral':
        return '😐';
      case 'neutrality':
        return '😐';
      case 'none':
        return '';
      case 'nostalgic':
        return '🕰️';
      case 'offended':
        return '😠';
      case 'optimism':
        return '👍';
      case 'optimistic':
        return '👍';
      case 'pain':
        return '😣';
      case 'patient':
        return '🙏';
      case 'persuasive':
        return '🗣️';
      case 'playful':
        return '😜';
      case 'pleasant':
        return '😊';
      case 'pleased':
        return '😊';
      case 'polite':
        return '🙏';
      case 'politeness':
        return '🙏';
      case 'positive':
        return '👍';
      case 'potentially angry':
        return '😠';
      case 'professional':
        return '💼';
      case 'pushy':
        return '🤏';
      case 'reassured':
        return '👍';
      case 'relieved':
        return '😌';
      case 'reluctant':
        return '😕';
      case 'resignation':
        return '😔';
      case 'resigned':
        return '😔';
      case 'respectful':
        return '🙏';
      case 'sad':
        return '😢';
      case 'sales':
        return '💰';
      case 'sadness':
        return '😢';
      case 'satisfaction':
        return '😌';
      case 'satisfied':
        return '😌';
      case 'scared':
        return '😨';
      case 'serious':
        return '😐';
      case 'service':
        return '🛎️';
      case 'shocked':
        return '😱';
      case 'skeptical':
        return '🤨';
      case 'slightly hopeful':
        return '🙂';
      case 'sorry':
        return '🙏';
      case 'strained':
        return '😬';
      case 'stressed':
        return '😫';
      case 'surprise':
        return '😮';
      case 'surprised':
        return '😮';
      case 'sympathetic':
        return '🤗';
      case 'sympathy':
        return '🤗';
      case 'thankful':
        return '🙏';
      case 'thanks':
        return '🙏';
      case 'threatening':
        return '😠';
      case 'tired':
        return '😴';
      case 'uncertain':
        return '😕';
      case 'uncertainty':
        return '😕';
      case 'unclear':
        return '🤔';
      case 'understanding':
        return '👍';
      case 'unhappy':
        return '😔';
      case 'uninterested':
        return '😒';
      case 'unknown':
        return '🤷';
      case 'unprofessional':
        return '🤦‍♂️';
      case 'upbeat':
        return '😄';
      case 'upset':
        return '😔';
      case 'urgent':
        return '🚨';
      case 'warning':
        return '⚠️';
      case 'welcoming':
        return '🤝';
      case 'willingness to purchase':
        return '💰';
      case 'worried':
        return '😟';
      default:
        return '😐';
    }
  }
}
export default new Emotion();
