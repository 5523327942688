import React, { useCallback } from 'react';
import TModal from './TModal';
import { IonList, IonItemDivider, IonIcon, IonLabel, IonButton } from '@ionic/react';
import TItem from '../TItem';
import TButtonItem from '../TButtonItem';
import ButtonGrid from '../ButtonGrid';
import { phoneCallsService } from '../../services';
import { loadingIndicator, appNotification } from '../../core';
import moment from 'moment';
import { apps, person, time, chatbubble, callOutline, earOutline, helpBuoyOutline } from 'ionicons/icons';
export interface UserCallsModalProps {
  isOpen?: boolean;
  call: any;
  user: any;
  clientId: number;
  onDidDismiss: () => any;
}

const UserCallsModal: React.FC<UserCallsModalProps> = ({
  isOpen,
  call,
  user,
  clientId,
  onDidDismiss
}) => {

  const callBarge = useCallback(async (mode) => {
    try {
      await loadingIndicator.create();
      await phoneCallsService.callBarge(clientId, call.id, mode);
      appNotification.toast(
        `Answer your phone when it rings.`,
        `${mode}`,
        {
          duration: 0
        }
      );
    } finally {
      loadingIndicator.dismiss();
    }
  }, [clientId, call]);

  return (
    <TModal
      isOpen={isOpen}
      title={`${user.full_name} Call in Progress`}
      onDidDismiss={onDidDismiss}
    >
      <IonList lines="full">
      {call && (
        <>
          <IonItemDivider mode="md" sticky>
            <IonIcon icon={person} /> Lead
          </IonItemDivider>
          <TItem lines="full">
            <IonLabel>
              {call?.lead_full_name}
            </IonLabel>
          </TItem>
          <TButtonItem
            color="secondary"
            routerLink={`/text-messages/conversation/${clientId}/${call.lead}/`}
            onClick={onDidDismiss}
          >
            <IonIcon icon={chatbubble} slot="start" />
            Go to Conversation
          </TButtonItem>
          <IonItemDivider mode="md" sticky>
            <IonIcon icon={time} />
            &nbsp;Duration
          </IonItemDivider>
          <TItem
            label={moment(call.created_at).fromNow(true)}
            lines="full"
          />
          <IonItemDivider mode="md" sticky>
            <IonIcon icon={apps} />
            &nbsp;Actions
          </IonItemDivider>
          <ButtonGrid>
            <IonButton
              color="success"
              onClick={
                (e) => {
                  e.preventDefault();
                  callBarge('listen');
                }
              }
              expand="block"
            >
              <IonIcon icon={earOutline} slot="start" />
              Listen
            </IonButton>
            <IonButton
              color="warning"
              onClick={
                (e) => {
                  e.preventDefault();
                  callBarge('whisper');
                }
              }
              expand="block"
            >
              <IonIcon icon={helpBuoyOutline} slot="start" />
              Whisper
            </IonButton>
            <IonButton
              color="danger"
              onClick={
                (e) => {
                  e.preventDefault();
                  callBarge('barge');
                }
              }
              expand="block"
            >
              <IonIcon icon={callOutline} slot="start" />
              Barge
            </IonButton>
          </ButtonGrid>
        </>
      )}

      </IonList>
    </TModal>
  );
};

export default UserCallsModal;
