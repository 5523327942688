import React, { useState, useEffect } from 'react';
import TInput from '../TInput';
import { IonItem, IonButton, IonText } from '@ionic/react';
import http from '../../core/http';
import { modalController } from '@ionic/core';
import { loginService } from '../../services';
import TModal from './TModal';
import ButtonGrid from '../ButtonGrid';
import { appNotification } from '../../core';
import { useLocation, useParams } from 'react-router';

interface ForgotPasswordProps {
  hint?: string;
  isOpen?: boolean;
  onDidDismiss?: (e?: any) => void;
  onWillDismiss?: (o?: any) => void;
}

const PasswordReset: React.FC<ForgotPasswordProps> = ({
  hint,
  isOpen,
  onDidDismiss,
  onWillDismiss
}) => {
  const location = useLocation();
  const params = useParams() as any;
  const [email, setEmail] = useState<any>(
    decodeURIComponent(params.email ?? hint)
  );
  const [loading, setLoading] = useState(false);
  const [sentCode, setSentCode] = useState(
    !!location.pathname.match(/\/reset-password\//i)
  );
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => setEmail(decodeURIComponent(params.email ?? hint)), [
    hint,
    params.email
  ]);

  const sendRequest = async ({ url, data, onComplete }: any) => {
    if (loading) {
      return;
    }

    if (!email || (sentCode && (!password || !code))) {
      appNotification.toast('Please fill out the form completely.', 'Error');
      return;
    }

    setLoading(true);

    try {
      const res = await loginService.sendPasswordResetRequest({ url, data });
      const msg = res.data.message;
      onComplete(msg);
    } catch (e) {
      http.onHttpError(e);
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async () => {
    await sendRequest({
      url: '/password-reset/reset/',
      data: {
        email,
        password,
        code
      },
      onComplete(message: string) {
        if (onWillDismiss) {
          onWillDismiss({ message, email });
        }

        modalController.dismiss();
      }
    });
  };

  const sendText = async () => {
    await sendRequest({
      url: '/password-reset/request-code/',
      data: {
        email
      },
      onComplete(msg: string) {
        appNotification.toast(msg, 'Code Sent');
        setSentCode(true);
      }
    });
  };

  return (
    <TModal
      isOpen={isOpen}
      cssClass="password-reset-modal"
      onDidDismiss={(e: any) => {
        setSentCode(false);
        onDidDismiss?.(e);
      }}
      title="Reset Password"
      footer={
        <ButtonGrid>
          <IonButton
            type="submit"
            color="success"
            className="no-margin"
            expand="block"
            disabled={loading}
            onClick={sentCode ? resetPassword : sendText}
          >
            {sentCode ? 'Reset Password' : 'Send'}
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonText color="medium">
        <p>
          {sentCode
            ? 'Please enter the code in that you received and a new password'
            : 'Enter your email address and we will text you a code.'}
        </p>
      </IonText>
      {!sentCode && (
        <IonItem className="input-background border-radius" lines="none">
          <TInput
            type="email"
            value={email}
            valueChanged={setEmail}
            onEnterPressed={sendText}
            placeholder="Email"
          />
        </IonItem>
      )}
      {sentCode && (
        <IonItem className="input-background border-radius-top" lines="full">
          <TInput
            type="text"
            value={code}
            valueChanged={setCode}
            onEnterPressed={resetPassword}
            autoComplete="new-password"
            placeholder="Code"
          />
        </IonItem>
      )}
      {sentCode && (
        <IonItem className="input-background border-radius-bottom" lines="none">
          <TInput
            type="password"
            autoComplete="new-password"
            value={password}
            valueChanged={setPassword}
            onEnterPressed={resetPassword}
            placeholder="Password"
          />
        </IonItem>
      )}
    </TModal>
  );
};

export default PasswordReset;
