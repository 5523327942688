import React, { useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import ChatMessageBox from './ChatMessageBox';
import ChatToolButton from './ChatToolButton';
import { util, loadingIndicator, appNotification } from '../core';
import { helpBuoy, pause, apps, arrowUp, mail } from 'ionicons/icons';
import { IonProgressBar, IonItem, IonRouterLink } from '@ionic/react';
import { leadsService, inboundLeadsService, userService } from '../services';
import { LeadContext, scrollToBottom } from '../context/LeadContext';
import ChatFilePreview from './ChatFilePreview';
import ChatTools from './ChatTools';
import css from 'classnames';
import '../styles/components/ChatFooter.scss';
import {
  ChatMessageBoxContext,
  getMessageText,
  clearMessage,
  setMessageFocus
} from '../context/ChatMessageBoxContext';
import {
  useKeyboardShortcuts,
  useRouter,
  usePortal,
  usePopper
} from '../hooks';
import moment from 'moment';
import { AppContext } from '../context/AppContext';

const ChatFooter: React.FC = () => {
  const router = useRouter();
  const nextLeadButton = useRef<any>();
  const chatFooter = useRef<any>();
  const { state: appState } = useContext(AppContext);
  const gettingNextLead = useRef<boolean>(false);
  const { user } = appState;
  const disallowPauseShorcut = user.is_text_ninja || user.is_phone_ninja;
  const portal = usePortal('chat-footer-portal');

  usePopper(chatFooter, nextLeadButton, {
    placement: 'top'
  });

  const {
    state,
    dispatch,
    createNewMessage,
    createNewInternalMessage,
    leadUpdated,
    nextLeadClientId,
    isInbound,
    isNeedsCall,
    getNextLeadLink
  } = useContext(LeadContext);

  const isOptOut = !!(state && state.lead && state.lead.sms_opt_out) ? (state?.lead?.sms_opt_out > 8) : false;

  const allowEmail = user?.is_staff && state?.lead?.email_address;

  const {
    isEmptyMessage,
    appendMessage,
    state: cbState,
    dispatch: cbDispatch
  } = useContext(ChatMessageBoxContext);

  const {
    selectedGif,
    selectedFile,
    selectedFilePreview,
    internalOnly,
    isEmail,
    allNotificationGroups,
    showAllTools,
    shortcode
  } = cbState;
  const { pause_date } = state.lead || {};

  const paused = !!pause_date;

  const pauseShortcut = (
    duration: number,
    interval: any,
    message?: string,
    disallow=false
  ) => async () => {
    // Don't let ninja's use pause shortcuts because they abuse it.
    if (!paused && !disallow) {
      try {
        await loadingIndicator.create();

        const date = moment()
          .tz(leadsService.getTimezone(state.lead))
          .add(duration, interval);

        const lead = state.lead;

        const res = await leadsService.update(
          {
            client: lead?.client,
            id: lead?.id,
            pause_date: date.toISOString(),
            pause_message: message
          },
          { noToast: true }
        );

        leadUpdated(res);

        await createNewInternalMessage({
          message: `Pause Date ${date.format('MM/DD/YY hh:mma zz')}`
        });

        if (message) {
          await createNewInternalMessage({
            message: `Pause Message: ${message}`
          });
        }

        await getNextLead();
      } finally {
        loadingIndicator.dismiss();
      }
    }
  };

  const checkCustomShortcodeBehavior = (code: string | null | undefined) => {
    switch (code) {
      case ':no':
        pauseShortcut(1, 'month', 'Are you ready to look at vehicles yet?', false)();
        break;
    }
  };

  // const sendShortcode = (shortcode: string) => async () => {
  //   const reply = appState.shortcodes[shortcode];
  //   expandQuickReply(reply, state.lead);
  //   await sendMessage();
  //   checkCustomShortcodeBehavior(shortcode);
  // };

  const sendMessage = async (e?: any) => {
    const message = getMessageText();

    setMessageFocus();

    if (cbState.showMentionsPopup) {
      return cbDispatch({
        type: 'setMention'
      });
    }

    if (util.isNativeOrModifierEnter(e)) {
      return appendMessage('\n');
    }
    if (isOptOut && !cbState.internalOnly) {
      appNotification.toast(
        'You cannot send messages to leads who have opted out.',
        'Opt-out',
        {color: 'danger'}
      );
      return;
    }
    if (isEmptyMessage()) {
      return;
    }

    if (state.sending === true) {
      return;
    }

    const lastUsedShortcode = shortcode;

    cbDispatch({
      type: 'set',
      value: {
        shortcode: null,
        selectedGif: null,
        selectedFile: null,
        selectedFilePreview: null
      }
    });

    clearMessage();

    dispatch({
      type: 'set',
      value: {
        sending: true
      }
    });

    const gif_url = selectedGif;
    const text_ninja = internalOnly;
    const is_email = isEmail;
    const media = selectedFile;
    const preview = selectedFilePreview;

    if (state.lead?.carrier_type === 'landline') {
      appNotification.toast(
        'Message not sent, number is a landline.',
        'Error',
        {color: 'danger'}
      );
    }


    try {
      await createNewMessage(
        {
          message,
          gif_url,
          text_ninja,
          media,
          is_email,
          outbound_email_address: is_email ? state?.lead?.email_address : undefined,
          email_subject: is_email ? state?.lead?.client_name : undefined,
        },
        (pct: number) => dispatch({ type: 'set', value: { sendProgress: pct } })
      );

      util.delay(scrollToBottom, 100);

      if (!(isInbound || isNeedsCall)) {
        inboundLeadsService
          .getNextInboundLead(user, nextLeadClientId())
          .then((lead: any) => {
            if (lead) {
              dispatch({ type: 'set', value: { nextLead: lead } });
            }
          });
      } else if (inboundLeadsService.inboundCount > 0) {
        dispatch({ type: 'set', value: { nextLead: true } });
      }

      checkCustomShortcodeBehavior(lastUsedShortcode);
    } catch (e) {
      cbDispatch({
        type: 'set',
        value: {
          message,
          shortcode: lastUsedShortcode,
          selectedGif: gif_url,
          selectedFile: media,
          selectedFilePreview: preview
        }
      });
      console.error(e);
      appNotification.toast(
        'Error sending message. Please try again.',
        'Error'
      );
    } finally {
      dispatch({
        type: 'set',
        value: {
          sendProgress: 0,
          sending: false
        }
      });
    }
  };

  const unpause = async () => {
    try {
      await loadingIndicator.create();
      const { lead } = state;
      const updates = {
        id: lead?.id,
        client: lead?.client,
        pause_date: null,
        pause_message: null
      };
      const updated = await leadsService.update(updates, {
        successMessage: 'Lead has been unpaused.'
      });
      dispatch({
        type: 'set',
        value: { lead: updated }
      });

      await createNewMessage({
        message: 'Pause date cleared',
        text_ninja: true
      });

      util.delay(() => scrollToBottom?.(), 250);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const toggleInternalOnly = () => {
    cbDispatch({
      type: 'set',
      value: {
        internalOnly: !internalOnly
      }
    });
    setMessageFocus();
  };

  const toggleIsEmail = () => {
    cbDispatch({
      type: 'set',
      value: {
        isEmail: !isEmail
      }
    });
    setMessageFocus();
  };

  const resetSelectedGif = () =>
    cbDispatch({
      type: 'set',
      value: { selectedGif: null }
    });

  const removeSelectedFile = () =>
    cbDispatch({
      type: 'set',
      value: {
        selectedFile: null,
        selectedFilePreview: null
      }
    });

  const onPauseClicked = () =>
    paused
      ? unpause()
      : dispatch({ type: 'set', value: { showPauseModal: true } });

  const toggleAllTools = () =>
    cbDispatch({
      type: 'set',
      value: {
        showAllTools: !showAllTools
      }
    });

  const getNextLead = async () => {
    if (gettingNextLead.current === true) {
      return;
    }

    try {
      const isInbound = util.locationIsInbound(router.location);
      const isNeedsCall = util.locationIsNeedsCall(router.location);
      const currentLeadId = state.lead?.id;
      if ((isInbound || isNeedsCall) && currentLeadId) {
        inboundLeadsService.removeServedLead(currentLeadId);
      }

      dispatch({ type: 'clear' });
      gettingNextLead.current = true;
      await loadingIndicator.create(`Getting next lead...`);

      const nextLead = await inboundLeadsService.getNextInboundLead(
        user,
        nextLeadClientId(),
        isNeedsCall
      );

      await loadingIndicator.dismiss();

      if (nextLead) {
        const clientId = nextLead.client_id;
        const leadId = nextLead.id;
        router.replace(leadsService.getLink(clientId, leadId, isInbound, isNeedsCall));
      } else {
        appNotification.toast(
          'There are no more leads waiting.',
          'No Leads Waiting'
        );
        router.replace((isInbound || isNeedsCall) ? '' : '/text-messages/', {});
      }
    } finally {
      gettingNextLead.current = false;
    }
  };

  const sendAndGetNextLead = (tag?: boolean) => async () => {
    await sendMessage(null);

    if (tag && state.lead) {
      let message =
        '@sales @bdc we need some help with this customer. Please pick up the customer.';
      if (state.lead?.assigned_to_info) {
        const mention = allNotificationGroups.find(
          it => it.name === state.lead?.assigned_to_info?.full_name
        );
        if (mention) {
          message = `@${mention.username} we need your assistance with one of your customers.`;
        }
      }
      await createNewInternalMessage({
        message
      });
    } else {
      //Don't set next lead if they tagged the salesperson
      await getNextLead();
    }
  };

  const archiveAndGetNextLead = async () => {
    try {
      await loadingIndicator.create();
      const { lead } = state;
      const res = await leadsService.archive(lead?.client, lead?.id, {
        noToast: true
      });
      leadUpdated(res);
      await getNextLead();
    } finally {
      loadingIndicator.dismiss();
    }
  };

  useKeyboardShortcuts({
    'Ctrl+1': toggleInternalOnly,
    'Ctrl+2': sendAndGetNextLead(false),
    'Ctrl+i': () => {
      if (userService.canAccessInboundQueue(user)) {
        dispatch({
          type: 'set',
          value: { showLeadNotesModal: !state.showLeadNotesModal }
        });
      }
    },
    // Ctrl+3 being used too much by mistake
    // 'Ctrl+3': sendAndGetNextLead(true),
    'Ctrl+4': pauseShortcut(1, 'hour', undefined, disallowPauseShorcut),
    'Ctrl+5': pauseShortcut(1, 'month', undefined, disallowPauseShorcut),
    'Ctrl+6': pauseShortcut(1, 'year', undefined, disallowPauseShorcut),
    'Ctrl+7': pauseShortcut(1, 'day', undefined, disallowPauseShorcut),
    'Ctrl+8': pauseShortcut(1, 'week', undefined, disallowPauseShorcut),
    'Ctrl+9': pauseShortcut(3, 'month', undefined, disallowPauseShorcut),
    'Ctrl+0': archiveAndGetNextLead,
    'Ctrl+Space': () =>
      cbDispatch({
        type: 'set',
        value: {
          showQuickReplies: !cbState.showQuickReplies
        }
      })
  });

  if (state.loading) {
    return null;
  }

  return (
    <>
      {state.nextLead &&
        ReactDOM.createPortal(
          <IonRouterLink
            className="waiting-lead"
            ref={nextLeadButton}
            routerLink={
              state.nextLead?.client_id
                ? getNextLeadLink(state.nextLead?.client_id, state.nextLead?.id)
                : undefined
            }
            onClick={!state.nextLead?.client_id ? getNextLead : undefined}
            routerDirection="none"
          >
            Go to Next Lead
          </IonRouterLink>,
          portal
        )}
      <ChatTools />
      {state.sending && (
        <div>
          <IonProgressBar color="success" value={state.sendProgress} />
        </div>
      )}
      {selectedGif && (
        <ChatFilePreview
          onRemoveClick={resetSelectedGif}
          contentType="image/gif"
          alt="Selected GIF"
          src={selectedGif}
        />
      )}
      {selectedFile && (
        <ChatFilePreview
          onRemoveClick={removeSelectedFile}
          contentType={selectedFile.type}
          alt="Selected Media"
          src={selectedFilePreview}
        />
      )}
      <IonItem
        lines="none"
        ref={chatFooter}
        className={css('chat-footer', {
          internal: cbState.internalOnly,
          optout: isOptOut && !cbState.internalOnly
        })}
      >
        <ChatToolButton
          icon={pause}
          color={paused ? 'warning' : 'primary'}
          title={paused ? 'Paused' : 'Pause'}
          action={onPauseClicked}
        />
        <ChatToolButton
          className="pointer internal-toggle-button"
          icon={helpBuoy}
          color={cbState.internalOnly ? 'warning' : ''}
          title="Toggle Internal Only"
          action={toggleInternalOnly}
        />
        {allowEmail && (
          <ChatToolButton
            className="pointer internal-toggle-button"
            icon={mail}
            color={cbState.isEmail ? 'secondary' : ''}
            title="Toggle Is Email"
            action={toggleIsEmail}
          />
        )}
        <ChatToolButton
          icon={apps}
          color={cbState.showAllTools ? 'secondary' : 'primary'}
          title="Show All Tools"
          action={toggleAllTools}
        />
        <ChatMessageBox sendMessage={sendMessage} />
        <ChatToolButton
          className={css('send-message-button', {
            visible: !isEmptyMessage() && !isOptOut
          })}
          icon={arrowUp}
          title="Send Message"
          action={sendMessage}
        />
      </IonItem>
    </>
  );
};

export default ChatFooter;
