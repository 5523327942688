import React, { useState, useEffect } from 'react';
import { TItemProps, default as TItem } from './TItem';
import { TInputProps, default as TInput } from './TInput';
import { AutoCompleteResultsProps } from './AutoCompleteResults';

type CombinedProps = TItemProps & AutoCompleteResultsProps;

export interface TInputItemProps extends CombinedProps {
  placeholder?: string;
  value?: string;
  childInputRef?: any;
  type?: HTMLIonInputElement['type'];
  pattern?: HTMLIonInputElement['pattern'];
  autocapitalize?: HTMLIonInputElement['autocapitalize'];
  inputMode?: HTMLIonInputElement['inputMode'];
  minlength?: HTMLIonInputElement['minlength'];
  maxlength?: HTMLIonInputElement['maxlength'];
  disabled?: HTMLIonInputElement['disabled'];
  debounce?: HTMLIonInputElement['debounce'];
  max?: HTMLIonInputElement['max'];
  min?: HTMLIonInputElement['min'];
  multiline?: boolean;
  autoGrow?: HTMLIonTextareaElement['autoGrow'];
  mask?: TInputProps['mask'];
  maskPlaceholder?: TInputProps['maskPlaceholder'];
  required?: boolean;
  valueModifier?: any;
  valuePrepend?: any;
  validation?: (value: string | undefined) => boolean;
  onChange?: (e: CustomEvent) => any;
  onBlur?: (e: CustomEvent) => any;
  onEnterPressed?: TInputProps['onEnterPressed'];
}

const TInputItem: React.FC<TInputItemProps> = ({
  onChange,
  onBlur,
  value,
  childInputRef,
  placeholder,
  children,
  labelPosition,
  type,
  pattern,
  autocapitalize,
  inputMode,
  minlength,
  maxlength,
  disabled,
  required,
  mask,
  maskPlaceholder,
  valueModifier,
  validation,
  debounce,
  onSelected,
  onSelectedIndexChanged,
  results,
  renderText,
  displayKey,
  multiline,
  autoGrow,
  valuePrepend,
  max,
  min,
  onEnterPressed,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const inputProps = {
    type,
    pattern,
    autocapitalize,
    autoGrow,
    inputMode,
    disabled,
    minlength,
    maxlength,
    required,
    mask,
    maskPlaceholder,
    valueModifier,
    validation,
    debounce,
    onSelected,
    onSelectedIndexChanged,
    results,
    renderText,
    displayKey,
    multiline,
    valuePrepend,
    max,
    onEnterPressed
  } as Partial<TInputProps>;

  return (
    <TItem labelPosition={labelPosition} {...rest}>
      <TInput
        childInputRef={childInputRef}
        style={{ textAlign: !labelPosition ? 'right' : undefined }}
        onIonChange={(e: CustomEvent) => {
          let value = e.detail.value;
          setInputValue(value);
          if (valueModifier) {
            value = value?.replace(valueModifier, '');
          }

          if (onChange && (!validation || validation(value) === true)) {
            onChange(e);
          }
        }}
        onIonBlur={(e: CustomEvent) => {
          let value = inputValue;
          if (valueModifier) {
            value = value?.replace(valueModifier, '');
          }

          if (onBlur && (!validation || validation(value) === true)) {
            onBlur(new CustomEvent('onIonBlur', { detail: { value } }));
          }
        }}
        alignment={labelPosition === 'stacked' ? 'start' : 'end'}
        value={inputValue}
        placeholder={placeholder}
        {...inputProps}
      />
      {children}
    </TItem>
  );
};

export default TInputItem;
