import { http, userDefaults } from '../core';

class TrainingService {
  async listCategories() {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: '/training/categories/'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getQuizzes(slug: string) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/training/categories/${slug}/`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getQuiz(slug: string, quizId: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/training/categories/${slug}/quizes/${quizId}/`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async createSession(quizId: any) {
    try {
      const key = `quiz_${quizId}`;
      const existing = (await userDefaults.getValue(key)) as any;
      if (existing && existing.is_complete === false) {
        return existing;
      }

      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/training/sessions/`,
        data: { quiz: quizId }
      });

      await userDefaults.setValue(key, data);

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getResults(sessionId: any) {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: `/training/sessions/${sessionId}/`
    });

    return data;
  }

  async logAnswer(quizId: any, session: any, question: any, choice: any) {
    try {
      const key = `quiz_${quizId}`;

      await http.authorizedRequest({
        method: 'POST',
        url: `/training/answers/`,
        data: { session, question, choice }
      });

      const data = await this.getResults(session);

      await userDefaults.setValue(key, data);

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new TrainingService();
