import React, { useCallback, useContext } from 'react';
import HapticButton from './HapticButton';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon
} from '@ionic/react';
import { trophy, sad } from 'ionicons/icons';
import { TrainingContext } from '../context/TrainingContext';

const TrainingResults: React.FC = () => {
  const { state, dispatch } = useContext(TrainingContext);
  const { quiz, session, module } = state;

  const allCorrect = session.incorrect_count === 0;
  const resultsIcon = allCorrect ? trophy : sad;
  const resultsText = allCorrect
    ? 'Congratulations! You aced the quiz!'
    : `You got ${session?.correct_count} out of ${session?.answer_count} questions correct.`;

  const retryQuiz = useCallback(() => {
    dispatch({ session: null });
  }, [dispatch]);

  if (!quiz) {
    return null;
  }

  return (
    <IonCard className="training-results">
      <IonCardHeader>
        <IonCardTitle>Score: {Math.round(session.score)}%</IonCardTitle>
        <IonCardSubtitle>
          Results for Lesson {quiz.order}: {quiz.name}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="quiz-results">
          <IonIcon
            color={allCorrect ? 'warning' : 'primary  '}
            icon={resultsIcon}
          />
          <h2>{resultsText}</h2>
          {allCorrect ? (
            <HapticButton
              color="secondary"
              fill="solid"
              routerLink={`/training/${module}/`}
            >
              Back to List
            </HapticButton>
          ) : (
            <HapticButton color="danger" fill="solid" onClick={retryQuiz}>
              Try Again
            </HapticButton>
          )}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default TrainingResults;
