import { http } from '../core';

class ClientWidgetService {
  async load(clientId: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/widget-settings/`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async update(clientId: any, id: any, settings: any) {
    try {
      const formData = new FormData();
      Object.keys(settings).forEach((k: string) => {
        formData.append(k, settings[k]);
      });
      const { data } = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/widget-settings/${id}/`,
        data: formData
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new ClientWidgetService();
