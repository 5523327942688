import React, { useContext, useCallback } from 'react';
import { loadingIndicator } from '../core';
import { LeadContext } from '../context/LeadContext';
import { AppContext } from '../context/AppContext';
import { urlShortenerService } from '../services';
import SendButton from './SendButton';
import TItem from './TItem';
import { IonIcon, IonButtons } from '@ionic/react';
import TExpandableItemGroup from './TExpandableItemGroup';
import { videocamOutline } from 'ionicons/icons';
import TButtonItem from './TButtonItem';
import { appendMessageText } from '../context/ChatMessageBoxContext';

const LeadVideoMeetingRoom: React.FC = () => {
  const { state } = useContext(LeadContext);
  const userState = useContext(AppContext).state;
  const { user } = userState;
  const { lead } = state;

  const activeVideoMeetingRoom = lead?.active_video_meeting_room;

  const leadVmrLink = lead ? `https://meet.tecobi.com/room/${lead.client}/${user.id}/${lead.urlhash}/${lead.first_name}%20${lead.last_name}/1` : '';
  const userVmrLink = lead ? `https://meet.tecobi.com/room/${lead.client}/${user.id}/${lead.urlhash}/${user.first_name}%20${user.last_name}` : '';

  const appendVideoMeetingLink = useCallback(async (shortUrl) => {
    appendMessageText(`Lets video chat: ${shortUrl}`);
  }, []);

  return (
    <>
      {lead && lead?.client_video_meetings_enabled && (
        <TExpandableItemGroup title="Video Meeting" badge={activeVideoMeetingRoom ? 1 : 0}>
          <TItem label="Send Meeting Link">
            <IonButtons slot="end">
              <SendButton
                outline
                onClick={async () => {
                  try {
                    await loadingIndicator.create();
                    const { short_url } = await urlShortenerService.shorten(
                      leadVmrLink
                    );
                    appendVideoMeetingLink(short_url);
                  } finally {
                    loadingIndicator.dismiss();
                  }
                }}
              />
            </IonButtons>
          </TItem>
          <TButtonItem
            expand="block"
            color="secondary"
            href={userVmrLink}
            target="_blank"
          >
            <IonIcon icon={videocamOutline} slot="start" />
            Join Video Meeting
          </TButtonItem>
        </TExpandableItemGroup>
      )}
    </>
  );
};

export default LeadVideoMeetingRoom;
