import { Plugins } from '@capacitor/core';
import actionSheet from './action-sheet';
import util from './util';
import browser from './browser';
const { Share } = Plugins;

class Sharing {
  async shareActionSheet(
    url: string,
    title?: string,
    text?: string,
    dialogTitle?: string
  ) {
    const buttons = [
      {
        text: 'Copy Link',
        handler: () => util.copyToClipboard(url)
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
    ] as any[];

    if (url.startsWith('http')) {
      buttons.unshift({
        text: 'Open in Browser',
        handler: () => {
          browser.open(url);
        }
      });
    }

    return await actionSheet.show(buttons);
  }

  async share(
    url: string,
    title?: string,
    text?: string,
    dialogTitle?: string
  ) {
    try {
      return await Share.share({ url, title, text, dialogTitle });
    } catch (e) {
      if (e === 'Web Share API not available') {
        return await this.shareActionSheet(url, title, text, dialogTitle);
      }
    }
  }
}

export default new Sharing();
