import React, { useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import '../styles/components/BlastModals.scss';
import {
  IonList,
  IonButton,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonBadge,
  IonChip,
  IonItem,
  IonRange,
  IonGrid,
  IonRow,
  IonCol,
  NavContext
} from '@ionic/react';
import { TTextItem, TPage } from '../components';
import {
  person,
  time,
  speedometer,
  locate,
  funnel,
  briefcase,
  key,
  card,
  analytics,
  chatbubbles,
  chatboxEllipses,
  image,
  hourglass,
  paperPlane,
  trash,
  archive,
  personCircle,
  checkmarkCircleOutline,
  closeCircle,
  calendar,
  calendarNumber,
  thumbsUp,
  thumbsDown,
  chatbubble,
  business,
  carSport,
  navigateCircle,
  timer,
  cash,
  chevronDown,
  chevronUp,
  idCardOutline,
  codeSlashOutline
} from 'ionicons/icons';
import TDateTimeItem from '../components/TDateTimeItem';
import TSelectItem from '../components/TSelectItem';
import { AppContext } from '../context/AppContext';
import TInputItem from '../components/TInputItem';
import TItem from '../components/TItem';
import ButtonGrid from '../components/ButtonGrid';
import {
  media,
  native,
  util,
  loadingIndicator,
  appNotification
} from '../core';
import TImage from '../components/TImage';
import { blastsService, statusesService, templateSyntaxCheckerService } from '../services';
import { modalController } from '@ionic/core';
import moment from 'moment';
import GiphyPicker from '../components/GiphyPicker';
import AutoReplyModal from '../components/modals/AutoReplyModal';
import { useRouter, useDebounce } from '../hooks';

const AddBlastPage: React.FC = () => {
  const childInputRef = useRef<any>(null);
  const { state: appState } = useContext(AppContext);
  const jobTitleOptions = appState.jobTitles;
  const {
    user,
    clientUsers,
    statuses,
    selectedClientId,
    selectedClient
  } = appState;
  const classificationOptions = [
    'All',
    'Sales',
    'Service',
    'Employment'
  ];

  if (user?.is_staff) {
    classificationOptions.splice( 3, 0, 'Service Canceled');
  }

  const autoReplyPauseDurationChoices = [
    { value: '', text: 'No Pause' },
    { value: 1, text: '1 hour' },
    { value: 3, text: '3 hours' },
    { value: 24, text: '1 day' },
    { value: 72, text: '3 days' },
    { value: 120, text: '5 days' },
    { value: 168, text: '1 week' },
    { value: 336, text: '2 weeks' },
    { value: 720, text: '1 month' },
    { value: 2160, text: '3 months' },
    { value: 4320, text: '6 months' },
    { value: 8760, text: '1 year' },
  ];

  const router = useRouter();
  const blastType = useMemo(() => router.query.type, [router]);
  const presetId = useMemo(() => router.query.presetId, [router]);
  const addType = useMemo(() => router.query.addType, [router]);
  const giphyEnabled = selectedClient.gif_search_enabled;
  const clientLimit = selectedClient.blast_limit;
  const isLeadBlast = blastType === 'leads';
  const navContext = useContext(NavContext);
  const userAssignedToLocked = user && !user.is_client_admin && !user.is_admin;
  const [acknowledgedCost, setAcknowledgedCost] = useState(false);
  const [messageSyntaxValid, setMessageSyntaxValid] = useState(true);
  const [preset, setPreset] = useState<any>();
  const [reachable, setReachable] = useState(0);
  const [statusCounts, setStatusCounts] = useState<any>({});
  const [leadProviders, setLeadProviders] = useState<any>([]);
  const [importedReplied, setImportedReplied] = useState<string>('2');
  const [sendAt, setSendAt] = useState(new Date().toISOString());
  const [sendOver, setSendOver] = useState('1200');
  const [sorting, setSorting] = useState('Oldest First');
  const [messageAge, setMessageAge] = useState(isLeadBlast ? '12' : '30');
  const [limit, setLimit] = useState(clientLimit);
  const [target, setTarget] = useState([1, 2, 23, 21, 11, 12, 35]);
  const [jobTitles, setJobTitles] = useState<any>([]);
  const [vehicleBodies, setVehicleBodies] = useState<any>([]);
  const [models, setModels] = useState();
  const [makes, setMakes] = useState<any>();
  const [vehicleOfInterestMakesModels, setVehicleOfInterestMakesModels] = useState<any>();
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [postalCodeStartsWith, setPostalCodeStartsWith] = useState();
  const [postalCodes, setPostalCodes] = useState();
  const [paymentRangeValue, setPaymentRangeValue] = useState<any>({ upper: undefined, lower: undefined });
  const [termRangeValue, setTermRangeValue] = useState<any>({ upper: undefined, lower: undefined });
  const [aprRangeValue, setAprRangeValue] = useState<any>({ upper: undefined, lower: undefined });
  const [postalCodesOptions, setPostalCodesOptions] = useState([]);
  const [vehicleOfInterestMakesModelsOptions, setVehicleOfInterestMakesModelsOptions] = useState([]);
  const [modelsOptions, setModelsOptions] = useState([]);
  const [makesOptions, setMakesOptions] = useState([]);
  const [leadsCreatedFrom, setLeadsCreatedFrom] = useState();
  const [leadsCreatedTo, setLeadsCreatedTo] = useState();
  const [soldFrom, setSoldFrom] = useState(
    moment()
      .add(-3, 'years')
      .toISOString()
  );
  const [soldTo, setSoldTo] = useState(
    moment()
      .add(-2, 'years')
      .toISOString()
  );
  const [useRobotNameInSignature, setUseRobotNameInSignature] = useState(false);
  const [assignedTo, setAssignedTo] = useState<Array<string|number>>(
    userAssignedToLocked ? [user?.id] : []
  );
  const [message, setMessage] = useState(
    isLeadBlast
      ? 'Are you still looking for a vehicle?'
      : 'Hi {{ first_name }}, this is {{ company_name }}. Do you still have the {{ vehicle }}?'
  );
  const [photo, setPhoto] = useState<any>();
  const [photoPreview, setPhotoPreview] = useState<any>();
  const [classification, setClassification] = useState('Sales');
  const [creditStage, setCreditStage] = useState('');
  const [tradeStage, setTradeStage] = useState('');
  const [vehicleStage, setVehicleStage] = useState('');
  const [apptStage, setApptStage] = useState('');
  const [showGiphyPicker, setShowGiphyPicker] = useState(false);
  const [showAutoReplyModal, setShowAutoReplyModal] = useState(false);
  const [autoReplyIntent, setAutoReplyIntent] = useState('');
  const [positiveAutoReply, setPositiveAutoReply] = useState<any>();
  const [negativeAutoReply, setNegativeAutoReply] = useState<any>();
  const maxMessageLength = 159;
  const canCreate = message.length && reachable > 0 && messageSyntaxValid;
  const onBlastAdded = () => {
    navContext.navigate('../', 'back');
  }

  const handleVariableInsert = (val: string) => {
    if (charsRemaining > 19) {
      childInputRef?.current?.setFocus();
      setMessage(message + ` ${val} `);
    }
  }

  const autoReplyAdded: any = (data: any) => {
    if (data.intent === 'positive') {
      setPositiveAutoReply(data);
    } else {
      setNegativeAutoReply(data);
    }
  }

  const showPositiveAutoReplyModal = (e: any) => {
    e.preventDefault();
    setAutoReplyIntent('positive');
    setShowAutoReplyModal(true);
  }

  const showNegativeAutoReplyModal = (e: any) => {
    e.preventDefault();
    setAutoReplyIntent('negative');
    setShowAutoReplyModal(true);
  }

  const checkSyntax = useDebounce(async () => {
    const res = await templateSyntaxCheckerService.submit(message)
    setMessageSyntaxValid(!!res?.ok);
  }, 250);

  useEffect(() => {
    checkSyntax();
  }, [message, checkSyntax]);

  const showCost = useCallback(async () => {
    if (blastType === 'customers' && !acknowledgedCost) {
      appNotification.alert({
        message: `You will be billed $${selectedClient.dms_blast_price} per SMS message sent.`,
        header: 'Customer Blasts',
        buttons: [
          {
            text: 'Cancel',
            handler() {
              modalController.dismiss();
            }
          },
          {
            text: 'Continue',
            handler() {
              setAcknowledgedCost(true);
            }
          }
        ]
      });
    }
  }, [blastType, acknowledgedCost, selectedClient]);

  const fetchPreset = useCallback(async () => {
    if (isLeadBlast && !!addType && !!presetId) {
      try {
        const res = addType === 'preset' ?
          await blastsService.fetchPreset(blastType, presetId)
          : await blastsService.fetchBlastToDuplicate(blastType, presetId)
        setPreset(res);
      } catch (e) {}
    }
  }, [presetId, blastType, isLeadBlast, addType]);

  useEffect(() => {
    fetchPreset();
  }, [fetchPreset]);

  useEffect(() => {
    showCost();
  }, [showCost]);

  const updatePostalCodes = useCallback(async () => {
    try {
      const res = await blastsService.getDmsPostalCodes(
        selectedClientId ?? 0
      );
      setPostalCodesOptions(res);
    } catch (e) {}
  }, [
    selectedClientId
  ]);
  useEffect(() => {
    if (!isLeadBlast) {
      updatePostalCodes();
    }
  }, [isLeadBlast, updatePostalCodes]);

  const updateMakes = useCallback(async () => {
    try {
      const res = await blastsService.getDmsMakes(
        selectedClientId ?? 0
      );
      setMakesOptions(res);
    } catch (e) {}
  }, [
    selectedClientId
  ]);
  useEffect(() => {
    if (!isLeadBlast) {
      updateMakes();
    }
  }, [isLeadBlast, updateMakes]);

  const updateModels = useCallback(async () => {
    setModels(undefined);
    try {
      const res = await blastsService.getDmsModels(
        selectedClientId ?? 0,
        makes
      );
      setModelsOptions(res);
    } catch (e) {}
  }, [
    makes,
    selectedClientId
  ]);

  useEffect(() => {
    if (!isLeadBlast) {
      updateModels();
    }
  }, [isLeadBlast, updateModels]);

  const updateVehicleOfInterestMakesModelsOptions = useCallback(async () => {
    setVehicleOfInterestMakesModels(undefined);
    try {
      const res = await blastsService.getVehicleOfInterestMakesModels(
        selectedClientId ?? 0
      );
      setVehicleOfInterestMakesModelsOptions(res);
    } catch (e) {}
  }, [
    selectedClientId
  ]);
  useEffect(() => {
    if (isLeadBlast) {
      updateVehicleOfInterestMakesModelsOptions();
    }
  }, [isLeadBlast, updateVehicleOfInterestMakesModelsOptions]);

  const updateAudience = useCallback(async () => {
    try {
      // Commenting this out since adding stage filters
      /*if (target.length === 0) {
        setStatusCounts({});
        setReachable(0);
        return;
      }*/
      const res = await blastsService.getLeadCount(
        selectedClientId ?? 0,
        isLeadBlast,
        {
          messageAge,
          classification,
          tradeStage,
          creditStage,
          vehicleStage,
          apptStage,
          sorting,
          limit,
          target,
          jobTitles,
          vehicleBodies,
          assignedTo,
          leadProviders,
          importedReplied,
          sendAt,
          soldFrom,
          soldTo,
          makes,
          models,
          postalCodes,
          postalCodeStartsWith,
          aprRangeValue,
          termRangeValue,
          paymentRangeValue,
          vehicleOfInterestMakesModels,
          leadsCreatedFrom,
          leadsCreatedTo
        }
      );
      setStatusCounts(res.status_counts);
      setReachable(res.count);
    } catch (e) {}
  }, [
    isLeadBlast,
    selectedClientId,
    messageAge,
    classification,
    tradeStage,
    creditStage,
    vehicleStage,
    apptStage,
    sorting,
    limit,
    target,
    jobTitles,
    vehicleBodies,
    assignedTo,
    leadProviders,
    importedReplied,
    sendAt,
    soldFrom,
    soldTo,
    makes,
    models,
    postalCodes,
    postalCodeStartsWith,
    aprRangeValue,
    termRangeValue,
    paymentRangeValue,
    vehicleOfInterestMakesModels,
    leadsCreatedFrom,
    leadsCreatedTo
  ]);
  useEffect(() => {
    updateAudience();
  }, [updateAudience]);

  const createBlast = async () => {
    if (canCreate) {
      try {
        await loadingIndicator.create();
        const res = await blastsService.create(
          selectedClientId ?? 0,
          isLeadBlast,
          {
            messageAge,
            classification,
            tradeStage,
            creditStage,
            vehicleStage,
            apptStage,
            sorting,
            limit,
            target,
            jobTitles,
            vehicleBodies,
            assignedTo,
            useRobotNameInSignature,
            leadProviders,
            importedReplied,
            sendAt: moment(sendAt).isBefore(moment())
              ? moment()
                  .add(5, 'minutes')
                  .toISOString()
              : sendAt,
            photo,
            sendOver,
            message,
            soldFrom,
            soldTo,
            makes,
            models,
            postalCodes,
            postalCodeStartsWith,
            aprRangeValue,
            termRangeValue,
            paymentRangeValue,
            vehicleOfInterestMakesModels,
            leadsCreatedFrom,
            leadsCreatedTo
          }
        );
        //do create autoReply
        if (positiveAutoReply){
          await blastsService.createAutoReply(selectedClientId, res.id, isLeadBlast, positiveAutoReply);
        }
        if (negativeAutoReply){
          await blastsService.createAutoReply(selectedClientId, res.id, isLeadBlast, negativeAutoReply);
        }
        onBlastAdded();

        appNotification.toast('Blast scheduled successfully.', 'Scheduled');

      } finally {
        loadingIndicator.dismiss();
      }
    } else {
      appNotification.toast(
        'Your reachable audience is 0. Please adjust your filters.',
        'No Reachable Audience'
      );
    }
  };

  const onPresetSelected = () => {
    if (!!preset) {
      setMessage(preset.message);
      setTarget(preset.status);
      if (preset.lead_classification) {
        // only duplicates will have lead_classification, presets do not
        setClassification(preset.lead_classification);
      }
      if (preset.imported_replied) {
        setImportedReplied(preset.imported_replied);
      }
      const positiveAutoReply = !!preset.yes_message ? {
        intent: 'positive',
        message: preset.yes_message,
        pause_duration_hours: preset.yes_pause_duration_hours || '',
        pause_message: preset.yes_pause_message,
        needs_call: preset.yes_needs_call,
        keywords: preset.yes_keywords.split('|'),
        status: preset.yes_status || ''
      } : undefined;
      setPositiveAutoReply(positiveAutoReply);
      const negativeAutoReply = !!preset.no_message ? {
        intent: 'negative',
        message: preset.no_message,
        pause_duration_hours: preset.no_pause_duration_hours || '',
        pause_message: preset.no_pause_message,
        needs_call: preset.no_needs_call,
        keywords: preset.no_keywords.split('|'),
        status: preset.no_status || ''
      } : undefined;
      setNegativeAutoReply(negativeAutoReply);
    }
  };

  useEffect(onPresetSelected, [preset])

  const onFileSelected = async (file: any) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoPreview(reader.result);
        setPhoto(file);
        resolve();
      });

      reader.readAsDataURL(file);
    });
  };

  const captureNative = async () => {
    try {
      const { file, url } = await media.selectPhoto();
      setPhotoPreview(url);
      setPhoto(file);
    } catch (e) {
      console.error(e);
    }
  };

  const attachImage = async () => {
    if (native.isAndroid) {
      captureNative();
    } else {
      const res = await media.selectPhoto();
      onFileSelected(res);
    }
  };

  const attachGiphy = async (e: any) => {
    const imageUrl = e.images.downsized.url;
    const dataUrl = (await util.urlToDataUrl(imageUrl)) as string;
    setPhotoPreview(imageUrl);
    setPhoto(util.dataURItoFile(dataUrl));
    setShowGiphyPicker(false);
  };

  const removeImage = (e: any) => {
    e.stopPropagation();
    setPhotoPreview(null);
    setPhoto(null);
  };

  const charsRemaining = maxMessageLength - message.length;
  const reachableStyle = reachable > 0 ? 'success' : 'danger';
  return (
    <TPage
      customFooterContent={
        <ButtonGrid>
          <IonButton
            disabled={!canCreate}
            color="secondary"
            onClick={createBlast}
            expand="block"
          >
            Add Blast
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList lines="full" className="no-padding">
        <IonItemDivider className="reachable-audience" sticky>
          <IonIcon color={reachableStyle} icon={personCircle} slot="start" />
          <IonLabel color={reachableStyle}>Reachable Audience</IonLabel>
          <IonBadge color={reachableStyle} mode="ios" slot="end">
            {reachable}
          </IonBadge>
        </IonItemDivider>
        <TDateTimeItem
          icon={time}
          label="Send At"
          min={moment()
            .add(-1, 'days')
            .format('YYYY-MM-DD')}
          max="2099"
          displayFormat="MMM DD, YY hh:mma"
          value={sendAt}
          onIonChange={(e: any) => setSendAt(e.detail.value)}
        />
        {isLeadBlast && (
          <>
            <TSelectItem
              label="Classification"
              options={classificationOptions}
              value={classification}
              onChange={(e: any) => setClassification(e.detail.value)}
              icon={briefcase}
            />
            <TSelectItem
              label="Lead Providers"
              placeholder="All"
              multiple
              value={leadProviders}
              onChange={(e: any) => setLeadProviders(e.detail.value)}
              options={[
                ...appState.clientProviders?.map?.(it => ({
                  value: it.id,
                  text: it.name
                }))
              ]}
              icon={paperPlane}
            />
            <TSelectItem
              label="Imported Leads"
              placeholder="All"
              value={importedReplied}
              onChange={(e: any) => setImportedReplied(e.detail.value)}
              options={[
                {
                  value: 'All',
                  text: 'All'
                },{
                  value: '0',
                  text: 'Imported Never Replied'
                },{
                  value: '1',
                  text: 'Imported Replied'
                },{
                  value: '2',
                  text: 'Not Imported + Imported Replied'
                },{
                  value: '3',
                  text: 'Not Imported'
                },
              ]}
              icon={archive}
            />
          </>
        )}
        {isLeadBlast &&
          (userAssignedToLocked ? (
            <TTextItem
              icon={person}
              label="Assigned To"
              text={`${user?.first_name} ${user?.last_name}`}
            />
          ) : (
            <TSelectItem
              label="Assigned To"
              placeholder="All"
              multiple
              value={assignedTo}
              onChange={(e: any) => setAssignedTo(e.detail.value)}
              icon={person}
              options={[
                { value: null, text: 'Unassigned' },
                { value: user?.id, text: 'Me' },
                ...clientUsers
                  ?.filter(it => it.id !== user?.id)
                  ?.map?.(it => ({
                    value: it.id,
                    text: it.full_name
                  }))
              ]}
            />
          )
        )}

        {!isLeadBlast && (
          <>
            <TDateTimeItem
              icon={calendar}
              fromValue={soldFrom}
              toValue={soldTo}
              onFromIonChange={(e: any) => setSoldFrom(e.detail.value)}
              onToIonChange={(e: any) => setSoldTo(e.detail.value)}
              fromMax={new Date().toISOString()}
              toMax={new Date().toISOString()}
              label="Sold Date"
              range
            />
            <TSelectItem
              label="Makes (optional)"
              value={makes}
              onChange={(e: any) => {
                setMakes(e.detail.value);
              }}
              options={[
                ...makesOptions?.map?.(it => ({
                  value: it,
                  text: it
                }))
              ]}
              icon={business}
              multiple
            />
            <TSelectItem
              label="Models (optional)"
              value={models}
              onChange={(e: any) => setModels(e.detail.value)}
              options={[
                ...modelsOptions?.map?.(it => ({
                  value: it,
                  text: it
                }))
              ]}
              icon={carSport}
              multiple
            />
            <TSelectItem
              label="ZIPs (optional)"
              value={postalCodes}
              onChange={(e: any) => {
                setPostalCodes(e.detail.value);
              }}
              options={[
                ...postalCodesOptions?.map?.(it => ({
                  value: it,
                  text: it
                }))
              ]}
              icon={locate}
              multiple
            />
            <TItem
              icon={analytics}
              label={'APR ' +
                (!!aprRangeValue.upper ? `${aprRangeValue.lower}-${aprRangeValue.upper}%` : '')
              }
            >
              <IonRange dualKnobs={true} min={0} max={28} step={1} snaps={true} pin={true} onIonChange={e => setAprRangeValue(e.detail.value as any)} />
            </TItem>
            <TItem
              icon={cash}
              label={'Payment ' +
                (!!paymentRangeValue.upper ? `$${paymentRangeValue.lower}-$${paymentRangeValue.upper}` : '')
              }
            >
              <IonRange dualKnobs={true} min={0} max={3000} step={50} snaps={true} pin={true} onIonChange={e => setPaymentRangeValue(e.detail.value as any)} />
            </TItem>
            <TItem
              icon={calendarNumber}
              label={'Term ' +
                (!!termRangeValue.upper ? `${termRangeValue.lower}-${termRangeValue.upper}` : '')
              }
            >
              <IonRange dualKnobs={true} min={0} max={84} step={12} snaps={true} pin={true} onIonChange={e => setTermRangeValue(e.detail.value as any)} />
            </TItem>
            <TInputItem
              icon={navigateCircle}
              wrapLabel
              label="ZIP Starts With (optional)"
              value={postalCodeStartsWith}
              onChange={(e: any) => setPostalCodeStartsWith(e.detail.value)}
            />
          </>
        )}

        <TItem icon={image} wrapLabel>
          <IonLabel>Image</IonLabel>
          {photo && <TImage src={photoPreview} alt="Attached Image" />}
          {photo && (
            <IonButton
              slot="end"
              color="danger"
              fill="clear"
              onClick={removeImage}
            >
              <IonIcon slot="icon-only" icon={trash} />
            </IonButton>
          )}
          {!photo && (
            <>
              <IonButton slot="end" color="secondary" onClick={attachImage}>
                Attach Image
              </IonButton>
              {giphyEnabled && (
                <IonButton
                  slot="end"
                  color="secondary"
                  onClick={() => setShowGiphyPicker(true)}
                >
                  Attach GIF
                </IonButton>
              )}
            </>
          )}
        </TItem>
        <TItem icon={codeSlashOutline}>
          <IonLabel>Insert</IonLabel>
          <IonButton
            color="secondary"
            onClick={() => handleVariableInsert('{{ first_name }}')}
          >
            First Name
          </IonButton>
          <IonButton
            color="secondary"
            onClick={() => handleVariableInsert('{{ last_name }}')}
          >
            Last Name
          </IonButton>
          <IonButton
            color="secondary"
            onClick={() => handleVariableInsert('{{ company_name }}')}
          >
            Company Name
          </IonButton>
          <IonButton
            color="secondary"
            onClick={() => handleVariableInsert('{{ credit_app_link }} PIN: {{ pin }}')}
          >
            Credit App
          </IonButton>
          <IonButton
            color="secondary"
            onClick={() => handleVariableInsert('{{ short_credit_app_link }} PIN: {{ pin }}')}
          >
            Short Credit App
          </IonButton>
          {selectedClient.soft_pulls && (
            <IonButton
              color="secondary"
              onClick={() => handleVariableInsert('{{ soft_pull_link }} PIN: {{ pin }}')}
            >
              Soft Pull
            </IonButton>
          )}
          <IonButton
            color="secondary"
            onClick={() => handleVariableInsert('{{ assignee }}')}
          >
            Assignee
          </IonButton>
          {!isLeadBlast && (
            <>
              <IonButton
                color="secondary"
                onClick={() => handleVariableInsert('{{ vehicle }}')}
              >
                Vehicle
              </IonButton>
              <IonButton
                color="secondary"
                onClick={() => handleVariableInsert('{{ apr }}')}
              >
                APR
              </IonButton>
              <IonButton
                color="secondary"
                onClick={() => handleVariableInsert('{{ survey_link }}')}
              >
                Survey
              </IonButton>
              <IonButton
                color="secondary"
                onClick={() => handleVariableInsert('{{ vin_last_8 }}')}
              >
                VIN last 8
              </IonButton>

            </>
          )}
        </TItem>
        <TInputItem
          childInputRef={childInputRef}
          icon={chatbubbles}
          label={`Message ${charsRemaining} (${util.getPluralizedString(
            'character',
            charsRemaining
          )} remaining)`}
          labelPosition="stacked"
          value={message}
          disabled={!!preset}
          multiline
          maxlength={maxMessageLength}
          onChange={(e: any) => setMessage(e.detail.value)}
        />
        {!messageSyntaxValid && (
          <IonItemDivider color="danger">
            The message syntax is invalid.
          </IonItemDivider>
        )}
        <TItem
          icon={thumbsUp}
          wrapLabel
        >
          <IonLabel>
            {!positiveAutoReply && (
              <>Positive Auto Reply</>
            )}
            {positiveAutoReply?.message && (
              <IonChip>
                <IonIcon color="primary" icon={chatbubble} />
                <IonLabel>
                  {positiveAutoReply.message}
                </IonLabel>
              </IonChip>
            )}
            {positiveAutoReply?.status && (
              <IonChip>
                <IonIcon color="primary" icon={checkmarkCircleOutline} />
                <IonLabel>
                  Set status
                </IonLabel>
                <IonBadge mode="ios" color="primary">
                  {statuses.find((s: any) => s.id === positiveAutoReply.status)?.option_name}
                </IonBadge>
              </IonChip>
            )}
            {positiveAutoReply?.pause_duration_hours && (
              <IonChip>
                <IonIcon color="primary" icon={timer} />
                <IonLabel>
                  Pause for
                </IonLabel>
                <IonBadge mode="ios" color="primary">
                  {autoReplyPauseDurationChoices.find((s: any) => s.value === positiveAutoReply.pause_duration_hours)?.text}
                </IonBadge>
              </IonChip>
            )}
            {positiveAutoReply?.pause_message && (
              <IonChip>
                <IonIcon color="primary" icon={chatboxEllipses} />
                <IonLabel>
                  {positiveAutoReply.pause_message}
                </IonLabel>
              </IonChip>
            )}
          </IonLabel>
          {positiveAutoReply && (
            <IonButton
              slot="end"
              color="danger"
              fill="clear"
              onClick={() => setPositiveAutoReply(undefined)}
            >
              <IonIcon slot="icon-only" icon={trash} />
            </IonButton>
          )}

          {!positiveAutoReply && (
            <IonButton
              slot="end"
              color="secondary"
              onClick={showPositiveAutoReplyModal}
            >
              Add Positive Auto Reply
            </IonButton>
          )}
        </TItem>
        <TItem
          icon={thumbsDown}
          wrapLabel
        >
          <IonLabel>
            {!negativeAutoReply && (
              <>Negative Auto Reply</>
            )}
            {negativeAutoReply?.message && (
              <IonChip>
                <IonIcon color="primary" icon={chatbubble} />
                <IonLabel>
                  {negativeAutoReply.message}
                </IonLabel>
              </IonChip>
            )}
            {negativeAutoReply?.status && (
              <IonChip>
                <IonIcon color="primary" icon={checkmarkCircleOutline} />
                <IonLabel>
                  Set status
                </IonLabel>
                <IonBadge mode="ios" color="primary">
                  {statuses.find((s: any) => s.id === negativeAutoReply.status)?.option_name}
                </IonBadge>
              </IonChip>
            )}
            {negativeAutoReply?.pause_duration_hours && (
              <IonChip>
                <IonIcon color="primary" icon={timer} />
                <IonLabel>
                  Pause for
                </IonLabel>
                <IonBadge mode="ios" color="primary">
                  {autoReplyPauseDurationChoices.find((s: any) => s.value === negativeAutoReply.pause_duration_hours)?.text}
                </IonBadge>
              </IonChip>
            )}
            {negativeAutoReply?.pause_message && (
              <IonChip>
                <IonIcon color="primary" icon={chatboxEllipses} />
                <IonLabel>
                  {negativeAutoReply.pause_message}
                </IonLabel>
              </IonChip>
            )}
          </IonLabel>
          {negativeAutoReply && (
            <IonButton
              slot="end"
              color="danger"
              fill="clear"
              onClick={() => setNegativeAutoReply(undefined)}
            >
              <IonIcon slot="icon-only" icon={trash} />
            </IonButton>
          )}

          {!negativeAutoReply && (
            <IonButton
              slot="end"
              color="secondary"
              onClick={showNegativeAutoReplyModal}
            >
              Add Negative Auto Reply
            </IonButton>
          )}
        </TItem>

        <IonItemDivider
          className="pointer"
          onClick={()=>setShowAdvancedOptions(!showAdvancedOptions)}
        >
          <IonIcon
            icon={showAdvancedOptions ? chevronUp : chevronDown}
            slot="start"
          />
          {showAdvancedOptions ? 'Hide' : 'Show'} Advanced Options
        </IonItemDivider>
        {showAdvancedOptions && (
          <>
            <TInputItem
              icon={speedometer}
              label="Limit"
              value={limit}
              type="number"
              max={clientLimit}
              onChange={(e: any) => setLimit(e.detail.value)}
              onBlur={(e: any) =>
                setLimit(
                  Math.min(
                    parseInt(e.detail.value.replace(/\D+/g, '') ?? '0'),
                    clientLimit
                  ).toString()
                )
              }
            />
            {user?.is_admin && (
              <TInputItem
                icon={hourglass}
                label="Send Over"
                type="number"
                value={sendOver}
                onChange={(e: any) => setSendOver(e.detail.value)}
              />
            )}
            {isLeadBlast && (
              <TDateTimeItem
                icon={calendar}
                fromValue={leadsCreatedFrom}
                toValue={leadsCreatedTo}
                onFromIonChange={(e: any) => setLeadsCreatedFrom(e.detail.value)}
                onToIonChange={(e: any) => setLeadsCreatedTo(e.detail.value)}
                fromMax={new Date().toISOString()}
                toMax={new Date().toISOString()}
                label="Leads Created Range"
                range
              />
            )}
            {isLeadBlast && user?.is_staff && (
              <TSelectItem
                label="Job Titles"
                value={jobTitles}
                icon={idCardOutline}
                selectedText={`${jobTitles?.length ?? 0} Selected`}
                onChange={(e: any) => setJobTitles(e.detail.value)}
                multiple
                options={jobTitleOptions
                  ?.map?.(it => ({
                    value: it.id,
                    text: it.name
                  }))}
              />
            )}
            <TSelectItem
              label="Signature"
              value={useRobotNameInSignature}
              onChange={(e: any) => setUseRobotNameInSignature(e.detail.value)}
              icon={person}
              options={[
                { value: false, text: 'Use assignee name' },
                { value: true, text: 'Use robot name' },
              ]}
            />
            {isLeadBlast && (
              <TSelectItem
                label="Vehicle Models (of interest)"
                value={vehicleOfInterestMakesModels}
                onChange={(e: any) => setVehicleOfInterestMakesModels(e.detail.value)}
                placeholder="0 Selected"
                options={[
                  ...vehicleOfInterestMakesModelsOptions?.map?.((it: any) => ({
                    value: it,
                    text: `${it.make_name} ${it.model_name}`
                  }))
                ]}
                icon={carSport}
                multiple
              />
            )}
            {isLeadBlast && (
              <TSelectItem
                label="Body Types (of interest)"
                value={vehicleBodies}
                icon={carSport}
                placeholder="0 Selected"
                onChange={(e: any) => setVehicleBodies(e.detail.value)}
                multiple
                options={[
                  'CONVERTIBLE',
                  'COUPE',
                  'HATCHBACK',
                  'MINIVAN',
                  'TRUCK',
                  'SUV',
                  'SEDAN',
                  'VAN',
                  'WAGON',
                  'CROSSOVER',
                  'OTHER'
                ]}
              />
            )}
            {isLeadBlast && (
              <TSelectItem
                label="Targeting"
                value={target}
                icon={locate}
                selectedText={`${target?.length ?? 0} Selected`}
                onChange={(e: any) => setTarget(e.detail.value)}
                multiple
                options={statuses
                  ?.filter?.(
                    (it: any) =>
                      statusesService.ineligibleBlastStatuses.indexOf(it.id) === -1
                  )
                  .map?.(it => ({
                    value: it.id,
                    text: it.option_name
                  }))}
              />
            )}
            {isLeadBlast && !userAssignedToLocked && target?.length > 0 && (
              <IonItem className="status-chips">
                <IonGrid>
                  <IonRow>
                    {target?.map?.((id: any) => (
                      <IonCol
                        sizeLg="6"
                        sizeMd="6"
                        sizeXs="12"
                        sizeXl="4"
                        sizeSm="12"
                        key={id}
                      >
                        <IonChip>
                          <IonIcon color="primary" icon={checkmarkCircleOutline} />
                          <IonLabel>
                            {statuses.find((s: any) => s.id === id)?.option_name}
                          </IonLabel>
                          <IonBadge mode="ios" color="primary">
                            {statusCounts?.[id] ?? 0}
                          </IonBadge>
                          <IonIcon
                            color="primary"
                            icon={closeCircle}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setTarget((t: any) => t.filter((i: any) => i !== id));
                            }}
                          />
                        </IonChip>
                      </IonCol>
                    ))}
                  </IonRow>
                </IonGrid>
              </IonItem>
            )}
            {isLeadBlast && (
              <>
                <TSelectItem
                  label="Trade Stage"
                  options={[{ value: '', text: 'Any' }, 'needs', 'has', 'no-need', 'has/no-need']}
                  value={tradeStage}
                  onChange={(e: any) => setTradeStage(e.detail.value)}
                  icon={key}
                />
                <TSelectItem
                  label="Credit Stage"
                  options={[{ value: '', text: 'Any' }, 'needs', 'has', 'no-need', 'has/no-need']}
                  value={creditStage}
                  onChange={(e: any) => setCreditStage(e.detail.value)}
                  icon={card}
                />
                <TSelectItem
                  label="Vehicle Stage"
                  options={[{ value: '', text: 'Any' }, 'needs', 'has']}
                  value={vehicleStage}
                  onChange={(e: any) => setVehicleStage(e.detail.value)}
                  icon={carSport}
                />
                <TSelectItem
                  label="Appointment Stage"
                  options={[{ value: '', text: 'Any' }, 'needs', 'has']}
                  value={apptStage}
                  onChange={(e: any) => setApptStage(e.detail.value)}
                  icon={calendar}
                />
              </>
            )}
            {isLeadBlast && (
              <TSelectItem
                label="Message Order"
                icon={funnel}
                value={sorting}
                onChange={(e: any) => setSorting(e.detail.value)}
                options={['Newest First', 'Oldest First']}
              />
            )}
            <TInputItem
              icon={time}
              wrapLabel
              type="number"
              label={`Last Message Age (${isLeadBlast ? 'Hours' : 'Days'})`}
              value={messageAge}
              onChange={(e: any) => setMessageAge(e.detail.value)}
            />
          </>
        )}
      </IonList>
      {showGiphyPicker && (
        <GiphyPicker
          isOpen={true}
          onSelected={attachGiphy}
          onDidDismiss={() => setShowGiphyPicker(false)}
        />
      )}
      {showAutoReplyModal && (
        <AutoReplyModal
          onDidDismiss={()=> {setShowAutoReplyModal(false)}}
          callBack={autoReplyAdded}
          intent={autoReplyIntent}
          pauseDurationChoices={autoReplyPauseDurationChoices}
        />
      )}
    </TPage>
  );
};

export default AddBlastPage;
