import { http } from '../core';
import CachedService from './cached-service';

class ClientService extends CachedService {
  constructor() {
    super('selectedClient', 'selectedClient', []);
  }

  async request(clientId: number) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/`
    });

    return res.data;
  }

  async update(clientId: any, changes: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/`,
        data: changes
      });

      this.evict();

      return data;
    } catch (e) {
      http.onHttpError(e);
      throw e;
    }
  }

  async uploadDocument(clientId: number, key: string, file: File) {
    try {
      const formData = new FormData();
      formData.append(key, file);
      const { data } = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/`,
        data: formData
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  isForceClockin(client: any) {
    return client?.force_clock_in !== null;
  }
  isRoundRobin(client: any) {
    return client?.delivery_type === 'round_robin';
  }
}

export default new ClientService();
