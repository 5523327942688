import { IonInput, IonTextarea } from '@ionic/react';
import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { util } from '../core';
import Inputmask from 'inputmask';
import {
  AutoCompleteResultsProps,
  default as AutoCompleteResults
} from './AutoCompleteResults';
import { usePortal, useEventListener } from '../hooks';
Inputmask.extendAliases({
  email: {
    mask: '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]'
  }
});

type CombinedProps = React.ComponentProps<typeof IonInput> &
  AutoCompleteResultsProps;

export interface TInputProps extends CombinedProps {
  valueChanged?: (e: any) => void;
  childInputRef?: any;
  onEnterPressed?: (e: any) => void;

  multiline?: boolean;
  autoGrow?: boolean;
  autoGrowMaxHeight?: number;
  mask?: string;
  maskPlaceholder?: string;
  valueModifier?: any;
  autoComplete?: string;
}

const TInput: React.FC<TInputProps> = props => {
  const {
    onEnterPressed,
    valueChanged,
    childInputRef,
    multiline,
    autoGrow,
    onKeyDown,
    autoGrowMaxHeight,
    onIonChange,
    mask,
    maskPlaceholder,
    valueModifier,
    results,
    onSelected,
    onSelectedIndexChanged,
    renderText,
    displayKey,
    alignment,
    ...rest
  } = props;
  const autoCompleteProps = {
    results,
    onSelected,
    onSelectedIndexChanged,
    renderText,
    displayKey,
    alignment
  };
  const fakeInput = useRef<any>();
  const input = childInputRef ?? useRef<any>();
  const inputMask = useRef<any>();
  const portal = usePortal();
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [dropDownTransform, setDropDownTransform] = useState<any>();
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEventListener('keyboardWillShow', ({ detail }: any) =>
    setKeyboardHeight(detail?.keyboardHeight ?? 0)
  );
  useEventListener('keyboardWillHide', () => setKeyboardHeight(0));

  const [height, setHeight] = useState<number | string | undefined>(undefined);
  const keyDown = (e: any) => {
    if (util.isEnterKey(e) && onEnterPressed) {
      e.preventDefault();
      onEnterPressed(e);
    }

    if (onKeyDown instanceof Function) {
      onKeyDown(e);
    }
  };

  const positionAutoComplete = useCallback(() => {
    const rect = input.current?.getBoundingClientRect?.() ?? {};
    const { x = 0, y = 0, height = 0, width = 0 } = rect;
    let top = multiline ? y + fakeInput.current?.clientHeight ?? 0 : y + height;
    const ddHeight = 350;

    if (top + ddHeight >= window.innerHeight - keyboardHeight) {
      top = y;
      if (top - ddHeight > 0) {
        setDropDownTransform('translateY(-100%)');
      }
    } else {
      setDropDownTransform(undefined);
    }
    setTop(top);
    setLeft(alignment === 'end' ? x + width : x);
  }, [input, alignment, multiline, fakeInput, keyboardHeight]);

  useLayoutEffect(positionAutoComplete, [results]);

  const ionChange = (e: any) => {
    onIonChange?.(e);
    valueChanged?.(e.detail.value);

    if (autoGrow) {
      const target = e.target.querySelector('.native-textarea');

      target.style.height = '40px';
      const height = target.scrollHeight;

      const effectiveHeight = autoGrowMaxHeight
        ? Math.min(height, autoGrowMaxHeight)
        : height;

      setHeight(effectiveHeight);
      target.style.height = `${effectiveHeight}px`;
    }
  };

  useLayoutEffect(() => {
    if (mask && input.current) {
      input.current.getInputElement().then((element: any) => {
        const opts = ['email'].indexOf(mask) > -1 ? { alias: mask } : { mask };
        inputMask.current = Inputmask(
          Object.assign(opts, { jitMasking: true })
        ).mask(element);
      });
    }
  }, [input, mask]);

  const Component = multiline ? IonTextarea : IonInput;

  return (
    <Component
      ref={input}
      className="t-input"
      onKeyDown={keyDown}
      onIonChange={ionChange}
      style={{ height }}
      {...(rest as any)}
    >
      {results &&
        results.length > 0 &&
        ReactDOM.createPortal(
          <AutoCompleteResults
            style={{
              top,
              left,
              transform: dropDownTransform
            }}
            visible
            {...autoCompleteProps}
          />,
          portal
        )}

      <div
        ref={fakeInput}
        style={{
          pointerEvents: 'none',
          zIndex: 0,
          position: 'absolute',
          top: 0,
          padding: '10px 18px',
          height: 'auto',
          left: 0,
          right: 0,
          opacity: 0
        }}
      >
        {props.value}
      </div>
    </Component>
  );
};

export default TInput;
