import React, { useCallback, useContext, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { TChart, MetricCard, TReportsPage } from '../components';
import { LeadListModal } from '../components/modals';
import { reportsService, needsCallService } from '../services';
import '../styles/Reports.scss';
import {
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonLabel,
  IonBadge,
  IonList
} from '@ionic/react';

const tooltips = {
  callbacks: {
    // We want percentages not numbers in the tooltips
    label: (tooltipItem: any, data: any) => {
      //get the concerned dataset
      const dataset = data.datasets[tooltipItem.datasetIndex];
      //calculate the total of this data set
      const total = dataset.data.reduce((previousValue: any, currentValue: any, currentIndex: any) => {
        return previousValue + currentValue;
      });
      //get the current items value
      const currentValue = dataset.data[tooltipItem.index];
      //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
      const percentage = Math.floor(((currentValue/total) * 100)+0.5);

      return `${percentage}%`;
    }
  }
}

const KPIs: React.FC = () => {
  const [modalData, setModalData] = useState<any>();
  const { state } = useContext(AppContext);
  // Used for is_full_service hide avg response time chart
  const { selectedClient } = state;
  const loadData = useCallback(
    async ({ since, until, clientId, filters }: any) => {
      const { classification, leadProvider, excludeSaleAppointments } = filters;
      const [app, ninja, dealer, needsCall] = await Promise.all([
        reportsService.retrieveTeamActivityReport(
          clientId,
          since,
          until,
          classification,
          excludeSaleAppointments,
          leadProvider
        ),
        reportsService.retrieveSMSCounts(
          clientId,
          since,
          until,
          true,
          classification,
          undefined,
          leadProvider
        ),
        reportsService.retrieveSMSCounts(
          clientId,
          since,
          until,
          false,
          classification,
          undefined,
          leadProvider
        ),
        needsCallService.retrieveReport(
          since,
          until,
          clientId,
          false
        )
      ]);
      const needsCallReport = needsCallService.aggregateReport(needsCall, state.clients);
      return {
        app,
        ninja,
        dealer,
        needsCallReport
      };
    },
    [state.clients]
  );

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ showLeadProvider: true, showExcludeSaleAppointments: true }}
    >
      {({ state }) => {
        const { data } = state;
        const responseTime = Math.round(data?.dealer?.avg_response_time / 60);
        const soldUnits = data?.app?.appointments?.sold;
        const soldLeads = data?.app?.appointments?.sold_leads;
        const charts = [
          <TChart
            type="pie"
            title="Dealer Engagement"
            options={{tooltips}}
            data={[data?.dealer?.sms_count, data?.ninja?.sms_count]}
            labels={['Dealer', 'Ninjas']}
            legend
          >
            <div className="chart-label">
              {Math.round(
                (data?.dealer?.sms_count /
                  (data?.dealer?.sms_count + data?.ninja?.sms_count)) *
                  100
              )}
              %
            </div>
          </TChart>,
          <MetricCard
            title="Avg Response Time"
            value={responseTime}
            uom="Minute"
          />,
          <MetricCard
            title="Avg Needs Call Wait"
            value={new Date(data.needsCallReport.avgWaitDuraton * 1000).toISOString().substr(11, 8)}
            uom=""
          />,
          <MetricCard title="Sold Units" value={soldUnits} uom="Unit" leads={soldLeads} />,
          <TChart
            type="gauge"
            title="Engagement Rate"
            value={Math.round(
              (data?.app?.new_leads?.engaged / data?.app?.new_leads?.total) *
                100
            )}
          >
            <IonList>
              <IonItem>
                <IonLabel>Engaged Leads</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.new_leads?.engaged_leads) {
                      setModalData({
                        leadsCount: data?.app?.new_leads?.engaged,
                        title: "Engaged Leads",
                        leads: data?.app?.new_leads?.engaged_leads
                      })
                    }
                  }}
                  style={{cursor: data?.app?.new_leads?.engaged_leads ? 'pointer' : 'default'}}
                  mode="ios"
                >
                  {data?.app?.new_leads?.engaged}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>New Leads</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.new_leads?.total_leads) {
                      setModalData({
                        leadsCount: data?.app?.new_leads?.total,
                        title: "New Leads",
                        leads: data?.app?.new_leads?.total_leads
                      })
                    }
                  }}
                  style={{cursor: data?.app?.new_leads?.total_leads ? 'pointer' : 'default'}}
                  mode="ios"
                >
                  {data?.app?.new_leads?.total}
                </IonBadge>
              </IonItem>
            </IonList>
          </TChart>,
          <TChart
            type="gauge"
            title="Appt Set Rate"
            value={Math.round(
              (data?.app?.appointments?.total / data?.app?.new_leads?.engaged) *
                100
            )}
          >
            <IonList>
              <IonItem>
                <IonLabel>Appointments Set</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.appointments?.total_leads) {
                      setModalData({
                        leadsCount: data?.app?.appointments?.total,
                        title: "Appointments Set",
                        leads: data?.app?.appointments?.total_leads
                      })
                    }
                  }}
                  style={{cursor: data?.app?.appointments?.total_leads ? 'pointer' : 'default'}}
                  mode="ios"
                >
                  {data?.app?.appointments?.total}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Engaged Leads</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.new_leads?.engaged_leads) {
                      setModalData({
                        leadsCount: data?.app?.new_leads?.engaged,
                        title: "Engaged Leads",
                        leads: data?.app?.new_leads?.engaged_leads
                      })
                    }

                  }}
                  style={{cursor: data?.app?.new_leads?.engaged_leads ? 'pointer' : 'default'}}
                  mode="ios"
                >
                  {data?.app?.new_leads?.engaged}
                </IonBadge>
              </IonItem>
            </IonList>
          </TChart>,
          <TChart
            type="gauge"
            title="Appt Show Rate"
            value={Math.round(
              (data?.app?.appointments?.shows /
                data?.app?.appointments?.total) *
                100
            )}
          >
            <IonList>
              <IonItem>
                <IonLabel>Appointments Showed</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.appointments?.shows_leads) {
                      setModalData({
                        leadsCount: data?.app?.appointments?.shows,
                        title: "Appointments Showed",
                        leads: data?.app?.appointments?.shows_leads
                      })
                    }
                  }}
                  style={{cursor: data?.app?.appointments?.shows_leads ? 'pointer' : 'default'}}
                  mode="ios"
                >
                  {data?.app?.appointments?.shows}
                </IonBadge>
              </IonItem>
              <IonItem>
                <IonLabel>Appointments Set</IonLabel>
                <IonBadge
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (data?.app?.appointments?.total_leads) {
                      setModalData({
                        leadsCount: data?.app?.appointments?.total,
                        title: "Appointments Set",
                        leads: data?.app?.appointments?.total_leads
                      })
                    }
                  }}
                  style={{cursor: data?.app?.appointments?.total_leads ? 'pointer' : 'default'}}
                  mode="ios"
                >
                  {data?.app?.appointments?.total}
                </IonBadge>
              </IonItem>
            </IonList>
          </TChart>
        ];

        // Remove charts[1] avg response time if client is_full_service
        if (selectedClient.is_full_service) {
          charts.splice(1,1);
        }

        return (
          <IonGrid className="reports">
            <IonRow>
              {charts.map((it, i) => (
                <IonCol
                  key={i}
                  sizeLg={(charts.length === 7 && i === 0) ? "12" : "4"}
                  sizeXl={(charts.length === 7 && i === 0) ? "12" : "4"}
                  sizeXs="12"
                  sizeSm={(charts.length === 7 && i === 0) ? "12" : "6"}
                  sizeMd={(charts.length === 7 && i === 0) ? "12" : "4"}
                >
                  {it}
                </IonCol>
              ))}
            </IonRow>
            {modalData && (
              <LeadListModal
                isOpen={true}
                leads={modalData.leads}
                title={modalData.title}
                leadsCount={modalData.leadsCount}
                onDidDismiss={() => {setModalData(undefined)}}
              />
            )}
          </IonGrid>
        );
      }}
    </TReportsPage>
  );
};

export default KPIs;
