import PagedService from './paged-service';
import { http } from '../core';

class UserWatchersService extends PagedService {

  async list() {
    try {
      const { data } = await http.authorizedRequest({
        url: `/user-watchers/`,
        type: 'user-watchers',
        exclusive: true
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async delete(id: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/user-watchers/${id}/`
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(lead_id: number, notifications: boolean) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/user-watchers/`,
        data: {notifications, lead_id}
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}
export default new UserWatchersService();
