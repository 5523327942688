import { http } from '../core';

class PhoneNumberLookupService {
  async lookup(clientId: number, phoneNumber: string) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        data: { phone_number: phoneNumber },
        url: `/clients/${clientId}/lookup-phone/`,
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new PhoneNumberLookupService();
