import React, { useContext, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonPopover, IonIcon } from '@ionic/react';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';
import { closeCircle, playCircleOutline } from 'ionicons/icons';
import RippleContainer from './RippleContainer';

const PersonalizedLeadVideos: React.FC = () => {
  const { state } = useContext(PersonalizedLeadContext);
  const { lead } = state;
  const [video, setVideo] = useState<any>();

  if (!lead?.videos?.length) {
    return null;
  }

  return (
    <>
      <h2>Videos</h2>
      <IonGrid className="personalized-videos">
        <IonRow>
          {lead.videos.map((vid: any) => (
            <IonCol key={vid.id} size="4" className="personalized-video">
              <RippleContainer>
                <img
                  src={vid.video_thumb}
                  onClick={() => setVideo(vid)}
                  alt="Video Thumbnail"
                />
                <IonIcon icon={playCircleOutline} className="play-button" />
              </RippleContainer>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      {video && (
        <IonPopover
          cssClass="large-video"
          isOpen={true}
          onDidDismiss={() => setVideo(null)}
        >
          <video
            src={video.mp4_file}
            poster={video.video_thumb}
            autoPlay
            controls
          />
          <IonIcon icon={closeCircle} onClick={() => setVideo(null)} />
        </IonPopover>
      )}
    </>
  );
};

export default PersonalizedLeadVideos;
