import React, { useCallback } from 'react';
import { TInfiniteScrollPage, ChatBubble } from '../components';
import { contestantDetailsService } from '../services';
import { IonItemDivider } from '@ionic/react';
import { useDataLoader } from '../hooks';
import { util } from '../core';
import { useRouter } from '../hooks';
import moment from 'moment';
const ContestantDetailsItems: React.FC = () => {
  const router = useRouter();
  const {contestantId, objectType} = router.query;
  const loadContestantDetails = useCallback(async () => {
    return await contestantDetailsService.list(contestantId, objectType);
  }, [contestantId, objectType]);

  const { next, data, error, loading, loadNext } = useDataLoader(
    loadContestantDetails,
    true
  );

  const renderChatBubble = (it: any) => {
    if (!data) {
      return null;
    }

    const index = data.indexOf(it);
    const previousItem = index > 0 ? data[index - 1] : null;
    let heading: any = null;
    let {
      id,
      client,
      lead,
      message,
      sender_name,
      sent_by_ninja,
      sent_by_1099,
      sms_type,
      smsmedia_set,
      created,
      media,
      content_type,
      read_at,
      gif_url,
      video_thumb,
      text_ninja,
      twilio_status,
      iframe_tag,
      call,
      resetPause,
      language,
      english_translation,
      video_meeting_status
    } = it;

    const format = 'dddd, MMMM D, YYYY';
    const mc = moment(created).format(format);
    const pc = previousItem ? moment(previousItem.created).format(format) : '';

    const mediaSet = [].concat(smsmedia_set || []) as any[];

    if (media && content_type) {
      mediaSet.push({
        id,
        media: media,
        content_type
      });
    }

    if (mc !== pc) {
      heading = (
        <IonItemDivider key={mc} sticky>
          {mc}
        </IonItemDivider>
      );
    }

    const outgoing = ['outbound', 'system'].indexOf(sms_type) > -1;

    if (!sender_name && !outgoing) {
      sender_name = 'Unknown'
    }

    const bubble = (
      <ChatBubble
        id={id}
        key={id}
        mediaSet={mediaSet}
        message={message}
        sender={sender_name}
        ninja={sent_by_ninja}
        senderIs1099={sent_by_1099}
        read={!!read_at}
        adPreview={iframe_tag}
        call={call}
        videoThumb={video_thumb}
        gif={gif_url}
        translation={english_translation}
        videoMeetingStatus={video_meeting_status}
        language={language}
        dateSent={util.formatMessageDate(created)}
        outgoing={outgoing}
        yellow={!!text_ninja}
        orange={!!text_ninja && (message.includes('needs a call') || message.includes('Needs call set to true'))}
        internal={!!text_ninja}
        resetPause={resetPause}
        twilioStatus={twilio_status}
        linkToConversation={`/text-messages/conversation/${client}/${lead}/`}
      />
    );
    return heading ? [heading, bubble] : bubble;
  };

  return (
    <TInfiniteScrollPage
      contentId="conversationContent"
      loading={loading}
      className="text-message-thread"
      items={data ?? []}
      hasNext={!loading && !!next}
      error={error}
      onLoadNextPage={loadNext}
      renderItem={renderChatBubble}
    />
  );
};

export default ContestantDetailsItems;
