import { IonSearchbar } from '@ionic/react';
import React, { useImperativeHandle, useRef, forwardRef } from 'react';
import { isPlatform } from '@ionic/core';
import { util } from '../core';

export interface TSearchBarProps
  extends React.ComponentProps<typeof IonSearchbar> {
  onSearch: (val: string) => any;
  valueChanged?: (val: string) => any;
}

const TSearchBar: React.FC<TSearchBarProps> = (
  { onKeyPress, onSearch, valueChanged, children, ...rest },
  ref
) => {
  const inputRef = useRef<any>();
  useImperativeHandle(ref, () => ({
    setFocus: async () => await inputRef.current?.setFocus?.(),
    get value() {
      return inputRef.current?.value;
    }
  }));

  const keyPress = (e: any) => {
    if (util.isEnterKey(e)) {
      onSearch?.(e.target.value);
    }

    if (onKeyPress instanceof Function) {
      onKeyPress(e);
    }
  };

  const ionChange = (e: CustomEvent) => {
    valueChanged?.(e.detail.value);
  };

  const onClear = () => {
    onSearch?.('');
  };

  return (
    <IonSearchbar
      ref={inputRef}
      mode={isPlatform('android') ? 'md' : 'ios'}
      style={{ margin: 0, padding: 0 }}
      onIonClear={onClear}
      onKeyPress={keyPress}
      onIonChange={ionChange}
      {...rest}
    >
      {children}
    </IonSearchbar>
  );
};

export default forwardRef(TSearchBar);
