import React, { useState, useContext } from 'react';
import { IonPopover, IonList, IonButton, IonIcon, IonRange } from '@ionic/react';
import TSelectItem from './TSelectItem';
import ButtonGrid from './ButtonGrid';
import TDateTimeItem from './TDateTimeItem';
import TItem from './TItem';
import { compass, paperPlane, personCircle } from 'ionicons/icons';
import '../styles/components/CallFilters.scss';
import { popoverController } from '@ionic/core';
import { AppContext } from '../context/AppContext';
import moment from 'moment';

export interface CallFiltersProps {
  isOpen: boolean;
  onDidDismiss?: () => any;
  onApply?: (filters: any) => any;
  filters?: any;
}

const CallFilters: React.FC<CallFiltersProps> = ({
  isOpen,
  onDidDismiss,
  onApply,
  filters
}) => {
  const [direction, setDirection] = useState(filters?.direction || '');
  const [provider, setProvider] = useState(filters?.provider || '');
  const [user, setUser] = useState(filters?.user || '');
  const [since, setSince] = useState(filters?.created_at__gte);
  const [until, setUntil] = useState(filters?.created_at__lte);
  const [maxDuration, setMaxDuration] = useState(filters?.duration__lte/60 || 120);
  const [minDuration, setMinDuration] = useState(filters?.duration__gte/60 || 0);
  const { state: appState } = useContext(AppContext);

  return (
    <IonPopover
      isOpen={isOpen}
      mode="md"
      cssClass="call-filters"
      onDidDismiss={onDidDismiss}
    >
      <IonList lines="full">
        <TDateTimeItem
          range
          toValue={until}
          fromValue={since}
          onFromIonChange={(e: any) => setSince(e.detail.value)}
          onToIonChange={(e: any) => setUntil(e.detail.value)}
        />
        <TSelectItem
          label="Direction"
          value={direction}
          onChange={(e: any) => setDirection(e.detail.value)}
          options={[{value: '', text:'All'}, {value: 'inbound', text: 'Inbound'}, {value: 'outbound-api', text: 'Outbound'}]}
        >
          <IonIcon slot="start" icon={compass} className="ion-hide-md-down" />
        </TSelectItem>
        <TSelectItem
          label="User"
          value={user}
          onChange={(e: any) => setUser(e.detail.value)}
          options={[
            { value: '', text: 'All' },
            ...appState.clientUsers?.map?.(it => ({
              value: it.id,
              text: it.full_name
            }))
          ]}
        >
          <IonIcon
            slot="start"
            icon={personCircle}
            className="ion-hide-md-down"
          />
        </TSelectItem>
        <TSelectItem
          label="Lead Provider"
          value={provider}
          onChange={(e: any) => setProvider(e.detail.value)}
          options={[
            { value: '', text: 'All' },
            ...appState.clientProviders?.map?.(it => ({
              value: it.id,
              text: it.name
            }))
          ]}
        >
          <IonIcon
            slot="start"
            icon={paperPlane}
            className="ion-hide-md-down"
          />
        </TSelectItem>
        <TItem
          className="price-range-item"
          label="Duration Minutes"
          labelPosition="stacked"
          lines="none"
        >
          <div className="ion-multiline price-range-container">
            <IonRange
              dualKnobs
              value={{ lower: minDuration, upper: maxDuration }}
              min={0}
              max={120}
              step={1}
              pin
              debounce={500}
              slot="end"
              onIonChange={(e: any) => {
                const { lower, upper } = e.detail.value;
                setMinDuration(lower);
                setMaxDuration(upper);
              }}
            />
            <div className="price-range">
              <span className="price-range-label">
                {minDuration}
              </span>
              <span className="price-range-label">
                {maxDuration}
              </span>
            </div>
          </div>
        </TItem>
        <ButtonGrid>
          <IonButton
            expand="block"
            color="primary"
            onClick={() => {
              popoverController.dismiss();
              setProvider('');
              setDirection('');
              onApply?.({});
            }}
          >
            Reset Filters
          </IonButton>
          <IonButton
            expand="block"
            color="secondary"
            onClick={() => {
              popoverController.dismiss();
              onApply?.({
                provider,
                direction,
                user,
                created_at__gte: moment(since).startOf('day').toISOString(),
                created_at__lte: moment(until).endOf('day').toISOString(),
                duration__gte: minDuration*60,
                duration__lte: maxDuration*60
              });
            }}
          >
            Apply Filters
          </IonButton>
        </ButtonGrid>
      </IonList>
    </IonPopover>
  );
};

export default CallFilters;
