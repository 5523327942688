import React, { useContext, useState, useEffect, useCallback } from 'react';
import TModal from './TModal';
import { useRouter } from '../../hooks';
import { LeadContext } from '../../context/LeadContext';
import { AppContext } from '../../context/AppContext';
import { IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { modalController } from '@ionic/core';
import { TSelectItem, TButtonItem } from '../';
import {
  calendarsService,
  urlShortenerService
} from '../../services';
import { loadingIndicator } from '../../core';
import { person, people, business, calendar } from 'ionicons/icons';

export interface SelfSchedulingLinkModalProps {
  isOpen?: boolean;
  onDidDismiss?: () => void;
  onSend?: (url: string) => any;
}

const SelfSchedulingLinkModal: React.FC<SelfSchedulingLinkModalProps> = ({
  onDidDismiss,
  onSend,
  isOpen = false
}) => {
  const { state } = useContext(LeadContext);
  const appContext = useContext(AppContext);
  const router = useRouter();
  const { user, clientUsers } = appContext.state;
  const calendarAvailability = JSON.parse(user.calendars_availability_json) ?? {};
  const calendarEnabled = !!calendarAvailability.addToCalendar;
  const [mainUserSlug, setMainUserSlug] = useState(user?.calendars_slug);
  const [otherUsersSlugs, setOtherUsersSlug] = useState<any>();
  const [eventType, setEventType] = useState();
  const [eventTypes, setEventTypes] = useState<any>();
  const [selectedEventType, setSelectedEventType] = useState<any>();
  const { lead } = state;
  const { urlhash } = lead ?? {};

  const loadEventTypes = useCallback(async () => setEventTypes(
    await calendarsService.retrieveEventTypes(mainUserSlug)
  ), [mainUserSlug]);

  useEffect(() => {
    if (eventType && eventTypes) {
      setSelectedEventType(eventTypes?.find((x: any) => x.slug === eventType));
    }
  }, [eventType, eventTypes]);

  useEffect(() => {
    loadEventTypes();
  }, [loadEventTypes]);

  const buildLink = useCallback(async () => {
    let longLink = `https://cal.tecobi.com/calendars/${mainUserSlug}`;
    if (eventType) {
      longLink += `/event/${eventType}`;
    }
    longLink += `/${urlhash}`;
    if (otherUsersSlugs?.length) {
      longLink += `?crossCheck=${otherUsersSlugs.join(',')}`;
    }
    try {
      await loadingIndicator.create();
      const { short_url } = await urlShortenerService.shorten(
        longLink
      );
      if(onSend) {
        const msg = `Use this link to schedule your ${selectedEventType ? selectedEventType.title : 'appointment'}: ${short_url ?? longLink}`
        onSend(msg);
      }
      modalController.dismiss();
    } finally {
      loadingIndicator.dismiss();
    }
  }, [mainUserSlug, otherUsersSlugs, eventType, urlhash, onSend, selectedEventType]);

  return (
    <TModal
      title={`${lead?.best_name} Self-Scheduling`}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      footer={
        <IonToolbar>
          <IonButtons slot="primary">
            <IonButton
              slot="primary"
              color="success"
              disabled={!calendarEnabled}
              id="btn-set-appointment"
              onClick={buildLink}
            >
              Send Link
            </IonButton>
          </IonButtons>
        </IonToolbar>
      }
    >


      {!calendarEnabled && (
        <>
          <IonItem lines="none">
            <IonLabel className="ion-multiline">
              You need to setup your calendar first.
            </IonLabel>
          </IonItem>
          <TButtonItem
            lines="none"
            color="secondary"
            size="default"
            onClick={() => {
              router.push('/calendar-settings/', {});
              modalController.dismiss();
            }}
          >
            <IonIcon icon={calendar} slot="start" />
            Setup Your Calendar
          </TButtonItem>
        </>
      )}
      {calendarEnabled && (
        <>
          <TSelectItem
            label="Main Calendar User *"
            value={mainUserSlug}
            onChange={(e: any) => setMainUserSlug(e.detail.value)}
            icon={person}
            options={[
              { value: user?.calendars_slug, text: 'Me' },
              ...clientUsers
                ?.filter(it => it.calendars_connected && it.id !== user?.id)
                ?.map?.(it => ({
                  value: it.calendars_slug,
                  text: it.full_name
                }))
            ]}
          />
          <TSelectItem
            label="Other Calendar Users (optional)"
            value={otherUsersSlugs}
            onChange={(e: any) => setOtherUsersSlug(e.detail.value)}
            icon={people}
            multiple
            options={[
              ...clientUsers
                ?.filter(it => it.calendars_connected)
                ?.map?.(it => ({
                  value: it.calendars_slug,
                  text: it.full_name
                }))
            ]}
          />
          {!!eventTypes?.length && (
            <TSelectItem
              label="Event Type (optional)"
              value={eventType}
              onChange={(e: any) => setEventType(e.detail.value)}
              icon={business}
              options={[
                ...eventTypes
                  ?.map?.((it: any) => ({
                    value: it.slug,
                    text: it.title
                  }))
              ]}
            />
          )}
        </>
      )}
    </TModal>
  );
};

export default SelfSchedulingLinkModal;
