import React, { useState, useCallback, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { fitBounds } from 'google-map-react/utils';
import { useDarkTheme, useClientRect } from '../hooks/';
import darkMapTheme from '../styles/dark-map';
import GoogleMapReact from 'google-map-react';

declare global {
  interface Window {
    google: any;
  }
}

export interface HeatmapCoords {
  lat: any;
  lng: any;
  weight?: number;
}

export interface TMapProps {
  coords?: any;
  startCoords?: any;
  label?: string;
  infoContent?: JSX.Element;
  zoom?: number;
  children?: any;
  style?: any;
  paused?: boolean;
  hideMarker?: boolean;
  onScreen?: boolean;
  hideTraffic?: boolean;
  heatmap?: HeatmapCoords[];
  heatMapRadius?: number;
  options?: any;
  forceDark?: boolean;
}

const InfoContentContainer: any = ({ children }: any) => (
  <div
    style={{
      backgroundColor: 'white',
      borderRadius: 6,
      color: 'black',
      height: 'auto',
      padding: 5,
      width: 200
    }}
  >
    {children}
  </div>
);

const TMap: React.FC<TMapProps> = ({
  coords,
  startCoords,
  zoom = 17,
  infoContent,
  paused = false,
  hideMarker,
  children,
  heatmap,
  hideTraffic,
  heatMapRadius,
  options = {},
  style = {},
  forceDark
}) => {
  const [rect, container] = useClientRect();
  const directionsRenderer = useRef<any>();
  const infoWindow = useRef<any>();
  const marker = useRef<any>();
  const [dest, setDest] = useState(coords);
  const [orig, setOrig] = useState(startCoords);
  const [center, setCenter] = useState(coords);
  const [currentZoom, setCurrentZoom] = useState(zoom);
  const isDarkTheme = useDarkTheme();
  const layerTypes: any[] = [];

  if (!hideTraffic) {
    layerTypes.push('TrafficLayer');
  }

  const getDirectionsRenderer = useCallback(() => {
    if (!directionsRenderer.current) {
      directionsRenderer.current = new window.google.maps.DirectionsRenderer();
    }

    return directionsRenderer.current;
  }, []);

  useEffect(() => setCurrentZoom(zoom), [zoom]);
  useEffect(() => {
    setDest(coords);
    setOrig(startCoords);

    if (startCoords?.lat) {
      const opts = {
        nw: {
          lat: Math.min(parseFloat(coords.lat), parseFloat(startCoords.lat)),
          lng: Math.min(parseFloat(coords.lng), parseFloat(startCoords.lng))
        },
        se: {
          lat: Math.max(parseFloat(coords.lat), parseFloat(startCoords.lat)),
          lng: Math.max(parseFloat(coords.lng), parseFloat(startCoords.lng))
        }
      };

      const size = { width: rect?.width ?? 200, height: rect?.height ?? 200 };

      const bounds = fitBounds(opts, size);
      setCenter(bounds.center);
      setCurrentZoom(bounds.zoom);
    } else {
      setCenter(coords);
    }
  }, [coords, startCoords, rect]);

  const mapPan = (detail: boolean) => () => {
    window.dispatchEvent(new CustomEvent('map:pan', { detail }));
  };

  useEffect(() => {
    return mapPan(false);
  }, []);

  const updateMap = useCallback(() => {
    if (window.google && orig?.lat) {
      const DirectionsService = window.google.maps.DirectionsService;
      const directions = new DirectionsService();
      directions.route(
        {
          origin: orig,
          destination: dest,
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result: any, status: any) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            getDirectionsRenderer().setDirections(result);
          }
        }
      );
    }
  }, [orig, dest, getDirectionsRenderer]);

  useEffect(() => {
    if (!paused) {
      updateMap();
    }
  }, [paused, updateMap]);

  let heatmapOptions;

  if (heatmap) {
    heatmapOptions = {
      positions: heatmap?.map(it => ({
        lat: it.lat,
        lng: it.lng,
        weight: it.weight
      })),
      options: {
        radius: heatMapRadius
      }
    };
  }

  const renderMarker = (map: any) => {
    if (!hideMarker && !orig?.lat) {
      marker.current = new window.google.maps.Marker({
        position: dest,
        map
      });

      if (infoContent) {
        const html = renderToString(
          <InfoContentContainer>{infoContent}</InfoContentContainer>
        );

        infoWindow.current = new window.google.maps.InfoWindow({
          content: html
        });

        infoWindow.current.open(map, marker.current);
      }
    }
  };

  return (
    <div
      ref={container}
      style={{ height: '100%', width: '100%' }}
      onMouseDown={mapPan(true)}
      onMouseUp={mapPan(false)}
    >
      <GoogleMapReact
        style={style}
        zoom={currentZoom}
        center={center}
        heatmap={heatmapOptions}
        layerTypes={layerTypes}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }: any) => {
          getDirectionsRenderer().setMap(map);
          renderMarker(map);
        }}
        options={{
          styles: isDarkTheme || forceDark ? darkMapTheme : undefined,
          controlSize: 25,
          ...options
        }}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
};

export default React.memo(TMap);
