import { http } from '../core';
import CachedService, { CacheStrategy, CacheTTL } from './cached-service';

class ClientNumbersService extends CachedService {
  constructor() {
    super(
      'client-numbers',
      'clientNumbers',
      [],
      CacheStrategy.TIMEOUT,
      CacheTTL.FIFTEENMINUTES
    );
  }

  async request(clientId: any) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/numbers/`
    });

    return res.data.results;
  }

  async update(clientId: any, id: any, changes: any) {
    const { data } = await http.authorizedRequest({
      method: 'PATCH',
      url: `/clients/${clientId}/numbers/${id}/`,
      data: changes
    });

    this.evict();

    return data;
  }
}

export default new ClientNumbersService();
