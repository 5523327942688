import React from 'react';
import { Route } from 'react-router';
import { TrainingContextProvider } from './context/TrainingContext';

import {
  Login,
  UserCalendarEventTypes,
  UserReserveCalendarSlot,
  VehicleShowcase,
  PersonalizedLead,
  CovidInformation,
  TrainingCategories,
  TrainingQuizzes,
  TrainingQuiz
} from './pages';
import { IonRouterOutlet } from '@ionic/react';

const UnauthorizedView: React.FC = () => {
  return (
    <TrainingContextProvider>
      <IonRouterOutlet id="main">
        <Route
          exact
          path="/vehicle/:clientId/:leadId/:vehicleId/"
          component={VehicleShowcase}
        />
        <Route exact path="/d/:token/" component={PersonalizedLead} />
        <Route exact path="/d/:token/trade-:showTrade" component={PersonalizedLead} />
        <Route exact path="/d/:token/:feature/:id" component={PersonalizedLead} />
        <Route exact path="/reset-password/:email/" component={Login} />
        <Route exact path="/forgot-password/" component={Login} />
        <Route exact path="/calendars/:slug/:leadHash?" component={UserCalendarEventTypes} />
        <Route exact path="/calendars/:slug/event/:eventTypeSlug/:leadHash?" component={UserReserveCalendarSlot} />
        <Route exact path="/training/" component={TrainingCategories} />
        <Route exact path="/training/:slug/" component={TrainingQuizzes} />
        <Route exact path="/training/:slug/:quizSlug/" component={TrainingQuiz} />
        <Route exact path="/covid-19/" component={CovidInformation} />
        <Route exact component={Login} />
      </IonRouterOutlet>
    </TrainingContextProvider>
  );
};

export default UnauthorizedView;
