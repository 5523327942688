import React, { useCallback, useState, useEffect, useContext } from 'react';
import {
  IonCol,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonCardContent,
  IonList,
  IonItem,
  IonBadge,
  IonRouterLink,
  IonGrid,
  IonSkeletonText,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react';

import { useHistory } from 'react-router-dom';

import {
  TPage,
  UpcomingAppointmentsCard,
  UnreadNotificationsCard,
  MetricCard,
  TimeToCallCard,
  UnassignedLeadsCountCard
} from '../components';

import {
  leadsService
} from '../services';

import { AppContext } from '../context/AppContext';
import { LeadContext } from '../context/LeadContext';
let loadWaitingLeadsInterval: number;

const Dashboard: React.FC = () => {
  const history = useHistory();
  const [needsCall, setNeedsCall] = useState<any>();
  const [usersNeedsCall, setUsersNeedsCall] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [usersWaiting, setUsersWaiting] = useState<any>();
  const [newLeadsToday, setNewLeadsToday] = useState<any>();
  const [newInboundsToday, setNewInboundsToday] = useState<any>();
  const [workingPipeline, setWorkingPipeline] = useState<any>();
  const [pausedPipeline, setPausedPipeline] = useState<any>();
  const [waiting, setWaiting] = useState<any>();
  const { state } = useContext(AppContext);
  const { dispatch } = useContext(LeadContext);
  const loadWaitingLeads = useCallback(async () => {
    const [
      needsCall,
      waiting,
      newLeadsToday,
      newInboundsToday,
      workingPipeline,
      pausedPipeline
    ] = await Promise.all([
      leadsService.listNeedsCall({ clientId: state.selectedClientId, pageSize: 500 }),
      leadsService.listWaiting({ clientId: state.selectedClientId, pageSize: 500 }),
      leadsService.listNewToday({ clientId: state.selectedClientId, pageSize: 1 }),
      leadsService.listHumanInboundsToday({ clientId: state.selectedClientId, pageSize: 1 }),
      leadsService.listWorkingPipeline({ clientId: state.selectedClientId, pageSize: 1 }),
      leadsService.listPausedPipeline({ clientId: state.selectedClientId, pageSize: 1 })
    ])
    const usersWaiting = {} as any;
    const usersNeedsCall = {} as any;
    needsCall.results.forEach((it: any) => {
      const userFullName: string = `${it.assigned_to?.first_name} ${it.assigned_to?.last_name}::${it.assigned_to?.id}`
      if (!(userFullName in usersNeedsCall)) {
        usersNeedsCall[userFullName] = (needsCall.results.filter((o: any) => o.assigned_to?.id === it.assigned_to?.id))
      }
    });

    waiting.results.forEach((it: any) => {
      const userFullName: string = `${it.assigned_to?.first_name} ${it.assigned_to?.last_name}::${it.assigned_to?.id}`
      if (!(userFullName in usersWaiting)) {
        usersWaiting[userFullName] = (waiting.results.filter((o: any) => o.assigned_to?.id === it.assigned_to?.id))
      }
    });

    setUsersNeedsCall(usersNeedsCall);
    setUsersWaiting(usersWaiting);
    setNeedsCall(needsCall);
    setWaiting(waiting);
    setNewLeadsToday(newLeadsToday);
    setNewInboundsToday(newInboundsToday);
    setWorkingPipeline(workingPipeline);
    setPausedPipeline(pausedPipeline);
    setLoading(false);
  }, [state.selectedClientId]);

  useEffect(() => {
    clearInterval(loadWaitingLeadsInterval);
    setLoading(true);
    loadWaitingLeads();
    loadWaitingLeadsInterval = setInterval(loadWaitingLeads, 10000);
  }, [loadWaitingLeads, state.selectedClientId]);

  useIonViewWillEnter(() => {
    clearInterval(loadWaitingLeadsInterval);
    setLoading(true);
    loadWaitingLeads();
    loadWaitingLeadsInterval = setInterval(loadWaitingLeads, 10000);
  },[state.selectedClientId]);

  useIonViewWillLeave(() => {
    clearInterval(loadWaitingLeadsInterval);
  });

  const renderUserList = (o: any, listType: string) => {
    const keys: any = typeof o === 'object' ? Object.keys(o) : [];
    if (!keys.length) {
      return (<IonItem lines="none"><span role="img" aria-label="cool">😎</span> &nbsp;No leads waiting.</IonItem>)
    }
    return keys.map((key: string) => {
        const keyPcs = key.split('::');
        const userFullName = keyPcs[1] !== 'undefined' ? keyPcs[0] : 'Unassigned';
        const userId = keyPcs[1] !== 'undefined' ? keyPcs[1] : 'Unassigned';
        return (
        <IonItem
          className="pointer"
          key={key}
          routerLink="/text-messages/"
          routerDirection="none"
          onClick={() => {
            const changes = {
                "assigned_to": {
                    "id": userId,
                    "display": userFullName
                },
                "convo_archived": "Active",
            } as any;
            if (listType === 'needsCall') {
              changes['needs_call'] = 'Call Needed';
            } else if (listType === 'waiting') {
              changes['last_message_type'] = 'inbound';
            }
            dispatch({
              type: 'set',
              value: {
                filters: { ...changes }
              }
            });
          }}
          detail
        >
          {userFullName}
          <IonBadge color={listType === 'waiting' ? 'secondary' : 'orange'} slot="end">{o[key]?.length}</IonBadge>
        </IonItem>
      )
    })
  }
  return (
    <TPage loading={false}>
      <IonGrid className="reports">
        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6">
          {loading
            ? Array.from(Array(3).keys()).map((it: any, i: number) => (
                <IonItem key={i}>
                  <IonLabel>
                    <IonSkeletonText key={i} animated />
                  </IonLabel>
                </IonItem>
              ))
            :
            <MetricCard
              title="New Today"
              value={newLeadsToday?.count}
              uom="Lead"
              onClick={() => {
                const changes = {
                    "convo_archived": "Active",
                    "created_at__gte": new Date().toISOString(),
                    "created_at__lte": new Date().toISOString()
                } as any;
                dispatch({
                  type: 'set',
                  value: {
                    filters: { ...changes }
                  }
                });
                history.push('/text-messages/');
              }}
            />}
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6">
          {loading
            ? Array.from(Array(3).keys()).map((it: any, i: number) => (
                <IonItem key={i}>
                  <IonLabel>
                    <IonSkeletonText key={i} animated />
                  </IonLabel>
                </IonItem>
              ))
            :
            <MetricCard
              title="Engaged Today"
              value={newInboundsToday?.count}
              uom="Lead"
              onClick={() => {
                const changes = {
                    "convo_archived": "Active",
                    "last_human_inbound_time__gte": new Date().toISOString(),
                    "last_human_inbound_time__lte": new Date().toISOString()
                } as any;
                dispatch({
                  type: 'set',
                  value: {
                    filters: { ...changes }
                  }
                });
                history.push('/text-messages/');
              }}
            />}
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid className="reports">
        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6">
          {loading
            ? Array.from(Array(3).keys()).map((it: any, i: number) => (
                <IonItem key={i}>
                  <IonLabel>
                    <IonSkeletonText key={i} animated />
                  </IonLabel>
                </IonItem>
              ))
            :
            <MetricCard
              title="Textable Pipeline"
              value={workingPipeline?.count}
              uom="Lead"
              onClick={() => {
                const changes = {
                    "convo_archived": "Active",
                    "lead_type__in": "SMS,Web,Other,ADF,DMS",
                    "classification": "Sales",
                    "pause_date": "Unpaused",
                    "imported_replied": "2",
                    "violators": false,
                    "sms_optout": false,
                    "carrier_type__in": "null,mobile,voip",
                    "status__in": '1,2,23,21,11,12,35'
                } as any;
                dispatch({
                  type: 'set',
                  value: {
                    filters: { ...changes }
                  }
                });
                history.push('/text-messages/');
              }}
            />}
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6">
          {loading
            ? Array.from(Array(3).keys()).map((it: any, i: number) => (
                <IonItem key={i}>
                  <IonLabel>
                    <IonSkeletonText key={i} animated />
                  </IonLabel>
                </IonItem>
              ))
            :
            <MetricCard
              title="Paused Pipeline"
              value={pausedPipeline?.count}
              uom="Lead"
              onClick={() => {
                const changes = {
                    "convo_archived": "Active",
                    "pause_date": "Paused"
                } as any;
                dispatch({
                  type: 'set',
                  value: {
                    filters: { ...changes }
                  }
                });
                history.push('/text-messages/');
              }}
            />}
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className="reports">
        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonCard>
              <IonCardHeader color="orange">
                <IonCardTitle>
                  Customers Needing Call
                  <IonRouterLink routerLink="/text-messages/" routerDirection="root">
                    <IonBadge
                      className="pointer"
                      onClick={() => {
                        const changes = {
                            "convo_archived": "Active",
                            "needs_call": "Call Needed",
                        } as any;
                        dispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes }
                          }
                        });
                      }}
                    >
                      {needsCall?.count}
                    </IonBadge>
                  </IonRouterLink>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{maxHeight: 300, overflow: 'auto'}}>
              {loading
                ? Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                :

                <IonList>
                  {renderUserList(usersNeedsCall, 'needsCall')}
                </IonList>
              }
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="6" sizeXl="6">
            <IonCard>
              <IonCardHeader color="secondary">
                <IonCardTitle>
                  Customers Waiting for SMS
                  <IonRouterLink routerLink="/text-messages/" routerDirection="root">
                    <IonBadge
                      className="pointer"
                      onClick={() => {
                        const changes = {
                            "convo_archived": "Active",
                            "pause_date": "Unpaused",
                            "last_message_type": "inbound"
                        } as any;
                        dispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes }
                          }
                        });
                    }}
                    >
                      {waiting?.count}
                    </IonBadge>
                  </IonRouterLink>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{maxHeight: 300, overflow: 'auto'}}>
              {loading
                ? Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                :

                <IonList>
                  {renderUserList(usersWaiting, 'waiting')}
                </IonList>
              }
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className="reports">
        <IonRow>
          <UpcomingAppointmentsCard />
        </IonRow>
      </IonGrid>

      <TimeToCallCard />
      <UnassignedLeadsCountCard />
      <IonGrid className="reports">
        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <UnreadNotificationsCard />
          </IonCol>
        </IonRow>
      </IonGrid>
    </TPage>
  );
};

export default Dashboard;
