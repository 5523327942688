import { Plugins, KeyboardResize } from '@capacitor/core';
import { isPlatform } from '@ionic/core';
import { dispatch } from '../context/AppContext';
const { SplashScreen, Keyboard, App } = Plugins;

class Native {
  get isNative() {
    return isPlatform('capacitor');
  }

  get isElectron() {
    return isPlatform('electron');
  }

  get isIOS() {
    return isPlatform('capacitor') && isPlatform('ios');
  }

  get isAndroid() {
    return isPlatform('capacitor') && isPlatform('android');
  }

  get isIPhone() {
    return isPlatform('capacitor') && isPlatform('iphone');
  }

  get isIPad() {
    return isPlatform('capacitor') && isPlatform('ipad');
  }

  private forwardEvent(type: any) {
    Keyboard.addListener(type, (e: any) =>
      window.dispatchEvent(new CustomEvent(type, { detail: e }))
    );
  }

  private initializeCustomKeyboard() {
    Keyboard.setResizeMode({
      mode: KeyboardResize.None
    });

    Keyboard.addListener('keyboardDidShow', () =>
      dispatch({ type: 'set', value: { keyboardOpen: true } })
    );

    Keyboard.addListener('keyboardDidHide', () =>
      dispatch({ type: 'set', value: { keyboardOpen: false } })
    );

    this.forwardEvent('keyboardDidShow');
    this.forwardEvent('keyboardWillShow');
    this.forwardEvent('keyboardDidHide');
    this.forwardEvent('keyboardWillHide');
  }

  private async initializeDeepLinks() {
    const dispatchRouteChange = (url: string) =>
      window.dispatchEvent(new CustomEvent('native:deeplink', { detail: url }));
    const launch = await App.getLaunchUrl();
    if (launch.url) {
      dispatchRouteChange(launch.url);
    }

    App.addListener('appUrlOpen', (data: any) => {
      dispatchRouteChange(data.url);
    });
  }

  initialize() {
    if (!this.isNative) {
      return;
    }

    if (!this.isAndroid) {
      this.initializeCustomKeyboard();
    }

    this.initializeDeepLinks();

    SplashScreen.hide();
  }
}

export default new Native();
