import React, { useState } from 'react';
import { ChatBubble, TAvatar } from '../components';
import { realTimeService } from '../services';
import { VideoMeetingModal, UserTypingModal, UserCallsModal } from '../components/modals';
import {
  IonAlert,
  IonCard,
  IonCardContent,
  IonGrid,
  IonPopover,
  IonText,
  IonFooter,
  IonButton,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon } from '@ionic/react';

import {
  chatbubbleEllipses,
  videocam,
  call as callIcon,
  shieldCheckmark,
  closeCircleOutline,
  ellipsisVertical
} from 'ionicons/icons';

import '../styles/components/RealTimeCard.scss';

export interface RealTimeCardProps {
  user: any;
  clientId: number;
  call?: any;
  videoMeeting?: any;
  sms?: any;
}

const RealTimeCard: React.FC<RealTimeCardProps> = ({
  user,
  clientId,
  call,
  videoMeeting,
  sms
}) => {
  const [showVideoMeetingModal, setShowVideoMeetingModal] = useState(false);
  const [showUserTypingModal, setShowUserTypingModal] = useState(false);
  const [showUserCallsModal, setShowUserCallsModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });

  return (
    <IonCol className="real-time-card" sizeSm="12" sizeXs="12" sizeMd="6" sizeLg="4" sizeXl="3">
      <IonCard>
        <IonItem lines="full">
          <TAvatar src={user.profile_pic} alt={user.full_name ?? user.fullName} slot="start" />
          {user.is_staff && (
            <IonIcon className="shield-icon" title="TECOBI Employee" icon={shieldCheckmark} />
          )}
          <IonText>
            {user.full_name ?? user.fullName}
          </IonText>
          <IonPopover
            cssClass='my-custom-class'
            event={popoverState.event}
            isOpen={popoverState.showPopover}
            animated={false}
            translucent={true}
            onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
          >
            <IonList>
              <IonItem
                lines="none"
                button
                onClick={(e: any) => {
                  setShowAlert(true);
                  setShowPopover({ showPopover: false, event: undefined });
                }}
              >
                Remove Item
                <IonIcon
                  icon={closeCircleOutline}
                  slot="end"
                  className="pointer"
                />
              </IonItem>
            </IonList>
          </IonPopover>
          <IonIcon
            icon={ellipsisVertical}
            slot="end"
            onClick={(e: any) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e })
            }}
            className="pointer"
          />
        </IonItem>
        <IonCardContent>
        {call && (
          <ChatBubble
            id={call.id}
            yellow={true}
            internal={true}
            sender={user.full_name ?? user.fullName}
            outgoing={call.direction === 'outbound-api'}
            typing={false}
            hideAudio={true}
            timeSince={call.created_at}
            call={{
              "id": call.id,
              "audio_file": null,
              "recording_url": null,
              "from_city": call.from_city,
              "from_state": call.from_state,
              "from_number": call.from_number,
              "call_status": call.call_status,
              "user": {
                "first_name": user.first_name ?? user?.fullName?.split(' ')[0],
                "last_name": user.last_name ?? user?.fullName?.split(' ')?.[1],
                "id": user.id
              }
            }}
            message={`${call.direction === 'outbound-api' ? 'Outbound' : 'Inbound'} phone call`}
          />
        )}

        {videoMeeting && (
          <ChatBubble
            id={videoMeeting.id}
            yellow={true}
            internal={true}
            sender={videoMeeting.user.full_name.split('(')[0]}
            outgoing={true}
            typing={false}
            message={videoMeeting.join_url}
            videoMeetingStatus="in-progress"
          />
        )}
        {sms && (
          <ChatBubble
            id={sms.lid}
            yellow={sms.internalOnly}
            internal={sms.internalOnly}
            sender={sms.fullName.split('(')[0]}
            outgoing
            typing
            message={sms.messagePreview}
          />
        )}
        </IonCardContent>
        <IonFooter>
          <IonGrid>
            <IonRow>
              {videoMeeting && (
                <IonCol>
                  <IonButton fill="clear" size="small" onClick={(e) => setShowVideoMeetingModal(true)}>
                    <IonIcon icon={videocam} slot="start" />
                    {videoMeeting.lead.full_name}
                  </IonButton>
                </IonCol>
              )}
              {call && (
                  <IonCol>
                    <IonButton fill="clear" size="small" onClick={(e) => {setShowUserCallsModal(true)}}>
                      <IonIcon icon={callIcon} slot="start" />
                      {call.lead_full_name}
                    </IonButton>
                  </IonCol>
              )}
              {sms && (
                <IonCol>
                  <IonButton fill="clear" size="small" onClick={(e) => {setShowUserTypingModal(true)}}>
                    <IonIcon icon={chatbubbleEllipses} slot="start" />
                    {sms.leadFullName}
                  </IonButton>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonFooter>
      </IonCard>
      {showVideoMeetingModal && (
        <VideoMeetingModal
          isOpen={showVideoMeetingModal}
          videoMeeting={videoMeeting}
          user={user}
          onDidDismiss={() => {setShowVideoMeetingModal(false)}}
        />
      )}
      {showUserTypingModal && (
        <UserTypingModal
          isOpen={true}
          sms={sms}
          user={user}
          clientId={clientId}
          onDidDismiss={() => {setShowUserTypingModal(false)}}
        />
      )}
      {showUserCallsModal && (
        <UserCallsModal
          isOpen={true}
          user={user}
          call={call}
          clientId={clientId}
          onDidDismiss={() => {setShowUserCallsModal(false)}}
        />
      )}
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Confirm!'}
        message={`Are you sure you want to delete this item? This deletes it for everyone.`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: 'Okay',
            handler: async () => {
              let key:string = '';
              let action:string = '';
              if (!!call) {
                key = call.call_sid;
                action = 'calls'
              } else if (!!videoMeeting) {
                key = videoMeeting.sid;
                action = 'video-meetings'
              } else if(!!sms) {
                key = sms.lid;
                action = 'typing'
              }
              await realTimeService.delete(
                user.id ?? user.uid,
                action,
                key
              )
            }
          }
        ]}
      />
    </IonCol>
  );
};

export default RealTimeCard;
