import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
class UserDefaults {
  async setValue(key: string, value: any) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    return await Storage.set({ key, value });
  }

  async getValue(key: string) {
    let { value } = await Storage.get({ key });
    if (value) {
      try {
        value = JSON.parse(value);
      } catch (e) {}
    }

    return value;
  }

  async clear() {
    return await Storage.clear();
  }

  async removeKey(key: string) {
    return await Storage.remove({ key });
  }
}

export default new UserDefaults();
