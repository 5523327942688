import PagedService from './paged-service';
import { http } from '../core';
import qs from 'querystring';


class AppointmentLostFollowupsService extends PagedService {

  async list( clientId: number) {
    try {
      const params = {} as any;

      params.deleted_at__isnull = true;

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/appointment-lost-followup-configs/?${query}`,
        type: 'appointment-lost-followup-configs',
        exclusive: true
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async get(clientId: number, id: number, type: string) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/appointment-lost-followup-configs/${id}/`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(clientId: number, postData: any) {
    try {
      const formData = new FormData();
      Object.keys(postData).forEach((k: string) => {
        if (postData[k] !== null && postData[k] !== undefined) {
          formData.append(k, postData[k]);
        }
      });
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/appointment-lost-followup-configs/`,
        data: formData
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async patch(clientId: number, id: number, data: any) {
    try {
      const formData = new FormData();
      if (data?.media === null) {
        data.media = '';
      }
      Object.keys(data).forEach((k: string) => {
        if (data[k] !== null && data[k] !== undefined) {
          formData.append(k, data[k]);
        }
      });
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/appointment-lost-followup-configs/${id}/`,
        data: formData
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async delete(clientId: number, id: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/appointment-lost-followup-configs/${id}/`
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

}

export default new AppointmentLostFollowupsService();
