import { useLocation, useHistory } from 'react-router';
import { util, appNotification } from '../core';
import { useEffect, useContext } from 'react';
import { AppContext } from '../context/AppContext';

export default function useRequiresClockin() {
  const { state } = useContext(AppContext);
  const { isClockedIn, selectedClient, user } = state;
  const location = useLocation();
  const history = useHistory();
  const isInbound = util.locationIsInbound(location);
  const isNeedsCall = util.locationIsNeedsCall(location);

  useEffect(() => {

    const userMustClockIn =
      (selectedClient?.force_clock_in === 'non_client_admins' &&
        !user?.is_client_admin &&
        !user?.is_staff) ||
      (selectedClient?.force_clock_in === 'all_client_users' && !user?.is_staff);

    if (!isClockedIn && (isInbound || isNeedsCall || userMustClockIn)) {
      appNotification.toast(
        'You must be clocked in to view this lead.',
        'Error'
      );
      history.replace('/', {});
    }
  }, [isClockedIn, history, isInbound, isNeedsCall, selectedClient, user]);
}
