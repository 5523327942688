import React, { useState, useEffect } from 'react';
import { TItemProps, default as TItem } from './TItem';
import { IonButton, IonIcon, IonLabel } from '@ionic/react';
import css from 'classnames';
import '../styles/components/TClickToEditItem.scss';
import TInput from './TInput';
import { TModal } from './modals';
import ButtonGrid from './ButtonGrid';
import ReactMarkdown from 'react-markdown';
import { pencil } from 'ionicons/icons';
import { useKeyboardShortcuts } from '../hooks';
import { appNotification, native } from '../core';

export interface TClickToEditItemProps extends TItemProps {
  text: any;
  placeholderText?: any;
  title?: string;
  type?: 'button' | 'bubble';
  buttonIcon?: any;

  disabled?: boolean;
  onChange?: (e: CustomEvent) => any;
  editPlaceholder?: string;
  onInputValueChange?: (e: any) => any;
  autocompleteResults?: any[];
  appendResult?: (value: string, item: string) => string;
  modalToolbar?: any;
  validate?: (value: string) => boolean;
}

const TClickToEditItem: React.FC<TClickToEditItemProps> = ({
  text,
  placeholderText,
  className,
  label,
  onChange,
  children,
  title,
  buttonIcon,
  disabled,
  editPlaceholder,
  type = 'bubble',
  onInputValueChange,
  autocompleteResults,
  appendResult,
  modalToolbar,
  validate,
  ...rest
}) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(text);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  useEffect(() => setSelectedItemIndex(0), [autocompleteResults]);

  useEffect(() => setValue(text), [text]);
  const isBubble = type === 'bubble';

  const setInputHeight = () => {
    // TODO: hacky way to set the initial height of the editable textarea in the modal
    setTimeout(() => {
      const elm = document.querySelector<HTMLElement>('.native-textarea');
      if (elm) {
        const height = elm?.scrollHeight;
        elm.style.height = `${height}px`;
      }
    }, 250);
  };

  const edit = () => {
    if (!disabled) {
      setEditing(true);
      setInputHeight();
    }
  };

  const doAppendResult = (item: string) => {
    if (appendResult) {
      setValue(appendResult(value, item));
      const element = document.getElementById('click-to-edit-input') as any;
      element?.setFocus?.();
    }
  };

  const selectResult = (e: any) => {
    if (autocompleteResults?.length) {
      e.preventDefault();
      e.stopPropagation();
      doAppendResult(autocompleteResults?.[selectedItemIndex] ?? '');
    }
  };

  useKeyboardShortcuts({
    Tab: selectResult
  });

  return (
    <TItem
      label={label}
      labelPosition="stacked"
      className={css('t-click-to-edit-item', className, { button: !isBubble })}
      {...rest}
    >
      {isBubble ? (
        <div
          className={css('display-text', { pointer: !disabled })}
          onClick={edit}
        >
          <ReactMarkdown
            renderers={{
              link: (props: any) => props.children
            }}
            source={placeholderText ?? text}
          />
          {!disabled && (
            <div className="detail edit-message">
              <IonIcon icon={pencil} />{' '}
              <label>{native.isNative ? 'Tap' : 'Click'} to Edit</label>
            </div>
          )}
        </div>
      ) : (
        <IonLabel>
          <IonButton onClick={edit} color="secondary" expand="block">
            {buttonIcon && <IonIcon slot="start" icon={buttonIcon} />}
            {placeholderText}
          </IonButton>
        </IonLabel>
      )}
      {children}
      {editing && (
        <TModal
          title={title ?? label}
          isOpen={true}
          cssClass="t-click-to-edit-item-modal"
          onDidDismiss={() => setEditing(false)}
          noContent
          footer={
            <ButtonGrid>
              <IonButton
                expand="block"
                color="secondary"
                onClick={() => {
                  if (!disabled) {
                    if (!validate || validate(value)) {
                      onChange?.(
                        new CustomEvent('onChange', { detail: { value } })
                      );
                      setEditing(false);
                    } else if (!!validate) {
                      appNotification.toast(
                        'Please fill out all of the fields.',
                        'Error'
                      );
                    }
                  }
                }}
              >
                Save
              </IonButton>
            </ButtonGrid>
          }
          toolbar={modalToolbar}
        >
          <TInput
            autoGrow={true}
            placeholder={editPlaceholder}
            value={value}
            onIonChange={onInputValueChange}
            id="click-to-edit-input"
            onEnterPressed={
              autocompleteResults?.length ? selectResult : undefined
            }
            onSelectedIndexChanged={setSelectedItemIndex}
            onSelected={doAppendResult}
            valueChanged={setValue}
            results={autocompleteResults}
            multiline
          />
        </TModal>
      )}
    </TItem>
  );
};

export default TClickToEditItem;
