import React, { useState, useContext, useEffect } from 'react';
import TModal from './TModal';
import { postAppointmentFollowupsService } from '../../services';
import { IonList, IonButton, IonIcon } from '@ionic/react';
import { TInputItem, TToggleItem, TSelectItem, ButtonGrid, TItem, TImage } from '..';
import {
  chatbubble,
  time,
  personCircle,
  image,
  call as callIcon,
  trash
} from 'ionicons/icons';
import { modalController } from '@ionic/core';
import {
  media,
  native,
  util,
  loadingIndicator,
  appNotification
} from '../../core';
import { AppContext } from '../../context/AppContext';
import GiphyPicker from '../GiphyPicker';
export interface PostAppointmentFollowupFormModalProps {
  item: any;
  onDidDismiss?: () => any;
  token: string;
}

const PostAppointmentFollowupFormModal: React.FC<PostAppointmentFollowupFormModalProps> = ({
  item,
  onDidDismiss,
  token
}) => {
  const { state: appState } = useContext(AppContext);
  const {
    selectedClient,
    statuses
  } = appState;
  const giphyEnabled = selectedClient.gif_search_enabled;
  const [config, setConfig] = useState(Object.assign({}, item));
  const [canSubmit, setCanSubmit] = useState(true);
  const [photo, setPhoto] = useState<any>();
  const [photoPreview, setPhotoPreview] = useState<any>();
  const [showGiphyPicker, setShowGiphyPicker] = useState(false);

  useEffect(() => {
    setPhotoPreview(config.media);
  }, [config]);

  const updateConfig = (key: string) => async (e: any) => {
    const val = e.detail.checked ?? e.detail.value;
    const configObj = config;
    configObj[key] = val;
    setCanSubmit(configObj.message && configObj.minutes_after_appointment && !isNaN(configObj.minutes_after_appointment));
    setConfig(configObj);
  };

  const save = async () => {
    try {
      await loadingIndicator.create();
      if (photo) {
        config.media = photo;
      }
      if (item.id) {
        if (config.media && typeof config.media === 'string' && config.media.indexOf('http') === 0) {
          delete config.media;
        }
        await postAppointmentFollowupsService.patch(
          config.client,
          config.id,
          config
        );
      } else {
        await postAppointmentFollowupsService.create(
          config.client,
          config
        );
      }

      appNotification.toast('Follow Up saved successfully.', 'Saved');
      modalController.dismiss();
    } finally {
      loadingIndicator.dismiss();
    }
  };
  const onFileSelected = async (file: any) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoPreview(reader.result);
        setPhoto(file);
        resolve();
      });

      reader.readAsDataURL(file);
    });
  };

  const captureNative = async () => {
    try {
      const { file, url } = await media.selectPhoto();
      setPhotoPreview(url);
      setPhoto(file);
    } catch (e) {
      console.error(e);
    }
  };

  const attachImage = async () => {
    if (native.isAndroid) {
      captureNative();
    } else {
      const res = await media.selectPhoto();
      onFileSelected(res);
    }
  };

  const attachGiphy = async (e: any) => {
    const imageUrl = e.images.downsized.url;
    const dataUrl = (await util.urlToDataUrl(imageUrl)) as string;
    setPhotoPreview(imageUrl);
    setPhoto(util.dataURItoFile(dataUrl));
    setShowGiphyPicker(false);
  };

  const removeImage = (e: any) => {
    e.stopPropagation();
    setPhotoPreview(null);
    setPhoto(null);
    const configCopy = Object.assign({}, config);
    configCopy.media = '';
    setConfig(configCopy);
  };

  const isFollowup: boolean = token === 'followup';

  return (
    <TModal
      isOpen={!!item}
      onDidDismiss={onDidDismiss}
      title={isFollowup ? 'Post Appointment Follow Up' : 'Appointment Checkin'}
      cssClass="followup-form-modal"
      footer={
        <ButtonGrid>
          <IonButton
            onClick={save}
            disabled={!canSubmit}
            color="secondary"
            expand="block"
          >
            Save
          </IonButton>
        </ButtonGrid>
      }
    >
      {!!item && (
        <IonList>
          <TInputItem
            icon={chatbubble}
            label="Message"
            multiline={true}
            autoGrow={true}
            onChange={updateConfig('message')}
            value={config.message}
          />
          {isFollowup && (
            <>
              <TToggleItem
                lines="full"
                icon={callIcon}
                label="Needs Call"
                checked={config.needs_call}
                onIonChange={updateConfig('needs_call')}
              />
              <TItem icon={image} lines="full" wrapLabel>
                {photoPreview && <TImage src={photoPreview} alt="Attached Image" />}
                {photoPreview && (
                  <IonButton
                    slot="end"
                    color="danger"
                    fill="clear"
                    onClick={removeImage}
                  >
                    <IonIcon slot="icon-only" icon={trash} />
                  </IonButton>
                )}
                {!photo && (
                  <>
                    <IonButton slot="end" color="secondary" onClick={attachImage}>
                      Attach Image
                    </IonButton>
                    {giphyEnabled && (
                      <IonButton
                        slot="end"
                        color="secondary"
                        onClick={() => setShowGiphyPicker(true)}
                      >
                        Attach GIF
                      </IonButton>
                    )}
                  </>
                )}
              </TItem>
            </>
          )}
          <TSelectItem
            lines="full"
            icon={time}
            label="Follow Up"
            value={config.minutes_after_appointment}
            onChange={updateConfig('minutes_after_appointment')}
            options={isFollowup ?  [
                { value: 120, text: '2 hours after' },
                { value: 180, text: '3 hours after' },
                { value: 1440, text: '1 day after' },
                { value: 2880, text: '2 days after' },
                { value: 4320, text: '3 days after' }
              ] : [
                { value: 5, text: '5 minutes after' },
                { value: 10, text: '10 minutes after' },
                { value: 15, text: '15 minutes after' },
                { value: 30, text: '30 minutes after' },
              ]}
          />
          {isFollowup && (
            <TSelectItem
              label="Set Status To (optional)"
              value={config.set_lead_status_to}
              icon={personCircle}
              onChange={updateConfig('set_lead_status_to')}
              options={statuses
                ?.map?.(it => ({
                  value: it.id,
                  text: it.option_name
                }))}
            />
          )}
        </IonList>
      )}
      {isFollowup && showGiphyPicker && (
        <GiphyPicker
          isOpen={true}
          onSelected={attachGiphy}
          onDidDismiss={() => setShowGiphyPicker(false)}
        />
      )}
    </TModal>
  );
};

export default PostAppointmentFollowupFormModal;
