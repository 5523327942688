import PagedService from './paged-service';
import { http } from '../core';
import qs from 'querystring';

class UpcomingBlastsService extends PagedService {
  private tokenForType(type: string) {
    return type === 'leads' ? 'sms-blasts' : 'customer-blasts';
  }
  async list({ type, user, completedAtIsNull, deletedAtIsNull }: any) {
    try {
      const params = {page_size: 250} as any;

      if (user) {
        params.user = user;
      }
      if (undefined !== typeof completedAtIsNull) {
        params.completed_at__isnull = completedAtIsNull;
      }
      if (undefined !== typeof deletedAtIsNull) {
        params.deleted_at__isnull = deletedAtIsNull;
      }

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/${this.tokenForType(type)}/?${query}`,
        type: 'upcoming-blasts',
        exclusive: true
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new UpcomingBlastsService();
