import {
  IonList,
  IonLabel,
  IonItem,
  IonText
} from '@ionic/react';
import { useParams, useLocation } from 'react-router';
import { TPage, TAvatar } from '../components/';
import { useRouter } from '../hooks';

import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import { util } from '../core';
import { calendarsService } from '../services';

const UserCalendarEventTypesPage: React.FC = () => {
  const { slug, leadHash } = useParams();
  const [loading, setLoading] = useState(false);
  const [eventTypes, setEventTypes] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();
  const router = useRouter();
  const location = useLocation();

  util.setTitle('Calendar');

  const loadEventTypes = useCallback(async () => {
    if (!slug) {
      return false;
    }
    setLoading(true);

    const [evtTypes, usrInfo] = await Promise.all([
      calendarsService.retrieveEventTypes(slug),
      calendarsService.retrieveUserInfo(slug)
    ]);
    setUserInfo(usrInfo);
    setEventTypes(evtTypes);
    setLoading(false);
  }, [slug]);

  useEffect(() => {
    loadEventTypes();
  }, [loadEventTypes]);

  return (
    <TPage loading={loading} logoType="calendars">
      <IonList className="no-padding" lines="full">
        <IonItem>
          <IonLabel class="ion-multiline">
            <TAvatar
              src={userInfo?.profile_pic}
              alt={`${userInfo?.first_name ?? ''} ${userInfo?.last_name ?? ''}`}
              style={{ margin: 'auto', fontSize: 35, height: 100, width: 100 }}
            />
            <IonText
              style={{
                display: 'block',
                marginTop: 15,
                textAlign: 'center'
              }}
            >
              <h1>
                {userInfo?.first_name} {userInfo?.last_name}
              </h1>
              <p className="ion-text-wrap">Welcome to my scheduling page. Please follow the instructions to add an event to my calendar.</p>
            </IonText>
          </IonLabel>
        </IonItem>
        {eventTypes?.map((eventType: any) => (
          <IonItem
            button
            key={eventType.id}
            onClick={() => {
              const url = `/calendars/${slug}/event/${eventType.slug}/${leadHash ?? ''}${location.search}`;
              router.push(url, {});
            }}
            detail={true}
          >
            <IonLabel className="ion-text-wrap">
              <h2>{eventType.title}</h2>
              <p>{eventType.description}</p>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </TPage>
  );
};

export default UserCalendarEventTypesPage;
