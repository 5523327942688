import { http } from '../core';
import qs from 'qs';
import PagedService from './paged-service';
import moment from 'moment';

class AppointmentsService extends PagedService {
  async list({ clientId, search, from, to, page_size, filters, sort }: any) {
    try {
      const params = {} as any;

      if (search) {
        params.search = search;
      }

      if (page_size) {
        params.page_size = page_size;
      }

      if (from) {
        params.appointment_time__gte = moment(from).toISOString();
      }

      if (to) {
        params.appointment_time__lt = moment(to).toISOString();
      }

      if (sort) {
        params.sorting = sort;
      }

      Object.keys(filters ?? {}).forEach((k: string) => {
        const v = filters[k];
        if (!v?.match?.(/^all$/i)) {
          params[k] = v;
        }
      });

      const query = qs.stringify(params);

      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/appointments/?${query}`
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async update(
    clientId: number,
    leadId: number,
    appointmentId: number,
    data: any
  ) {
    try {
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/${leadId}/appointments/${appointmentId}/`,
        data
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  findActive(appointments: any[]) {
    return appointments?.find(
      (it: any) => !it.cancelled && !it.sold && !it.showed && !it.missed
    );
  }
}

export default new AppointmentsService();
