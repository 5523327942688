import React, { useState, useEffect, useCallback } from 'react';
import TModal from './TModal';
import { IonList, IonToolbar, IonButtons, IonButton } from '@ionic/react';
import TInputItem from '../TInputItem';
import { tradesService } from '../../services';
import { modalController } from '@ionic/core';
import { loadingIndicator, appNotification, util } from '../../core';
import TSelectItem from '../TSelectItem';
import '../../styles/components/AddTradeModal.scss';
import config from '../../config.json';

export interface AddTradeModalProps {
  isOpen?: boolean;
  onDidDismiss?: () => void;
  onTradeAdded?: (data: any) => any;
  isPublic?: boolean;
  clientId: number;
  leadId: number;
  tradeInProgress?: string;
  defaultPostalCode?: any;
  onTradeProgressUpdate?: (progress: any) => any;
}

const AddTradeModal: React.FC<AddTradeModalProps> = ({
  onDidDismiss,
  onTradeAdded,
  clientId,
  leadId,
  tradeInProgress,
  defaultPostalCode,
  onTradeProgressUpdate,
  isPublic = false,
  isOpen = false
}) => {
  let existing;
  if (tradeInProgress) {
    existing = JSON.parse(tradeInProgress) as any;
  }
  const cssClass = isPublic ? `add-trade-modal public-add-trade-modal` : 'add-trade-modal';
  const [lookupResults, setLookupResults] = useState<any[]>();
  const [step, setStep] = useState<number>(0);
  const [postalCode, setPostalCode] = useState(
    existing?.vehicleOptions?.zip_code ?? defaultPostalCode
  );
  const [description, setDescription] = useState(
    existing?.vehicleOptions?.description
  );
  const [vehicle, setVehicle] = useState(existing?.vehicleOptions?.vehicle);
  const [mileage, setMileage] = useState(existing?.vehicleOptions?.mileage);
  const [options, setOptions] = useState(
    existing?.vehicleOptions?.options ?? []
  );
  const [exact, setExact] = useState(existing?.vehicleOptions?.exact);
  const [selectedOptions, setSelectedOptions] = useState(
    existing?.vehicleOptions?.selectedOptions ?? {}
  );

  const getVehicleDescription = (item: any) => {
    const { year, make, model, trim } = item._source || {};
    return `${year} ${make} ${model} ${trim}`;
  };

  const lookup = useCallback(async () => {
    if (description && description !== getVehicleDescription(vehicle || {})) {
      const { data } = await tradesService.autocomplete(description);
      return setLookupResults(data?.hits?.hits);
    }

    setLookupResults([]);
  }, [description, vehicle]);

  useEffect(() => {
    lookup();
  }, [lookup]);

  const createTrade = async () => {
    try {
      if (!exact?.id) return;
      const { id } = exact;
      const reportUrl = `https://snap-api.tradepending.com/api/v4/report-html?vehicle_id=${id}&partner_id=${config.snap.partnerId}&zip_code=${postalCode}&url=app.tecobi.com`
      await loadingIndicator.create();
      const miles = mileage?.replace?.(/\D+/gi, '') ?? 0;
      const { data } = await tradesService.createTradeIn(
        clientId,
        reportUrl,
        leadId,
        id,
        postalCode,
        miles

      );
      onTradeAdded?.(data);

      modalController.dismiss();
    } catch (e) {
      console.log(e);
      appNotification.toast(
        'There was a problem creating your trade in.',
        'Error'
      );
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const savePending = () => {
    onTradeProgressUpdate?.({
      vehicleOptions: {
        zip_code: postalCode,
        mileage,
        vehicle,
        description,
        options,
        selectedOptions,
        exact
      }
    });
  };

  return (
    <TModal
      title="Add Trade"
      isOpen={isOpen}
      onDidDismiss={() => {
        savePending();
        onDidDismiss?.();
      }}
      cssClass={cssClass}
      footer={
        <IonToolbar>
            {isPublic && (
              <IonButton
                disabled={!exact}
                slot="primary"
                size="large"
                color="success"
                expand="block"
                onClick={createTrade}
                style={{width: '100%'}}
              >
                Get Report
              </IonButton>
            )}
            {!isPublic && (
              <IonButtons slot="primary">
                <IonButton
                  disabled={!exact}
                  slot="primary"
                  color="success"
                  onClick={createTrade}
                >
                  Get Report
                </IonButton>
                <IonButton slot="secondary" color="primary" onClick={onDidDismiss}>
                  Cancel
                </IonButton>
              </IonButtons>
            )}
        </IonToolbar>
      }
    >
      <IonList
        style={{
          '--background': 'var(--ion-item-background)',
          minHeight: '100%'
        }}
      >
        {step === 0 && (
          <>
            <TInputItem
              label="Zip Code"
              labelPosition="stacked"
              value={postalCode}
              onBlur={() => savePending()}
              onChange={(e: any) => setPostalCode(e.detail.value)}
            />
            <IonButton
              slot="primary"
              size="small"
              color="success"
              expand="block"
              onClick={() => setStep(1)}
              style={{width: '100%'}}
            >
              Next
            </IonButton>
          </>
        )}
        {step === 1 && (
          <>
            <TInputItem
              label="Mileage"
              labelPosition="stacked"
              value={mileage}
              onBlur={() => savePending()}
              onChange={(e: any) => setMileage(e.detail.value)}
            />
            <IonButton
              slot="primary"
              size="small"
              color="success"
              expand="block"
              onClick={() => setStep(2)}
              style={{width: '100%'}}
            >
              Next
            </IonButton>
          </>
        )}
        <div style={{visibility: step === 2 ? 'visible' : 'hidden'}}>
          <TInputItem
            className="snap-typeahead"
            label="Year/Make/Model"
            labelPosition="stacked"
            value={description}
            results={lookupResults}
            renderText={getVehicleDescription}
            onSelected={async (item: any) => {
              setVehicle(item);
              setDescription(getVehicleDescription(item));
              const details = await tradesService.getVehicleDetails(
                item._source,
                postalCode
              );
              setSelectedOptions({});
              if (details.id) {
                setExact(details);
                setOptions([]);
              } else {
                setOptions([details]);
              }
              util.delay(savePending, 1000);
            }}
            onChange={(e: any) => setDescription(e.detail.value)}
          />
          {options?.map?.((option: any, index: number) => (
            <TSelectItem
              key={index}
              labelPosition="stacked"
              label={option?.select}
              className="ion-text-capitalize"
              options={option?.choices}
              value={selectedOptions?.[option?.select]}
              onChange={async (e: any) => {
                setSelectedOptions((opt: any) => {
                  opt = opt || {};
                  opt[option?.select] = e.detail.value;
                  return opt;
                });

                const details = await tradesService.getVehicleDetails(
                  vehicle._source,
                  postalCode,
                  selectedOptions
                );
                if (details.id) {
                  setExact(details);
                } else {
                  setOptions((opt: any) => {
                    const existing = opt.find(
                      (it: any) => it.select === details.select
                    );

                    if (existing) {
                      opt.splice(opt.indexOf(existing), 1);
                    }

                    return opt.concat([details]);
                  });
                }

                util.delay(savePending, 1000);
              }}
            />
          ))}
        </div>
      </IonList>
    </TModal>
  );
};

export default AddTradeModal;
