import { http } from '../core';
import PagedService from './paged-service';
import qs from 'querystring';

class SurveyService extends PagedService {
  async list() {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/survey/`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async listResults(clientId: any, leadId?: any) {
    const params = {} as any;

    if (leadId) {
      params.lead__id = leadId;
    }

    const query = qs.stringify(params);

    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/survey-responses/?${query}`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new SurveyService();
