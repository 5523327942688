import React, { useCallback, useState, useEffect } from 'react';
import { IonList, IonItem } from '@ionic/react';
import css from 'classnames';
import '../styles/components/AutoCompleteResults.scss';
import { useKeyboardShortcuts } from '../hooks';

export interface AutoCompleteResultsProps {
  visible?: boolean;
  results?: any;
  displayKey?: string;
  renderText?: (item: any) => string;
  style?: object;
  onSelected?: (item: any) => any;
  onSelectedIndexChanged?: (index: number) => any;
  onDidDismiss?: () => void;
  alignment?: 'start' | 'end';
}

const AutoCompleteResults: React.FC<AutoCompleteResultsProps> = ({
  onSelected,
  onDidDismiss,
  onSelectedIndexChanged,
  results,
  displayKey,
  renderText,
  style,
  visible,
  alignment
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectionMade = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      onSelected?.(results?.[selectedIndex]);
    },
    [results, onSelected, selectedIndex]
  );

  const choose = (item: any) => {
    onSelected?.(item);
  };

  const resultsChanged = useCallback(() => {
    setSelectedIndex(0);
    onSelectedIndexChanged?.(0);
  }, [onSelectedIndexChanged]);

  useEffect(resultsChanged, [results]);

  const adjustScroll = (index: number) => {
    const element = document.querySelector(
      '.auto-complete-results'
    ) as HTMLIonListElement;
    const currentPosition = element?.scrollTop ?? 0;
    const listHeight = element?.clientHeight ?? 0;
    const item = element?.childNodes?.[index] as HTMLElement;
    const itemHeight = item?.clientHeight ?? 0;
    const itemPosition = item?.offsetTop;

    if (itemPosition < currentPosition) {
      element.scrollTo(0, itemPosition);
    } else if (itemPosition + itemHeight > currentPosition + listHeight) {
      element.scrollTo(0, currentPosition + itemHeight);
    }
  };

  const up = useCallback(
    e => {
      e.preventDefault();
      const newIndex = Math.max(selectedIndex - 1, 0);
      setSelectedIndex(newIndex);
      onSelectedIndexChanged?.(newIndex);
      adjustScroll(newIndex);
    },
    [selectedIndex, setSelectedIndex, onSelectedIndexChanged]
  );

  const down = useCallback(
    e => {
      e.preventDefault();
      const newIndex = Math.min(selectedIndex + 1, (results?.length ?? 0) - 1);
      setSelectedIndex(newIndex);
      onSelectedIndexChanged?.(newIndex);
      adjustScroll(newIndex);
    },
    [results, selectedIndex, setSelectedIndex, onSelectedIndexChanged]
  );

  const esc = useCallback(
    e => {
      e.preventDefault();
      onDidDismiss?.();
    },
    [onDidDismiss]
  );

  useKeyboardShortcuts({
    tab: selectionMade,
    esc,
    up,
    down
  });

  if (!results || !results.length) {
    return null;
  }

  return (
    <IonList
      lines="full"
      className={css('auto-complete-results', {
        visible,
        'align-right': alignment === 'end'
      })}
      style={style}
    >
      {results?.map?.((it: any, index: number) => (
        <IonItem
          key={index}
          className={css({
            selected: index === selectedIndex
          })}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            choose(it);
          }}
        >
          {renderText
            ? renderText(it)
            : typeof it === 'string'
            ? it
            : it[displayKey ?? 'id']}
        </IonItem>
      ))}
    </IonList>
  );
};

export default AutoCompleteResults;
