import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  TTextItem,
  TReportsPage,
  TItemGroup,
  TNumberItem
} from '../components';
import { loadingIndicator } from '../core';
import { PaginatedLeadListModal, UserCallListModal, LockOutModal } from '../components/modals'
import { reportsService, lockOutService } from '../services';
import moment from 'moment';
import {
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonButton,
  IonIcon,
  IonAlert
} from '@ionic/react';
import { time, lockClosed, lockOpen } from 'ionicons/icons';
import { AppContext } from '../context/AppContext';
const NinjaReportPage: React.FC = () => {
  const { state: appState } = useContext(AppContext);
  const { user } = appState;
  const [showModal, setShowModal] = useState(false);
  const [showCallsModal, setShowCallsModal] = useState(false);
  const [lockOuts, setLockOuts] = useState<any>([])
  const [showLockOutModal, setShowLockOutModal] = useState({showModal: false, user: null});
  const [alert, setAlert] = useState({show: false, user: null as any, lockOut: null as any});
  const [lockOutChange, setLockOutChange] = useState<boolean>(true);
  const [modalData, setModalData] = useState<any>();
  const [callsModalData, setCallsModalData] = useState<any>();

  const fetchLockouts = useCallback(async () => {
      await loadingIndicator.create();
      try {
        const res = await lockOutService.list();
        setLockOuts(res?.results);
      } catch (e) {
        console.error(e);
      } finally {
        loadingIndicator.dismiss();
        setLockOutChange(false);
      }
  }, []);

  useEffect(() => {
    if(lockOutChange) {
      fetchLockouts();
    }
  }, [fetchLockouts, lockOutChange]);

  const loadData = useCallback(async ({ since, until, filters }: any) => {
    const { admins, contractors, isPhoneNinja, ninjaTeams } = filters;
    return await reportsService.retrieveNinjaReports(since, until, admins, contractors, isPhoneNinja, ninjaTeams)
  }, []);

  const openModal = (user: any, endpoint: string, dateTimeField: string, title: string) => {
    setModalData({ user, endpoint, dateTimeField, title });
    setShowModal(true);
  }

  const openCallsModal = (user: any, endpoint: string, dateTimeField: string, title: string) => {
    setCallsModalData({ user, endpoint, dateTimeField, title });
    setShowCallsModal(true);
  }

  return (
    <TReportsPage
      downloadData={(data: any) => data.ninjas}
      onLoadData={loadData}
      filterProps={{
        hideClassification: true,
        showAdminFilter: true,
        showContractorFilter: true,
        showPhoneNinjaFilter: true,
        showNinjaTeamsFilter: true}}
    >
      {({ state }) => (
        <IonList className="reports no-padding no-margin" lines="full">
          <TItemGroup title="Ninja Totals">
            <TNumberItem label="Total SMS" number={state.data.total_sms} />
            <TTextItem
              label="SMS Per Hour"
              text={Math.round(state.data.sms_per_hour)}
            />
            <TTextItem
              label="Set Appointments"
              text={state.data.total_appointments}
            />
            <TNumberItem
              label="Cancelled Appointments"
              number={state.data.cancelled_appointments}
            />
            <TNumberItem
              label="Net Appointments"
              number={state.data.net_appointments}
            />
            <TNumberItem
              label="Shown Appointments"
              number={state.data.shown_appointments}
            />
            <TNumberItem
              label="Sold Appointments"
              number={state.data.sold_appointments}
            />
            <TNumberItem
              label="Touched Leads"
              number={state.data.total_touched_leads}
            />
          </TItemGroup>

          {state.data.ninjas?.map((it: any) => {
            const lockOut = lockOuts.find((lockOut: any) => it.id === lockOut.user);
            return (
            <IonItemGroup key={it.id}>
              <IonItemDivider sticky>
                <IonIcon
                  slot="start"
                  style={{ marginRight: 5 }}
                  size="small"
                  color={it.clocked_in ? 'success' : 'danger'}
                  icon={time}
                />
                <IonLabel>{`${it.first_name} ${it.last_name}`}</IonLabel>
                {user.is_staff && (user.is_manager || user.is_superuser) && (
                  <>
                    <IonButton
                      onClick={
                        () => {
                          if (lockOut) {
                            setAlert({show: true, user: it, lockOut: lockOut});
                          } else {
                            setShowLockOutModal({showModal: true, user: it});
                          }
                        }
                      }
                      slot="end"
                      style={{ marginRight: 5 }}
                      color={!!lockOut ? 'danger' : 'primary'}
                    >
                      <IonIcon
                        slot="start"
                        size="small"
                        icon={!!lockOut ?  lockOpen : lockClosed}
                      />
                      {!!lockOut ? 'Unlock' : 'Lock Out'}
                    </IonButton>
                  </>
                )}
              </IonItemDivider>
              <IonGrid className="users-grid">
                <IonRow className="column-header">
                  <IonCol>Clocked In</IonCol>
                  <IonCol>Total SMS</IonCol>
                  <IonCol>SMS Avg/hr</IonCol>
                  <IonCol>Set Appts</IonCol>
                  <IonCol>Touched Leads</IonCol>
                  <IonCol>Internal Msgs</IonCol>
                  <IonCol>Outbound Calls</IonCol>
                  <IonCol>Avg Calls/hr</IonCol>
                  <IonCol>Avg Call Time</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{it.time_clocked_in}</IonCol>
                  <IonCol>{it.total_sms}</IonCol>
                  <IonCol>{Math.round(it.sms_per_hour)}</IonCol>
                  <IonCol
                    style={{cursor: 'pointer'}}
                    onClick={(e: any) => {
                      openModal(it, 'appointment-leads', 'created_at', 'Set Appts');
                    }}
                  >
                    {it.total_appointments}
                  </IonCol>
                  <IonCol
                    style={{cursor: 'pointer'}}
                    onClick={(e: any) => {
                      openModal(it, 'touched-leads-report', 'created', 'Touched Leads');
                    }}
                  >{
                    it.touched_leads_count}
                  </IonCol>
                  <IonCol>{it.internal_sms}</IonCol>
                  <IonCol
                    style={{cursor: 'pointer'}}
                    onClick={(e: any) => {
                      openCallsModal(it, 'call-report', 'created_at', 'Calls');
                    }}
                  >
                    {it.outbound_calls}
                  </IonCol>
                  <IonCol>{!!it.seconds_clocked_in ? (it.outbound_calls/(it.seconds_clocked_in/3600)).toFixed(2) : 0}</IonCol>
                  <IonCol>{moment.utc(it.avg_outbound_call_duration * 1000).format('mm:ss')}</IonCol>
                </IonRow>
                <IonRow className="column-header">
                  <IonCol>Cancelled Appts</IonCol>
                  <IonCol>Net Appts</IonCol>
                  <IonCol>Shown Appts</IonCol>
                  <IonCol>Sold Appts</IonCol>
                  <IonCol>Msgs per Lead</IonCol>
                  <IonCol>External Msgs</IonCol>
                  <IonCol>Avg Daily Q Time</IonCol>
                  <IonCol>Needs Calls</IonCol>
                  <IonCol/>
                </IonRow>
                <IonRow>
                  <IonCol
                    style={{cursor: 'pointer'}}
                    onClick={(e: any) => {
                      openModal(it, 'appointment-leads', 'cancelled_or_missed', 'Cancelled Appts');
                    }}
                  >
                    {it.cancelled_appointments}
                  </IonCol>
                  <IonCol>{it.net_appointments}</IonCol>
                  <IonCol
                    style={{cursor: 'pointer'}}
                    onClick={(e: any) => {
                      openModal(it, 'appointment-leads', 'showed', 'Shown Appts');
                    }}
                  >
                    {it.shown_appointments}
                  </IonCol>
                  <IonCol
                    style={{cursor: 'pointer'}}
                    onClick={(e: any) => {
                      openModal(it, 'appointment-leads', 'sold', 'Sold Appts');
                    }}
                  >
                    {it.sold_appointments}
                  </IonCol>
                  <IonCol>{it.messages_per_lead}</IonCol>
                  <IonCol>{it.external_sms}</IonCol>
                  <IonCol title={`${it.days_worked} days worked`}>{it.avg_daily_time_in_queue}</IonCol>
                  <IonCol
                    style={{cursor: 'pointer'}}
                    onClick={(e: any) => {
                      openModal(it, 'needs-call-leads', 'created_at', 'Needs Calls');
                    }}
                  >
                    {it.needs_calls}
                  </IonCol>
                  <IonCol/>
                </IonRow>
              </IonGrid>
            </IonItemGroup>
          )})}
          {showModal && (
            <PaginatedLeadListModal
              isOpen={true}
              userId={modalData?.user?.id}
              since={state.since}
              until={state.until}
              tableTitle={modalData?.title}
              title={`${modalData?.user?.first_name} ${modalData?.user?.last_name}`}
              onDidDismiss={() => {setShowModal(false)}}
              endpoint={modalData?.endpoint}
              dateTimeField={modalData?.dateTimeField}
            />
          )}
          {showLockOutModal?.showModal && (
            <LockOutModal
              user={showLockOutModal.user}
              onDidDismiss={() => {
                setShowLockOutModal({showModal: false, user: null });
                setLockOutChange(true);
              }}
            />
          )}
          {showCallsModal && (
            <UserCallListModal
              isOpen={true}
              userId={callsModalData?.user?.id}
              since={state.since}
              until={state.until}
              tableTitle={callsModalData?.title}
              title={`${callsModalData?.user?.first_name} ${callsModalData?.user?.last_name}`}
              onDidDismiss={() => {setShowCallsModal(false)}}
              endpoint={callsModalData?.endpoint}
              dateTimeField={callsModalData?.dateTimeField}
            />
          )}
          <IonAlert
            isOpen={alert.show}
            onDidDismiss={() => setAlert({show: false, user: null, lockOut: null})}
            header={`Unlock ${alert.user?.first_name} ${alert.user?.last_name}?`}
            message={alert.lockOut?.reason}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: blah => console.log('Confirm Cancel')
              },
              {
                text: 'Unlock',
                handler: async () => {
                  await lockOutService.delete(alert.lockOut?.id);
                  setLockOutChange(true);
                }
              }
            ]}
          />
        </IonList>
      )}
    </TReportsPage>
  );
};

export default NinjaReportPage;
