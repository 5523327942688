import React, { useState, useContext, useRef, useCallback } from 'react';
import {
  TInfiniteScrollPage,
  TextMessageRow,
  TSearchBar,
  TFab,
  HapticButton
} from '../components';
import { leadsService } from '../services';
import {
  funnel,
  notifications,
  arrowDown,
  add,
  closeCircle
} from 'ionicons/icons';
import {
  IonIcon,
  IonItemDivider,
  IonItem,
  IonChip,
  IonLabel,
  IonBadge,
  IonGrid,
  IonRow,
  IonButton,
  IonCol
} from '@ionic/react';
import { AppContext } from '../context/AppContext';
import { LeadContext } from '../context/LeadContext';
import { haptics } from '../core';
import { AddLeadModal, LeadFiltersModal } from '../components/modals';
import { useLiveDBRef, useDebounce, useDataLoader } from '../hooks';
import _ from 'lodash';

const TextMessagesPage: React.FC = () => {
  const searchElement = useRef<any>();
  const { state, dispatch } = useContext(AppContext);
  const { leadSorting, selectedClientId } = state;
  const leadContext = useContext(LeadContext);
  const { dispatch:leadDispatch, resetFilters } = leadContext;
  const { filters } = leadContext.state;
  const filtersKeys = Object.keys(filters);
  const [searchValue, setSearchValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [inboundOnly, setInboundOnly] = useState(false);
  const sortAscending = leadSorting !== 'Newest First';
  const [showAddLeadModal, setShowAddLeadModal] = useState(false);

  const loadData = useCallback(async () => {
    return inboundOnly
      ? await leadsService.listWaiting({
          clientId: state.selectedClientId,
          search: searchValue,
          sorting: leadSorting,
          exclusive: true
        })
      : await leadsService.list({
          clientId: state.selectedClientId,
          search: searchValue,
          filters,
          sorting: leadSorting,
          exclusive: true
        });
  }, [state.selectedClientId, searchValue, leadSorting, filters, inboundOnly]);

  const {
    error,
    next,
    loading,
    data,
    refresher,
    loadNext,
    dispatch: dataDispatch,
    forceRefresh
  } = useDataLoader(loadData, true);
  const onTextMessageChange = useDebounce(async (e: any) => {
    if (selectedClientId && !sortAscending) {
      const opts = {
        clientId: selectedClientId
      };

      const updateResults = (results: any) => {
        dataDispatch({
          type: 'dataFunction',
          value: (cur: any = []) =>
            _.sortBy(
              _.uniqBy([...results, ...cur], (it: any) => it.id),
              it => it.updated
            )
        });
      };

      const inbound = await leadsService.listWaiting(
        Object.assign(opts, { pageSize: inboundOnly ? 100 : 1 })
      );

      const clientNeedsCallCount = await leadsService.loadNeedsCall(state.selectedClientId);

      dispatch({
        type: 'inboundUpdated',
        value: inbound
      });

      dispatch({
        type: 'needsCallCountUpdated',
        value: clientNeedsCallCount?.clientNeedsCallCount
      });

      if (inboundOnly) {
        updateResults(inbound.results);
        // After sending outbound text, hitting "back" button
        // when inboundOnly is true, clientInboundCount correctly decrements
        // while inbound.results do not update, so forceRefresh() fixes it.
        if (inbound.results.length < state.clientInboundCount) {
          // commenting out because its causing refreshing constantly during blast send
          //forceRefresh();
        }
      } else {
        const all = await leadsService.list(
          Object.assign(opts, { filters, search: searchElement.current?.value })
        );
        updateResults(all.results);
      }
    }
  }, 4000);

  useLiveDBRef(
    `/text-messages/conversation/${selectedClientId}/`,
    onTextMessageChange
  );

  return (
    <TInfiniteScrollPage
      loading={loading}
      toolbarLoadingIndicator={searching && loading}
      toolbar={
        <>
          <IonItemDivider>
            <TSearchBar
              ref={searchElement}
              onSearch={(search: string) => {
                // If a number is detected, strip out spaces, dashes, dots, parenthesis, commas
                if (/\d/.test(search)) {
                  search = search.replace(/-|[{()}]|\.|,|\s/g,"");
                }
                if (search === searchValue) {
                  forceRefresh();
                }
                setSearchValue(search);
                setSearching(true);
              }}
              placeholder="Search Text Messages"
            />
            <HapticButton
              icon={funnel}
              onClick={() => {
                leadContext.dispatch({
                  type: 'set',
                  value: { showLeadFilters: true }
                });
              }}
            />
          </IonItemDivider>
          {(inboundOnly || sortAscending || filtersKeys.length > 0) && (
            <div className="filters-row">
              {inboundOnly && (
                <IonChip color="primary">
                  <IonIcon icon={notifications} />
                  <IonLabel>Customers Waiting</IonLabel>
                  <IonBadge mode="ios">{state.clientInboundCount}</IonBadge>
                  <IonIcon
                    icon={closeCircle}
                    onClick={() => {
                      setInboundOnly(false);
                      resetFilters();
                    }}
                  />
                </IonChip>
              )}
              {sortAscending && (
                <IonChip color="primary">
                  <IonIcon icon={arrowDown} />
                  <IonLabel>Oldest Messages First</IonLabel>
                  <IonIcon
                    icon={closeCircle}
                    onClick={() =>
                      dispatch({
                        type: 'set',
                        value: { leadSorting: 'Newest First' }
                      })
                    }
                  />
                </IonChip>
              )}
              {!inboundOnly &&
                filtersKeys.map(
                  (k: string) =>
                    filters[k] !== '' && (
                      <IonChip color="primary" key={k}>
                        <IonIcon icon={leadContext.filterIcon(k)} />
                        <IonLabel>
                          {leadContext.filterDisplay(k, filters[k])}
                        </IonLabel>
                        <IonIcon
                          icon={closeCircle}
                          onClick={() => {
                            delete filters[k];
                            leadContext.dispatch({
                              type: 'set',
                              value: {
                                filters: { ...filters }
                              }
                            });
                          }}
                        />
                      </IonChip>
                    )
                )}
            </div>
          )}
          {(state.clientInboundCount > 0 || state.clientNeedsCallCount > 0) && (!inboundOnly && !filters.needs_call) && (
            <IonItem className="inbound">
              <IonGrid>
                <IonRow justify-content-start align-items-start>
                {(state.clientInboundCount > 0) && (
                  <IonCol>
                    <IonButton
                      expand="full"
                      color="secondary"
                      onClick={() => setInboundOnly(true)}
                    >
                      {state.clientInboundCount} needing text
                    </IonButton>
                  </IonCol>
                )}
                {(state.clientNeedsCallCount > 0 && true) && (
                  <IonCol>
                    <IonButton
                      expand="full"
                      color="orange"
                      onClick={() => {
                        setInboundOnly(false);
                        const changes = {
                            "needs_call": "Call Needed",
                        } as any;
                        leadDispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes }
                          }
                        });
                      }
                    }
                    >
                      {state.clientNeedsCallCount} needing call
                    </IonButton>
                  </IonCol>
                )}
                </IonRow>
              </IonGrid>
            </IonItem>
          )}
        </>
      }
      items={data}
      error={error}
      hasNext={!!next}
      fab={
        state.selectedClient.default_number ? (
          <TFab
            icon={add}
            onClick={() => {
              haptics.lightImpact();
              setShowAddLeadModal(true);
            }}
          />
        ) : (
          undefined
        )
      }
      onRefresh={refresher}
      zeroStateText="No Text Messages Found."
      onLoadNextPage={loadNext}
      renderItem={(item: any) => <TextMessageRow key={item.id} item={item} />}
    >
      <LeadFiltersModal />
      {showAddLeadModal && (
        <AddLeadModal
          isOpen={true}
          onDidDismiss={() => setShowAddLeadModal(false)}
          onCreated={() => refresher()}
        />
      )}
    </TInfiniteScrollPage>
  );
};

export default TextMessagesPage;
