import React, { useContext, useMemo } from 'react';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import moment from 'moment';
import TAvatar from './TAvatar';
import { creditApplicationsService } from '../services';
import { AppContext } from '../context/AppContext';
import { appNotification } from '../core';

export interface CreditApplicationRowProps {
  item: any;
}

const CreditApplicationRow: React.FC<CreditApplicationRowProps> = ({
  item
}) => {
  const { state } = useContext(AppContext);
  const { user } = state;
  const { id, lead, created_at, cobuyer_lead } = item;
  const { full_name, script, lead_status, assigned_to } = lead;
  const canViewCreditApps = useMemo(() => {
    return (
      user &&
      (user.is_admin || user.is_client_admin || user.can_view_credit_apps)
    );
  }, [user]);

  return (
    <IonItem
      className="ion-activatable pointer"
      onClick={() =>
        canViewCreditApps
          ? creditApplicationsService.openApplicationPDF(id)
          : appNotification.alertSimple(
              'You do not have permission to view credit applications',
              'Error'
            )
      }
    >
      <TAvatar slot="start" alt={full_name} />
      <IonLabel>
        <IonText className="ion-multiline">
          <h2>{full_name}</h2>
          <p className="subtitle ion-text-wrap">
            {cobuyer_lead && `${cobuyer_lead.full_name} (co-buyer) • `}
            {lead_status} • {script} • {assigned_to}
          </p>
          <span className="detail">{moment(created_at).fromNow()}</span>
        </IonText>
      </IonLabel>
    </IonItem>
  );
};

export default CreditApplicationRow;
