import PagedService from './paged-service';
import { http } from '../core';
import qs from 'querystring';


class ContestsService extends PagedService {

  async list(params?: any) {
    try {

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/contests/?${query}`,
        type: 'followup-configs',
        exclusive: true
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async get(id: string) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/contests/${id}/`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getStandings(contestId: string) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/contests/user-counts/?contest=${contestId}&ordering=-points`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

}

export default new ContestsService();
