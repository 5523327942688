import React, { useCallback, useState, useContext, useEffect } from 'react';
import { TReportsPage, TChart, TSelectItem } from '../components';
import { reportsService } from '../services';
import { AppContext } from '../context/AppContext';

import {
  IonList,
  IonItem,
  IonLabel,
  IonBadge
} from '@ionic/react';

const LeadSourcesPage: React.FC = () => {
  const appState = useContext(AppContext).state;
  const { clientNumbers, selectedClientId } = appState;
  const [twilioNumber, setTwilioNumber] = useState('');
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // TODO: This causes a quick "canceled" request with the previous selected client's twilio number
  useEffect(() => {
    setTwilioNumber('');
  }, [selectedClientId]);

  const loadData = useCallback(async ({ since, until, clientId }: any) => {
    const [smsDeliveryStatusCounts] = await Promise.all([
      reportsService.retrieveSmsDeliveryReports(clientId, since, until, {twilio_number: twilioNumber})
    ]);

    return {
      smsDeliveryStatusCounts
    };
  }, [twilioNumber]);

  return (
    <TReportsPage
      onLoadData={loadData}
      filterProps={{ hideClassification: true }}
    >
      {({ state }) => {
        const totals: any = Object.values(state.data?.smsDeliveryStatusCounts);
        const keys: any = Object.keys(state.data?.smsDeliveryStatusCounts).map?.((it: any) => it.split('_count')[0]);
        return (
          <TChart
            type="pie"
            title="SMS Status Counts"
            data={totals}
            labels={keys}
            legend
          >
            <IonList lines="full" className="reports">
              <TSelectItem
                label="Phone Number"
                value={twilioNumber}
                onChange={(e: any) => setTwilioNumber(e.detail.value)}
                options={[{value: '', text: 'All'}].concat(clientNumbers.map((it: any) => ({
                  value: it.id,
                  text: `${it.twilio_number} ${it.script.name}`
                })))}
              />
              {Object.entries(state.data?.smsDeliveryStatusCounts).map((it: any) => (
                <IonItem key={it[0]}>
                  <IonLabel>{capitalize(it[0].split('_')[0])}</IonLabel>
                  <IonBadge mode="ios">
                    {it[1]}
                  </IonBadge>
                </IonItem>
              ))}
            </IonList>
          </TChart>
        );
      }}
    </TReportsPage>
  );
};

export default LeadSourcesPage;
