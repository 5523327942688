import { http } from '../core';

class ClientEmployeeService {
  async list(clientId: number) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/employees/?include_phone_ninjas=true`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new ClientEmployeeService();
