import React, { useContext, useState, useEffect } from 'react';
import '../../styles/components/ReportFilters.scss';
import TModal from './TModal';
import { IonList, IonButton } from '@ionic/react';
import TSelectItem from '../TSelectItem';
import ButtonGrid from '../ButtonGrid';
import { AppContext } from '../../context/AppContext';
import TToggleItem from '../TToggleItem';
import { loadingIndicator, appNotification, http } from '../../core';
import { clientNumbersService } from '../../services';

export interface EditClientNumberModalProps {
  number: any;
  onDidDismiss: () => any;
}

const EditClientNumberModal: React.FC<EditClientNumberModalProps> = ({
  number,
  onDidDismiss
}) => {
  const { state } = useContext(AppContext);
  const { clientUsers, selectedClientId } = state;
  const [phoneNumber, setPhoneNumber] = useState(number);

  useEffect(() => setPhoneNumber(number), [number]);

  const updateField = (key: string) => (e: CustomEvent) => {
    const val = e.detail.checked ?? e.detail.value;
    setPhoneNumber((n: any) => ({ ...n, [key]: val }));
  };

  const save = async () => {
    try {
      await loadingIndicator.create();
      await clientNumbersService.update(selectedClientId, number.id, {
        call_notifications: phoneNumber.call_notifications,
        sms_notifications: phoneNumber.sms_notifications,
        user: phoneNumber.user
      });
      appNotification.toast('Phone number preferences updated.', 'Success');
      onDidDismiss();
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <TModal
      cssClass="edit-client-number-modal"
      isOpen={true}
      title={`Edit ${phoneNumber.script.name} ${phoneNumber.twilio_number}`}
      onDidDismiss={onDidDismiss}
      footer={
        <ButtonGrid>
          <IonButton color="secondary" expand="block" onClick={save}>
            Save
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList lines="full">
        <TSelectItem
          label="Assignment"
          value={phoneNumber.user ?? ''}
          onChange={updateField('user')}
          options={[
            { value: '', text: 'Unassigned' },
            ...clientUsers.map((it: any) => ({
              value: it.id,
              text: it.full_name
            }))
          ]}
        />
        <TToggleItem
          label="Call Notifications"
          checked={!!phoneNumber.call_notifications}
          onIonChange={updateField('call_notifications')}
        />
        <TToggleItem
          label="SMS Notifications"
          checked={!!phoneNumber.sms_notifications}
          onIonChange={updateField('sms_notifications')}
        />
      </IonList>
    </TModal>
  );
};

export default EditClientNumberModal;
