import {
  userService,
  clientsService,
  clientService,
  clientUsersService,
  clientGroupsService,
  clientHoursService,
  clientNumbersService,
  clientProvidersService,
  statusesService,
  ninjaTeamsService,
  jobTitlesService,
  languagesService,
  pushNotifications,
  clientMentionUsersService,
  leadsService,
  quickReplyCategoriesService,
  shortcodesService
} from '../services';
import updates from './updates';
import device from './device';
import authorization from './authorization';

class Data {
  private async verifySelectedClientIdExists(clientId: any, clients: any) {
    const selectedClient = clients.find((it: any) => it.id === clientId);

    if (!selectedClient) {
      clientId = clients[0].id as number;
      await authorization.setSelectedClientId(clientId);
    }

    return clientId;
  }

  private toState(res: any[], start: any = {}) {
    return res.reduce((o: any, d: any) => Object.assign(o, d), start);
  }

  evictCache() {
    window.dispatchEvent(new CustomEvent('cache:evict'));
  }

  async load() {
    const clients = await clientsService.load();
    const user = await userService.load();
    let clientId = await authorization.getSelectedClientId();

    if (clients.clients?.length === 0) {
      authorization.clear();
      return window.location.reload(true);
    }

    clientId = await this.verifySelectedClientIdExists(
      clientId,
      clients.clients
    );

    const promises = [
      statusesService.load(),
      ninjaTeamsService.load(),
      jobTitlesService.load(),
      languagesService.load(),
      this.loadClientInfo(clientId),
      updates.load(),
      device.load(),
      quickReplyCategoriesService.load(),
      shortcodesService.load(),
      pushNotifications.loadUnread()
    ];

    const res = await Promise.all(promises);

    res.push(clients, user);

    return this.toState(res, {
      selectedClientId: clientId,
      isClockedIn: user.user.is_clocked_in
    });
  }

  async loadClientInfo(clientId: any) {
    const res = await Promise.all([
      clientGroupsService.load(clientId),
      clientUsersService.load(clientId),
      clientHoursService.load(clientId),
      clientNumbersService.load(clientId),
      clientProvidersService.load(clientId),
      clientMentionUsersService.load(clientId),
      clientService.load(clientId),
      leadsService.loadWaiting(clientId),
      leadsService.loadNeedsCall(clientId)
    ]);
    return res.reduce((o: any, d: any) => Object.assign(o, d), {});
  }
}

export default new Data();
