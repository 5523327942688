import { IonApp, setupConfig, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import React, { useCallback, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { AppContextConsumer, AppContextProvider } from './context/AppContext';
import { initialization, native, authorization } from './core';
import { useEventListener } from './hooks';
import { AppLoading, Offline } from './pages';
import './styles/app.scss';
import './styles/variables.scss';
import AuthorizedView from './AuthorizedView';
import UnauthorizedView from './UnauthorizedView';
//@ts-ignore
import { iosTransitionAnimation } from '@ionic/core/dist/collection/utils/transition/ios.transition';
import { PushNotificationActions } from './services/push-notifications';

setupConfig({
  swipeBackEnabled: !isPlatform('mobileweb'),
  statusTap: true,
  navAnimation: iosTransitionAnimation
});

const App: React.FC = () => {
  const [initial, setInitial] = useState<any>();
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const onKeyboardWillShow = useCallback(
    ({ detail }: any) => setKeyboardHeight(detail?.keyboardHeight ?? 0),
    []
  );

  const onKeyboardWillHide = useCallback(() => setKeyboardHeight(0), []);

  useEventListener('keyboardWillShow', onKeyboardWillShow);
  useEventListener('keyboardWillHide', onKeyboardWillHide);

  const initialize = useCallback(async () => {
    setInitial(await initialization.onAppLaunched());
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <IonApp
      style={{
        transition: 'height cubic-bezier(.55,0,.52,.99) 211ms',
        transform: 'translate3d(0)',
        height:
          keyboardHeight && native.isIOS
            ? `calc(100vh - ${keyboardHeight}px + env(safe-area-inset-bottom, 0))`
            : undefined
      }}
    >
      <IonReactRouter>
        {initial ? (
          <AppContextProvider initialState={initial}>
            <AppContextConsumer>
              {({ state }) => {
                if (!state.isOnline) {
                  return <Route exact component={Offline} />;
                }

                if (state.authorized) {
                  return <AuthorizedView initialState={initial} />;
                }

                return <UnauthorizedView />;
              }}
            </AppContextConsumer>
          </AppContextProvider>
        ) : (
          <AppLoading />
        )}
        <Route
          exact
          path="/logout/"
          component={() => {
            (async () => {
              await authorization.clear();
              window.location.href = '/';
            })();
            return null;
          }}
        />
        <PushNotificationActions />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
