import { http } from '../core';

class EventTypesService {
  async list() {
    try {
      const { data } = await http.authorizedRequest({
        url: '/event-types/',
        exclusive: true,
        type: 'auth-user-event-types'
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async get(id: number) {
    try {
      const { data } = await http.authorizedRequest({
        url: `/event-types/${id}/`,
        exclusive: true,
        type: `user-calendar-event-type-${id}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(data: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/event-types/`,
        data
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async update(id: number, data: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: `/event-types/${id}/`,
        data
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async delete(id: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/event-types/${id}/`
      });
      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

}
export default new EventTypesService();
