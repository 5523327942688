import React, { useCallback, useEffect, useState } from 'react';
import { TPage } from '../components';
import { contestantDetailsService } from '../services';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonRow,
  IonCol,
  IonBadge,
  IonButton
} from '@ionic/react';
import { useRouter } from '../hooks';
const ContestantDetails: React.FC = () => {

  const router = useRouter();

  const [data, setData] = useState<any>();

  const goToItemList = (objectType: string) => router.push(`./${objectType}/`);

  const { contestantId } = router.query;

  const getData = useCallback(async () => {
    try {
      const res = await contestantDetailsService.get(contestantId);
      setData(res);
    } catch (e) {}
  }, [contestantId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <TPage>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>{data?.user_full_name}</IonCardTitle>
          <IonCardSubtitle>{data?.client_name}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          {data?.client_city}, {data?.client_state}
        </IonCardContent>
        <IonRow>
          <IonCol>
            <IonButton fill="clear" color="secondary" onClick={() => goToItemList('sms')}>SMS<IonBadge color="secondary">{data?.sms_count}</IonBadge></IonButton>
          </IonCol>
          <IonCol>
          <IonButton fill="clear" color="tertiary" onClick={() => goToItemList('call')}>Call<IonBadge color="tertiary">{data?.call_count}</IonBadge></IonButton>
          </IonCol>
          <IonCol>
          <IonButton fill="clear" color="warning" onClick={() => goToItemList('pause')}>Pause<IonBadge color="warning">{data?.pause_count}</IonBadge></IonButton>
          </IonCol>
          <IonCol>
          <IonButton fill="clear" color="orange" onClick={() => goToItemList('needs-call-response')}>Needs Call Repsonse<IonBadge color="orange">{data?.needs_call_response_count}</IonBadge></IonButton>
          </IonCol>
        </IonRow>
      </IonCard>
    </TPage>
  );
};

export default ContestantDetails;
