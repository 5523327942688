import PagedService from './paged-service';
import { http } from '../core';
import qs from 'querystring';


class ContestantDetailsService extends PagedService {

  async list( snapshotId: number, objectType: string) {
    try {
      const params = {} as any;

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/contests/user-counts/${snapshotId}/${objectType}/?${query}`,
        type: `contestant-details-items-${objectType}`,
        exclusive: true
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async get( snapshotId: number) {
    try {

      const { data } = await http.authorizedRequest({
        url: `/contests/user-counts/${snapshotId}/`,
        type: `contestant-details-${snapshotId}`,
        exclusive: true
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new ContestantDetailsService();
