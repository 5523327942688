import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  useContext
} from 'react';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonButtons,
  IonToolbar
} from '@ionic/react';
import '../styles/pages/PersonalizedLead.scss';
import {
  PersonalizedLeadFeaturedVehicle,
  PersonalizedLeadInventory,
  PersonalizedLeadTrades,
  PersonalizedLeadAppointment,
  PersonalizedLeadChecklist,
  PersonalizedLeadTeamInfo,
  PersonalizedLeadDirections,
  PersonalizedLeadFeaturedVideo,
  PersonalizedLeadFeaturedAppointment,
  PersonalizedLeadVideos,
  TMenuButton,
  THeaderLogo
} from '../components';
import { PersonalizedLeadContextProvider } from '../context/PersonalizedLeadContext';
import { useParams } from 'react-router';
import { leadsService, personalizedFeaturesService } from '../services';
import PersonalizedLeadCollapsedHeader from '../components/PersonalizedLeadCollapsedHeader';
import { useWindowSize } from '../hooks';
import { AppContext } from '../context/AppContext';

const PersonalizedLead: React.FC = () => {
  const content = useRef<any>();
  const { token, feature, id } = useParams() as any;
  const { state: appState } = useContext(AppContext);
  const [initialState, setInitialState] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [showHeader, setShowHeader] = useState(false);
  const { width } = useWindowSize();

  const loadData = useCallback(async () => {
    document.title = 'Loading...';
    setLoading(true);
    const lead = await leadsService.loadByToken(token);
    const featureData = await personalizedFeaturesService.loadFeatureData(
      lead,
      feature,
      id
    );

    const state = {
      lead,
      feature,
      featureData,
      token
    };

    setInitialState(state);
    document.title = `${state.lead?.first_name} ${state?.lead?.last_name} | ${state?.lead?.client?.name}`;
    setLoading(false);
  }, [token, feature, id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onScroll = ({ detail }: any) => {
    const { scrollTop } = detail;
    setShowHeader(scrollTop > 100);
  };

  const isCollapsed = useMemo(() => width && width < 768, [width]);

  const columns = useMemo(() => {
    return {
      left: isCollapsed
        ? [
            PersonalizedLeadChecklist,
            PersonalizedLeadAppointment,
            PersonalizedLeadTeamInfo,
            PersonalizedLeadInventory,
            PersonalizedLeadTrades
          ]
        : [
            PersonalizedLeadInventory,
            PersonalizedLeadTrades,
            PersonalizedLeadVideos
          ],
      right: isCollapsed
        ? [PersonalizedLeadDirections, PersonalizedLeadVideos]
        : [
            PersonalizedLeadAppointment,
            PersonalizedLeadTeamInfo,
            PersonalizedLeadChecklist,
            PersonalizedLeadDirections
          ]
    };
  }, [isCollapsed]);

  const renderSection = (Component: any, i: number) => <Component key={i} />;

  return (
    <IonPage className="personalized-lead">
      {appState.authorized && (
        <IonHeader>
          <IonToolbar>
            <IonButtons className="menu-buttons" slot="start">
              <TMenuButton />
            </IonButtons>
            <THeaderLogo />
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent ref={content} scrollEvents onIonScroll={onScroll}>
        {!loading && (
          <PersonalizedLeadContextProvider initialState={initialState}>
            <PersonalizedLeadCollapsedHeader visible={showHeader} />
            <IonGrid>
              <IonRow>
                <IonCol
                  sizeXl="8"
                  sizeLg="8"
                  sizeMd="8"
                  sizeSm="12"
                  sizeXs="12"
                  className="featured-content"
                >
                  <h1 className="welcome-text">
                    <strong>Welcome</strong>, {initialState?.lead?.first_name}!
                  </h1>
                  <PersonalizedLeadFeaturedVideo />
                  <PersonalizedLeadFeaturedVehicle />
                  <PersonalizedLeadFeaturedAppointment />
                  {columns.left.map(renderSection)}
                </IonCol>
                <IonCol
                  sizeXl="4"
                  sizeLg="4"
                  sizeMd="4"
                  sizeSm="12"
                  sizeXs="12"
                >
                  {columns.right.map(renderSection)}
                </IonCol>
              </IonRow>
            </IonGrid>
          </PersonalizedLeadContextProvider>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PersonalizedLead;
