import React, { useState, useContext, useCallback, useEffect } from 'react';
import TModal from './TModal';
import moment from 'moment';
import { modalController } from '@ionic/core';
import { IonButton, IonIcon, IonItem, IonGrid, IonRow, IonCol } from '@ionic/react';
import { TDatePicker, TInput } from '../';
import '../../styles/components/PauseModal.scss';
import { leadsService, quickRepliesService, clientHoursService } from '../../services';
import { LeadContext, scrollToBottom } from '../../context/LeadContext';
import { AppContext } from '../../context/AppContext';
import { loadingIndicator, util, appNotification } from '../../core';
import { warning, flash } from 'ionicons/icons';
import TBanner from '../TBanner';
import TToggleItem from '../TToggleItem';
import TButtonItem from '../TButtonItem';
import QuickRepliesModal from './QuickRepliesModal';
export interface PauseModalProps {
  isOpen?: boolean;
  onDidDismiss?: () => void;
}

const PauseModal: React.FC<PauseModalProps> = ({
  onDidDismiss,
  isOpen = false
}) => {
  const { state, dispatch, createNewInternalMessage } = useContext(LeadContext);
  const appContext = useContext(AppContext);
  const { clientHours, lead } = state;
  const { user } = appContext.state;
  const [pauseDate, setPauseDate] = useState<string>(new Date().toISOString());
  const [needsCall, setNeedsCall] = useState<boolean>(false);
  const [showQuickReplies, setShowQuickReplies] = useState<boolean>(false)
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState<any>();

  const [shortcodes, setShortcodes] = useState<any>();
  const [selectedShortCodeIndex, setSelectedShortCodeIndex] = useState(0);

  const timezone = leadsService.getTimezone(lead);

  const fillShortcode = (e?: any) => {
    const code = e ?? shortcodes[selectedShortCodeIndex];
    const reply = appContext.state.shortcodes[code];
    if (reply?.text) {
      setMessage(
        `${message.substring(
          0,
          message.lastIndexOf(':')
        )}${quickRepliesService.renderedMessageText(reply, lead)}`
      );
    } else {
      setMessage(e.detail?.value);
    }
    setShortcodes([]);
  };

  const doFillShortcode = (e: any) => {
    if (shortcodes?.length) {
      return fillShortcode();
    }
  };

  const searchShortcodes = async (value: string) => {
    const keys = Object.keys(appContext.state.shortcodes);
    const search = value.substring(value.lastIndexOf(':'));
    setShortcodes(keys.filter(it => it.match(util.regexpEscape(search))));
  };

  const checkShortcodes = (value: string) => {
    const shouldShowShortcut = !!value.match(/(^|\s+):/);

    if (shouldShowShortcut) {
      searchShortcodes(value);
    } else {
      setShortcodes(null);
    }
    setMessage(value);
  };

  const doPauseLead = useCallback(async () => {
    if (!lead) {
      return null;
    }

    await loadingIndicator.create();
    try {
      const tzDate = moment(pauseDate).tz(timezone);
      const formattedDate = tzDate.format('MM/DD/YY hh:mma zz');
      const updates = {
        id: lead.id,
        client: lead.client,
        pause_date: tzDate.toISOString(),
        pause_message: message,
        unpause_needs_call: needsCall,
      };
      const updated = await leadsService.update(updates, {
        successMessage: `Lead has been paused until ${formattedDate}.`
      });
      dispatch({ type: 'set', value: { lead: updated } });

      await createNewInternalMessage({
        message: `Pause Date ${formattedDate}`
      });

      if (message) {
        await createNewInternalMessage({
          message: `Pause Message: ${message}`
        });
      }

      setPauseDate(new Date().toISOString());
      setMessage('');

      util.delay(() => scrollToBottom?.(), 250);
      modalController.dismiss();
    } finally {
      loadingIndicator.dismiss();
    }
  }, [pauseDate, lead, message, createNewInternalMessage, dispatch, timezone, needsCall]);

  const pauseLead = useCallback(() => {
    if (validation?.during === false) {
      appNotification.alert({
        message:
          'Pause date is outside of business hours. Are you sure you want to schedule it?',
        header: 'Warning',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Continue',
            handler() {
              doPauseLead();
            }
          }
        ]
      });
    } else {
      doPauseLead();
    }
  }, [validation, doPauseLead]);

  const checkInsideBusinessHours = useCallback(() => {
    setValidation(
      clientHoursService.isDuringBusinessHours(
        moment(pauseDate),
        clientHours,
        timezone
      )
    );
  }, [pauseDate, clientHours, timezone]);

  useEffect(() => checkInsideBusinessHours(), [checkInsideBusinessHours]);

  return (
    <TModal
      isOpen={isOpen}
      title={`Pause ${state.lead?.first_name} ${state.lead?.last_name}`}
      onDidDismiss={onDidDismiss}
      cssClass="pause-modal"
      footer={
        <>
          {validation?.during === false && (
            <TBanner icon={warning} color="danger">
              Pause date is outside of business hours
            </TBanner>
          )}
          <IonItem lines="none">
            <IonButton
              disabled={user.is_staff && !message?.length}
              slot="end"
              color="success"
              fill="clear"
              size="default"
              onClick={pauseLead}
            >
              Pause
            </IonButton>
          </IonItem>
        </>
      }
    >
      <div className="pause-container">
        <TDatePicker
          label="Pause Date"
          minDate={new Date()}
          dateString={pauseDate}
          onDateChange={setPauseDate}
          timezone={timezone}
          dateFormat="MM/dd/yyyy h:mma"
          timeIntervals={15}
          showTimeSelect
        />
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton expand="block" onClick={(e) => setPauseDate(moment().add(1, 'month').toISOString())}>
                  1 Month
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton expand="block" onClick={(e) => setPauseDate(moment().add(3, 'month').toISOString())}>
                  3 Months
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton expand="block" onClick={(e) => setPauseDate(moment().add(6, 'month').toISOString())}>
                  6 Months
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton expand="block" onClick={(e) => setPauseDate(moment().add(12, 'month').toISOString())}>
                  1 Year
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <TToggleItem
          label="Needs Call"
          lines="full"
          checked={needsCall}
          onIonChange={(e: any) => setNeedsCall(e.detail.checked)}
          wrapLabel
        />
        <TButtonItem
          color="secondary"
          onClick={() => setShowQuickReplies(true)}
        >
          <IonIcon icon={flash} slot="start" />
          Quick Replies
        </TButtonItem>
        <TInput
          value={message}
          valueChanged={checkShortcodes}
          onEnterPressed={doFillShortcode}
          className="pause-message"
          placeholder={`Message to send on ${moment(pauseDate)
            .tz(timezone)
            .format('MMMM D, YYYY \\a\\t h:mma')}`}
          autoGrowMaxHeight={100}
          autoGrow
          autoCapitalize="on"
          results={shortcodes}
          onSelected={fillShortcode}
          onSelectedIndexChanged={setSelectedShortCodeIndex}
          spellcheck
          multiline
        />
      </div>
      {showQuickReplies && (
        <QuickRepliesModal
          setMessage={setMessage}
          isOpen={true}
          onDidDismiss={() => setShowQuickReplies(false)}
        />
      )}
    </TModal>
  );
};

export default PauseModal;
