import { http } from '../core';
import qs from 'querystring';
import clientEmployeeService from './client-employees';

class ReportsService {
  async retrieveMyActivityReport(
    clientId: number,
    from: Date,
    to: Date,
    classification?: string,
    leadProvider?: number
  ) {
    try {
      const params = {
        since: from.toISOString(),
        until: to.toISOString(),
        classification
      } as any;

      if (classification === 'All') {
        params.classification = '';
      }

      if (leadProvider) {
        params.master_provider = leadProvider;
      }

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/my-activity-report/?${query}`,
        exclusive: true,
        type: 'my-activity-report'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async retrieveTeamActivityReport(
    clientId: number,
    from: Date,
    to: Date,
    classification?: string,
    excludeSaleAppointments?: string,
    leadProvider?: number
  ) {
    try {
      const params = {
        since: from.toISOString(),
        until: to.toISOString(),
        exclude_sale_appointments: excludeSaleAppointments,
        classification
      } as any;

      if (classification === 'All') {
        params.classification = '';
      }
      if (excludeSaleAppointments === 'All') {
        params.exclude_sale_appointments = '';
      }

      if (leadProvider) {
        params.master_provider = leadProvider;
      }

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/team-activity-report/?${query}`,
        exclusive: true,
        type: 'team-activity-report'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async retrieveTeamMessagesActivityReport(
    clientId: number,
    from: Date,
    to: Date,
    classification?: string,
    leadProvider?: number
  ) {
    try {
      const params = {
        since: from.toISOString(),
        until: to.toISOString(),
        classification
      } as any;

      if (classification === 'All') {
        params.classification = '';
      }

      if (leadProvider) {
        params.master_provider = leadProvider;
      }

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/team-messages-activity-report/?${query}`,
        exclusive: true,
        type: 'team-messages-activity-report'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveKPIReport(
    clientId: number,
    from: Date,
    to: Date,
    classification?: string,
    leadProvider?: number,
    pipelineStatusIds?: Array<number>,
    newLeadsOnly?: boolean,
  ) {
    try {
      const params = {
        since: from.toISOString(),
        until: to.toISOString(),
        classification,
      } as any;

      if (classification === 'All') {
        params.classification = '';
      }
      if (pipelineStatusIds && pipelineStatusIds.length) {
        params.pipeline_statuses = pipelineStatusIds.join(',');
      }
      if (newLeadsOnly) {
        params.new = true;
      }
      if (leadProvider) {
        params.master_provider = leadProvider;
      }

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/kpi-report/?${query}`,
        exclusive: false,
        type: 'kpi-report'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveFourElementsReport(
    clientId: number,
    from: Date,
    to: Date,
    leadProvider?: number,
  ) {
    try {
      const params = {
        since: from.toISOString(),
        until: to.toISOString(),
      } as any;
      if (leadProvider) {
        params.master_provider = leadProvider;
      }
      const query = qs.stringify(params);
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/four-elements-report/?${query}`,
        exclusive: false,
        type: 'four-elements-report'
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveSmsDeliveryReports(clientId: number, from: Date, to: Date, params: any) {
    try {
      const query = qs.stringify({
        created__gte: from.toISOString(),
        created__lte: to.toISOString(),
        twilio_status__isnull: false,
        sms_type__in: 'outbound,system',
        ...params,
      });

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/smsmessage-status-counts/?${query}`,
        exclusive: true,
        type: 'smsmessage-status-counts'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveUserReport(clientId: number, userId: number, from: Date, to: Date) {
    try {
      const query = qs.stringify({
        since: from.toISOString(),
        until: to.toISOString()
      });

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/user-activity/${userId}/?${query}`,
        exclusive: true,
        type: `user-report-${userId}`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveUserReports(clientId: number, from: Date, to: Date) {
    try {
      const query = qs.stringify({
        since: from.toISOString(),
        until: to.toISOString()
      });

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/user-activity/?${query}`,
        exclusive: true,
        type: 'user-report'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveProviderAppointments(clientId: number, from: Date, to: Date) {
    try {
      const query = qs.stringify({
        since: from.toISOString(),
        until: to.toISOString()
      });

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/reports/provider-appointments/?${query}`,
        exclusive: true,
        type: 'provider-appointments'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveProviderLeads(clientId: number, from: Date, to: Date) {
    try {
      const query = qs.stringify({
        since: from.toISOString(),
        until: to.toISOString()
      });

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/reports/provider-leads/?${query}`,
        exclusive: true,
        type: 'provider-leads'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveCustomerBlastReports(
    from: Date,
    to: Date
  ) {
    try {
      const query = qs.stringify({
        completed_at__gte: from.toISOString(),
        completed_at__lte: to.toISOString(),
        ordering: 'client,send_at',
      });

      const { data } = await http.authorizedRequest({
        url: `/customer-blast-accounting/?${query}`,
        exclusive: true,
        type: 'customer-blast-accounting-report'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveBlastReports(
    from: Date,
    to: Date,
    blastType: string,
    filters?: any,
    clientId?: number
  ) {
    console.log(filters)
    try {

      const data: any = {
        send_at__gte: from.toISOString(),
        send_at__lte: to.toISOString(),
      }
      if (filters?.classification && filters.classification !== 'All') {
        data['lead_classification'] = filters.classification;
      }
      if (filters?.showSelectedClientOnly) {
        data['client_id'] = clientId;
      }
      const query = qs.stringify(data);

      const url = blastType === 'lead' ? 'sms-blasts-report' : 'customer-blasts-report';

      const { data: blastIds } = await http.authorizedRequest({
        url: `/${url}/?${query}`,
        exclusive: true,
        type: url
      });

      const res = await Promise.all(blastIds.map((id:number) => {
        return http.authorizedRequest({
          url: `/${url}/${id}/`,
          exclusive: true,
          type: `${url}-${id}`
        });
      }));

      const blasts = res.map((it: any) => it?.data).filter((it: any) => !!Object.keys(it).length);
      return blasts

    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveTeamUserReport(
    userIds: any,
    clientId: number,
    from: Date,
    to: Date,
    exclusive = true
  ) {
    try {

      const query = qs.stringify({
        start_time: from.toISOString(),
        end_time: to.toISOString(),
        client_id: clientId,
      });

      const res = await Promise.all(userIds.map((id:number) => {
        return http.authorizedRequest({
          url: `/ninja-report/${id}/?${query}`,
          exclusive,
          type: `ninja-report-${id}`
        });
      }));

      const users = res.map((it: any) => it?.data).filter((it: any) => !!Object.keys(it).length);
      let total_sms = 0;
      let sold_appointments = 0;
      let shown_appointments = 0;
      let net_appointments = 0;
      let cancelled_appointments = 0;
      let total_appointments = 0;
      let seconds_clocked_in = 0;
      let touched_lead_ids: Array<number> = [];
      users.forEach(user => {
        total_sms += user.total_sms;
        sold_appointments += user.sold_appointments;
        shown_appointments += user.shown_appointments;
        net_appointments += user.net_appointments;
        cancelled_appointments += user.cancelled_appointments;
        total_appointments += user.total_appointments;
        seconds_clocked_in += user.seconds_clocked_in;
        touched_lead_ids = Array.from(new Set(touched_lead_ids.concat(user.touched_lead_ids)));
      });

      const sms_per_hour = total_sms/(seconds_clocked_in/60/60)
      const total_touched_leads = touched_lead_ids.length;

      return {
        total_sms,
        sold_appointments,
        shown_appointments,
        net_appointments,
        cancelled_appointments,
        total_appointments,
        sms_per_hour,
        total_touched_leads,
        users,
        from,
        to,
      }

    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveNinjaReports(
    from: Date,
    to: Date,
    admins?: any,
    contractors?: any,
    isPhoneNinja?: any,
    ninjaTeams?: any,
    exclusive = true
  ) {
    try {
      const staffUserQuery = qs.stringify({
        is_admin: admins === 'All' ? undefined : admins,
        is_1099: contractors === 'All' ? undefined : contractors,
        is_phone_ninja: isPhoneNinja === 'All' ? undefined : isPhoneNinja,
        ninja_teams: ninjaTeams ? ninjaTeams.join(',') : undefined,
      });

      const query = qs.stringify({
        start_time: from.toISOString(),
        end_time: to.toISOString(),
      });

      const { data: staffUserIds } = await http.authorizedRequest({
        url: `/staff-user-ids/?${staffUserQuery}`,
        exclusive,
        type: 'staff-user-ids'
      });

      const res = await Promise.all(staffUserIds.map((id:number) => {
        return http.authorizedRequest({
          url: `/ninja-report/${id}/?${query}`,
          exclusive,
          type: `ninja-report-${id}`
        });
      }));

      const ninjas = res.map((it: any) => it?.data).filter((it: any) => !!Object.keys(it).length);
      let total_sms = 0;
      let sold_appointments = 0;
      let shown_appointments = 0;
      let net_appointments = 0;
      let cancelled_appointments = 0;
      let total_appointments = 0;
      let seconds_clocked_in = 0;
      let touched_lead_ids: Array<number> = [];
      ninjas.forEach(ninja => {
        total_sms += ninja.total_sms;
        sold_appointments += ninja.sold_appointments;
        shown_appointments += ninja.shown_appointments;
        net_appointments += ninja.net_appointments;
        cancelled_appointments += ninja.cancelled_appointments;
        total_appointments += ninja.total_appointments;
        seconds_clocked_in += ninja.seconds_clocked_in;
        touched_lead_ids = Array.from(new Set(touched_lead_ids.concat(ninja.touched_lead_ids)));
      });

      const sms_per_hour = total_sms/(seconds_clocked_in/60/60)
      const total_touched_leads = touched_lead_ids.length;

      return {
        total_sms,
        sold_appointments,
        shown_appointments,
        net_appointments,
        cancelled_appointments,
        total_appointments,
        sms_per_hour,
        total_touched_leads,
        ninjas,
        from,
        to,
      }

    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveTopNinjasReports(
    from: Date,
    to: Date,
    exclusive = true
  ) {
    try {
      const query = qs.stringify({
        start_time: from.toISOString(),
        end_time: to.toISOString(),
      });

      const { data } = await http.authorizedRequest({
        url: `/top-ninjas-report/?${query}`,
        exclusive,
        type: 'top-ninjas-report'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveTopNinjaTeamsReports(
    from: Date,
    to: Date,
    exclusive = true
  ) {
    try {
      const query = qs.stringify({
        sold__gte: from.toISOString(),
        sold__lte: to.toISOString(),
      });

      const { data } = await http.authorizedRequest({
        url: `/ninja-teams-sold/?${query}`,
        exclusive,
        type: 'ninja-teams-sold'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async retrieveAppointmentReport(
    from: Date,
    to: Date,
    user__is_staff?: boolean,
    topNinja?: boolean,
    classification?: string,
    leadProvider?: number,
    dtField = 'sold',
  ) {
    const fromKey = `${dtField}__gte`;
    const toKey = `${dtField}__lte`;
    try {
      if (classification === 'All') {
        classification = undefined;
      }
      let top_ninja__isnull;
      if (topNinja === true) {
        top_ninja__isnull = false;
      } else if (topNinja === false) {
        top_ninja__isnull = true;
      }
      const lead__classification = classification;
      const params = {
        lead__classification,
        user__is_staff,
        top_ninja__isnull,
      } as any;
      params[fromKey] = from.toISOString();
      params[toKey] = to.toISOString();
      if (leadProvider) {
        params.lead__master_provider = leadProvider;
      }

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/appointment-report/?${query}`,
        exclusive: true,
        type: `appointment-report`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
  async retrieveSMSCounts(
    clientId: number,
    from: Date,
    to: Date,
    staff: boolean,
    classification?: string,
    respondedById?: any,
    leadProvider?: number
  ) {
    try {
      if (classification === 'All') {
        classification = undefined;
      }
      // the URL param is lead__classification
      const lead__classification = classification;
      const params = {
        created__gte: from.toISOString(),
        created__lte: to.toISOString(),
        lead__classification
      } as any;

      if (respondedById) {
        params.responded_by__id = respondedById;
      } else {
        const employeeIds = await clientEmployeeService.list(clientId);
        if (staff) {
          params.responded_by__id__not__in = employeeIds.join(',');
        } else {
          params.responded_by__id__in = employeeIds.join(',');
        }
      }

      if (leadProvider) {
        params.lead__master_provider = leadProvider;
      }

      const query = qs.stringify(params);

      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/sms-response-report/?${query}`,
        exclusive: true,
        type: `sms-counts-${staff}`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

export default new ReportsService();
