import React, { useState, useContext, useCallback } from 'react';
import {
  IonIcon,
  IonChip,
  IonLabel,
  IonItemDivider,
  IonItem,
  useIonViewWillLeave
} from '@ionic/react';
import {
  TInfiniteScrollPage,
  PhoneCallRow,
  TSearchBar,
  CallFilters,
  HapticButton
} from '../components';
import moment from 'moment';
import { AppContext } from '../context/AppContext';
import { phoneCallsService, clientUsersService } from '../services';
import { PHONE_CALLS_DEFAULT_DURATION_RANGE } from '../services/phone-calls';

import { funnel, person, closeCircle, compass, paperPlane, time, calendar } from 'ionicons/icons';
import { haptics } from '../core';
import { useDataLoader } from '../hooks';

const PhoneCallsPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const [expandedItemId, setExpandedItemId] = useState<number | null>(null);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({} as any);
  const [userName, setUserName] = useState('');
  const [providerName, setProviderName] = useState('');
  const [filtersOpen, setFiltersOpen] = useState(false);

  const loadCalls = useCallback(async () => {
    return await phoneCallsService.list({
      clientId: state.selectedClientId,
      ...filters,
       search
    });
  }, [state.selectedClientId, search, filters]);

  const { data, next, loading, refresher, loadNext, error } = useDataLoader(
    loadCalls,
    true
  );

  useIonViewWillLeave(() => setExpandedItemId(null));

  return (
    <TInfiniteScrollPage
      loading={loading}
      onRefresh={refresher}
      hasNext={!!next}
      items={data}
      onLoadNextPage={loadNext}
      error={error}
      toolbarLoadingIndicator={!!search && loading}
      zeroStateText={
        search || !!Object.keys(filters).length
          ? 'No Phone Calls Found Matching Criteria'
          : 'No Phone Calls'
      }
      toolbar={
        <>
          <IonItemDivider>
            <TSearchBar
              onSearch={(value: string) => {
                setSearch(value);
              }}
              placeholder="Search Phone Calls"
            />
            <HapticButton icon={funnel} onClick={() => setFiltersOpen(true)} />
            {filtersOpen && (
              <CallFilters
                isOpen={true}
                filters={filters}
                onDidDismiss={() => setFiltersOpen(false)}
                onApply={async (e: any) => {
                  console.log(e)
                  setFilters(e);
                  if (e.user) {
                    setUserName(clientUsersService.getNameById(e.user, state.clientUsers));
                  }
                  if (e.provider) {
                    const pName = state.clientProviders?.find?.(it => e.provider === it.id);
                    if (pName) {
                      setProviderName(pName?.name);
                    }
                  }
                }}
              />
            )}
          </IonItemDivider>
          {!!(filters.user || filters.direction || filters.created_at__gte || filters.provider || (filters?.duration__gte > PHONE_CALLS_DEFAULT_DURATION_RANGE.Min || filters?.duration__lte < PHONE_CALLS_DEFAULT_DURATION_RANGE.Max)) && (
            <IonItem lines="full">
            {filters?.user && (
              <IonChip color="primary">
                <IonIcon icon={person} />
                <IonLabel>{userName}</IonLabel>
                <IonIcon
                  icon={closeCircle}
                  onClick={() => {
                    const fltrs = Object.assign({}, filters);
                    fltrs.user = undefined;
                    setFilters(fltrs);
                  }}
                />
              </IonChip>
            )}
            {filters?.direction && (
              <IonChip color="primary">
                <IonIcon icon={compass} />
                <IonLabel>{filters.direction === 'inbound' ? 'Inbound' : 'Outbound'}</IonLabel>
                <IonIcon
                  icon={closeCircle}
                  onClick={() => {
                    const fltrs = Object.assign({}, filters);
                    fltrs.direction = undefined;
                    setFilters(fltrs);
                  }}
                />
              </IonChip>
            )}
            {!!filters?.provider && (
              <IonChip color="primary">
                <IonIcon icon={paperPlane} />
                <IonLabel>{providerName}</IonLabel>
                <IonIcon
                  icon={closeCircle}
                  onClick={() => {
                    const fltrs = Object.assign({}, filters);
                    fltrs.provider = undefined;
                    setFilters(fltrs);
                  }}
                />
              </IonChip>
            )}
            {!!filters?.created_at__gte && (
              <IonChip color="primary">
                <IonIcon icon={calendar} />
                <IonLabel>{'Since: ' + moment(filters.created_at__gte).format('MM/DD/YY')}</IonLabel>
                <IonIcon
                  icon={closeCircle}
                  onClick={() => {
                    const fltrs = Object.assign({}, filters);
                    fltrs.created_at__gte = undefined;
                    setFilters(fltrs);
                  }}
                />
              </IonChip>
            )}
            {!!filters?.created_at__lte && (
              <IonChip color="primary">
                <IonIcon icon={calendar} />
                <IonLabel>{'Until: ' + moment(filters.created_at__lte).format('MM/DD/YY')}</IonLabel>
                <IonIcon
                  icon={closeCircle}
                  onClick={() => {
                    const fltrs = Object.assign({}, filters);
                    fltrs.created_at__lte = undefined;
                    setFilters(fltrs);
                  }}
                />
              </IonChip>
            )}
            {(filters?.duration__gte > PHONE_CALLS_DEFAULT_DURATION_RANGE.Min || filters?.duration__lte < PHONE_CALLS_DEFAULT_DURATION_RANGE.Max) && (
              <IonChip color="primary">
                <IonIcon icon={time} />
                <IonLabel>{filters.duration__gte/60}-{filters.duration__lte/60}</IonLabel>
                <IonIcon
                  icon={closeCircle}
                  onClick={() => {
                    const fltrs = Object.assign({}, filters);
                    fltrs.duration__gte = undefined;
                    fltrs.duration__lte = undefined;
                    setFilters(fltrs);
                  }}
                />
              </IonChip>
            )}
            </IonItem>
          )}
        </>
      }
      renderItem={(item: any, index?: number) => {
        const nextItem = index && data && (data[index + 1] as any);
        const nextIsSelected = nextItem && nextItem.id === expandedItemId;
        const onRowClick = () => {
          haptics.lightImpact();
          if (expandedItemId === item.id) {
            setExpandedItemId(null);
          } else {
            setExpandedItemId(item.id);
          }
        };
        return (
          <PhoneCallRow
            key={item.id}
            expanded={expandedItemId === item.id}
            fullLines={!!nextIsSelected}
            onClick={onRowClick}
            item={item}
          />
        );
      }}
    />
  );
};

export default PhoneCallsPage;
