import { http, appNotification, theme, liveDB, util } from '../core';
import { dispatch } from '../context/AppContext';
import CachedService from './cached-service';
import { User } from '../types/User';

class UserService extends CachedService {
  constructor() {
    super('user-info', 'user', null);
  }

  async request() {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: '/auth/user/'
    });

    theme.setPreference(res.data?.mobile_theme ?? 'system');

    return res.data;
  }

  async fetchClockInStatus() {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: '/clock-in-status/'
    });
    return res.data;
  }

  async update(data: Partial<User>, options?: any) {
    try {
      const formData = new FormData();
      const dataKeys = Object.keys(data);
      const hasProfileImage = dataKeys.indexOf('profile_image') > -1 && data['profile_image'] !== null && data['profile_image'] !== undefined;
      if (hasProfileImage) {
        Object.keys(data).forEach((k: string) => {
          formData.append(k, data[k]);
        });
      }
      const d = hasProfileImage ? formData : data;
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: '/auth/user/',
        data: d
      });

      this.cache(res.data);
      dispatch({
        type: 'setUser',
        value: res.data
      });
      if (!options?.noToast) {
        appNotification.toast('Settings updated.', 'Success');
      }
      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  canAccessInboundQueue(user?: User) {
    return this.isStaff(user);
  }

  isNinja(user?: User) {
    return !!user && user.is_admin === false && user.is_text_ninja === true;
  }

  async updateClockedInStatus(is_clocked_in: boolean) {
    const user = await this.update({ is_clocked_in }, { noToast: true });
    liveDB
      .useReference(`/users-clocked-in/${user.id}/`)
      .set(user.is_clocked_in);
    return user;
  }

  isRoundRobinEnabled(user?: User) {
    return !!user && user.round_robin_enabled === true;
  }

  isStaff(user?: User) {
    return util.userIsStaff(user);
  }

  async toggleClockIn(isClockedIn: boolean) {
    return new Promise(resolve => {
      appNotification.alert({
        message: `Do you want to clock ${isClockedIn ? 'out' : 'in'}?`,
        header: `Clock ${isClockedIn ? 'Out' : 'In'}`,
        buttons: [
          {
            role: 'cancel',
            text: 'No',
            handler() {
              resolve(false);
            }
          },
          {
            text: 'Yes',
            handler: () => {
              (async () => {
                const updatedClockedInState = !isClockedIn;
                await this.updateClockedInStatus(updatedClockedInState);
                appNotification.toast(
                  `You are now clocked ${updatedClockedInState ? 'in' : 'out'}`,
                  'Success'
                );
                resolve(true);
              })();
            }
          }
        ]
      });
    });
  }
}

export default new UserService();
